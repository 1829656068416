import React, {useCallback} from "react";
import PropTypes from "prop-types";
import {usePopover} from "../../../../hooks/use-popover";
import {
    Box,
    Card,
    Divider,
    IconButton,
    Menu, MenuItem,
    menuItemClasses,
    Stack,
    SvgIcon,
    Tooltip,
    Typography
} from "@mui/material";
import Globe03 from "@untitled-ui/icons-react/build/esm/Globe04";
import DotsVerticalIcon from "@untitled-ui/icons-react/build/esm/DotsVertical";
import {ItemIcon} from "./item-icon";
import moment from "moment/moment";
import {useDialog} from "../../../../hooks/use-dialog";
import Trash02Icon from "@untitled-ui/icons-react/build/esm/Trash01";
import {bytesToSize} from "../../../../utils/bytesToSize";
import {useTranslation} from "react-i18next";
import FileModal from "../file-modal";


const HoverableFileCard = (props) => {
    const {file, onDelete, renderTags, renderActions, allowDeletion} = props;
    const popover = usePopover();
    const {t} = useTranslation();
    const detailsDialog = useDialog();

    const handleDelete = useCallback(() => {
        popover.handleClose();
        onDelete?.(file.id);
    }, [file, popover, onDelete]);

    let size = bytesToSize(file.size);

    return (
        <>
            <FileModal
                file={detailsDialog.data}
                open={detailsDialog.open}
                onClose={detailsDialog.handleClose}
                deleteCallback={handleDelete}
            />
            <Card
                key={file.id}
                sx={{
                    backgroundColor: 'transparent',
                    boxShadow: 0,
                    transition: (theme) => theme.transitions.create(['background-color, box-shadow'], {
                        easing: theme.transitions.easing.easeInOut,
                        duration: 200
                    }),
                    '&:hover': {
                        backgroundColor: 'background.paper',
                        boxShadow: 16
                    }
                }}
                variant="outlined"
            >
                <Stack
                    alignItems="center"
                    direction="row"
                    justifyContent="space-between"
                    spacing={2}
                    sx={{
                        pt: 2,
                        px: 2
                    }}
                >
                    <Stack direction="row" spacing={1}>
                        <Box>
                            {file.is_public && (
                                <Tooltip title={t("File is public")}>
                                    <SvgIcon fontSize="small">
                                        <Globe03/>
                                    </SvgIcon>
                                </Tooltip>
                            )}
                        </Box>
                    </Stack>
                    {(onDelete && allowDeletion) && (
                        <IconButton
                            onClick={popover.handleOpen}
                            ref={popover.anchorRef}
                        >
                            <SvgIcon fontSize="small">
                                <DotsVerticalIcon/>
                            </SvgIcon>
                        </IconButton>
                    )}
                </Stack>
                <Box sx={{p: 2}}>
                    <Box
                        sx={{
                            display: 'flex',
                            mb: 1
                        }}
                    >
                        <Box
                            onClick={() => detailsDialog.handleOpen?.(file)}
                            sx={{
                                display: 'inline-flex',
                                cursor: 'pointer'
                            }}
                        >
                            <ItemIcon
                                type={file.type}
                                extension={file.type}
                            />
                        </Box>
                    </Box>
                    <Typography
                        onClick={() => detailsDialog.handleOpen?.(file)}
                        sx={{cursor: 'pointer'}}
                        variant="subtitle2"
                    >
                        {file.name}
                    </Typography>
                    <Divider sx={{my: 1}}/>
                    <Stack
                        alignItems="center"
                        direction="row"
                        justifyContent="space-between"
                        spacing={1}
                        sx={{width: '100%'}}
                    >
                        <Stack
                            direction="column"
                            spacing={0}
                            alignItems="flex-start"
                            justifyContent="center"
                        >
                            <Typography
                                color="text.secondary"
                                variant="body2"
                            >
                                {size}
                            </Typography>
                            <Typography
                                color="text.secondary"
                                variant="caption"
                            >
                                {t("common.uploaded_on_from_now", {fromNow: moment(file?.created_at).fromNow()})}
                            </Typography>
                        </Stack>
                        <Box>
                            {renderTags && renderTags(file)}
                        </Box>
                    </Stack>
                </Box>
            </Card>
            <Menu
                anchorEl={popover.anchorRef.current}
                anchorOrigin={{
                    horizontal: 'right',
                    vertical: 'bottom'
                }}
                onClose={popover.handleClose}
                onClick={popover.handleClose}
                open={popover.open}
                sx={{
                    [`& .${menuItemClasses.root}`]: {
                        fontSize: 14,
                        '& svg': {
                            mr: 1
                        }
                    }
                }}
                transformOrigin={{
                    horizontal: 'right',
                    vertical: 'top'
                }}
            >
                {allowDeletion && (
                    <MenuItem
                        onClick={handleDelete}
                        sx={{color: 'error.main'}}
                    >
                        <SvgIcon fontSize="small">
                            <Trash02Icon/>
                        </SvgIcon>
                        {t("common.delete")}
                    </MenuItem>
                )}
                {renderActions && renderActions(file).map((action, index) => (
                    <MenuItem
                        key={index}
                        onClick={() => action.onClick(file)}
                    >
                        <SvgIcon fontSize="small">
                            {action.icon}
                        </SvgIcon>
                        {action.name}
                    </MenuItem>
                ))}

            </Menu>

        </>
    )

}

HoverableFileCard.propTypes = {
    file: PropTypes.object.isRequired,
    onDelete: PropTypes.func,
    renderActions: PropTypes.func,
    renderTags: PropTypes.func,
    allowDeletion: PropTypes.bool
}

export default HoverableFileCard