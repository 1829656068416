export const createTypography = (config) => {

    return {

        fontFamily: `Font-${config?.id}, -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"`,

        body1: {
            fontSize: config?.font_body1_size || 12,
            fontWeight: config?.font_body1_weight || 400,
            lineHeight: config?.font_body1_line_height || 2
        },
        body2: {
            fontSize: config?.font_body2_size || 11,
            fontWeight: config?.font_body2_weight || 400,
            lineHeight: config?.font_body2_line_height || 1.57
        },
        button: {
            fontSize: config?.font_button_size || 11,
            fontWeight: config?.font_button_weight || 600,
            lineHeight: config?.font_button_line_height || 1
        },
        caption: {
            fontSize: config?.font_caption_size || 10,
            fontWeight: config?.font_caption_weight || 500,
            lineHeight: config?.font_caption_line_height || 1.66
        },
        subtitle1: {
            fontSize: config?.font_subtitle1_size || 13,
            fontWeight: config?.font_subtitle1_weight || 500,
            lineHeight: config?.font_subtitle1_line_height || 1.57
        },
        subtitle2: {
            fontSize: config?.font_subtitle2_size || 12,
            fontWeight: config?.font_subtitle2_weight || 500,
            lineHeight: config?.font_subtitle2_line_height || 1.57
        },
        overline: {
            fontSize: config?.font_overline_size || 10,
            fontWeight: config?.font_overline_weight || 600,
            letterSpacing: '0.5px',
            lineHeight: config?.font_overline_line_height || 2,
            textTransform: 'uppercase'
        },
        h1: {
            fontWeight: config?.font_h1_weight || 700,
            fontSize: config?.font_h1_size || 45,
            lineHeight: config?.font_h1_line_height || 1.2
        },
        h2: {
            fontWeight: config?.font_h2_weight || 700,
            fontSize: config?.font_h2_size || 40,
            lineHeight: config?.font_h2_line_height || 1.2
        },
        h3: {
            fontWeight: config?.font_h3_weight || 700,
            fontSize: config?.font_h3_size || 35,
            lineHeight: config?.font_h3_line_height || 1.2
        },
        h4: {
            fontWeight: config?.font_h4_weight || 700,
            fontSize: config?.font_h4_size || 28,
            lineHeight: config?.font_h4_line_height || 1.2
        },
        h5: {
            fontWeight: config?.font_h5_weight || 700,
            fontSize: config?.font_h5_size || 20,
            lineHeight: config?.font_h5_line_height || 1.2
        },
        h6: {
            fontWeight: config?.font_h6_weight || 700,
            fontSize: config?.font_h6_size || 14,
            lineHeight: config?.font_h6_line_height || 1.2
        }
    };
};
