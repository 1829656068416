import React, {useEffect, useState} from "react";
import OnlineIndicator from "./data-analytics/dashboards/online-indicator";
import clsx from "clsx";
import {Stack, Typography} from "@mui/material";
import moment from "moment";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

function UserOnlineDot({className, userId, withText, dotOnly, ...rest}){

    const account = useSelector(state => state.account.user);
    const [indicatorStatus, setIndicatorStatus] = useState("offline");
    const {t} = useTranslation();
    const users = useSelector(state => state.users.users);
    const [statusText, setStatusText] = useState(t('common.offline'));
    const user = users.filter(user => user.id === userId).length > 0 ? users.find(user => user.id === userId) : null;

    useEffect(() => {
        let indicatorStatusTmp = 'offline';
        let statusTextTmp = t("common.offline");
        if(user){
            if(account.id === user.id){
                indicatorStatusTmp = 'online';
            } else {
                // check if the user has not turned off visibility of online status
                if(typeof(user.is_online) !== "undefined" && typeof(user.is_active) !== "undefined" && typeof(user.last_active) !== "undefined"){
                    // when user is online check if online or away
                    if(user.is_online){
                        // set indicator to online
                        if(user.is_active){
                            indicatorStatusTmp = 'online';
                            statusTextTmp = t("common.online");
                        } else {
                            indicatorStatusTmp = 'away';
                            statusTextTmp = t("common.away");
                        }
                    } else {
                        // null will print the "last active" string
                        indicatorStatusTmp = null;
                    }
                }
            }
        }
        setIndicatorStatus(indicatorStatusTmp);
        setStatusText(statusTextTmp);
    }, [userId, users]);

    // 'online', 'offline', 'away', 'busy'

    if(!user)
        return null;

    return (
        <Stack direction="row" spacing={1} alignItems="center">
            {dotOnly ? (
                <>
                    <OnlineIndicator size="small" status={indicatorStatus ? indicatorStatus : 'offline'} {...rest} />
                </>
            ) : (
                <>
                    {indicatorStatus ? (
                        <>
                            <OnlineIndicator size="small" status={indicatorStatus} {...rest} />
                            {withText && (
                                <Typography variant="caption" color="textSecondary" style={{marginLeft: 6}} {...rest}>
                                    {statusText}
                                </Typography>
                            )}
                        </>
                    ) : (
                        <Typography color="textSecondary" noWrap variant="caption" {...rest}>
                            {(user.last_active === null) ? t('common.new') : moment(user.last_active).fromNow()}
                        </Typography>
                    )}
                </>
            )}
        </Stack>
    )
}

UserOnlineDot.propTypes = {
    className: PropTypes.string,
    withText: PropTypes.bool,
    dotOnly: PropTypes.bool,
    user: PropTypes.object,
};

UserOnlineDot.defaultProps = {
    user: null,
    dotOnly: false,
    withText: false,
};

export default UserOnlineDot;