import {Box, Button, Container, Typography, useMediaQuery} from '@mui/material';
import {RouterLink} from 'src/omnia/components/elements/router-link';
import {Seo} from 'src/omnia/components/elements/seo';
import {usePageView} from 'src/omnia/hooks/use-page-view';
import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/system";
import SaveButton from "../../../components/elements/save-button";
import {useState} from "react";
import OnIcon from "../../../components/elements/icon";

const Page = ({...rest}) => {
    const theme = useTheme();
    const mdUp = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const [reloading, setReloading] = useState(false);
    const { t } = useTranslation();

    const handleReload = () => {
        setReloading(true);
        setTimeout(() => {
            window.location.reload()
        }, 500);
    }

    console.log('Omnia Error')
    console.log(rest?.error?.message);
    console.log(rest?.error?.stack);

    return (
        <>
            <Seo title={t('pages.error_500_title')}/>
            <Box
                component="main"
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexGrow: 1,
                    py: '80px'
                }}
            >
                <Container maxWidth="lg">
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            mb: 6
                        }}
                    >
                        <Box
                            alt="Internal server error"
                            component="img"
                            src={theme?.config?.error_500?.view || "/assets/errors/error-500.png"}
                            sx={{
                                height: 'auto',
                                maxWidth: '100%',
                                width: 200
                            }}
                        />
                    </Box>
                    <Typography
                        align="center"
                        variant='h4'
                    >
                        {t('pages.error_500_title')}
                    </Typography>
                    <Typography
                        align="center"
                        variant="subtitle1"
                        color="textSecondary"
                        sx={{mt: 0.5}}
                    >
                        {t('pages.error_500_subheader')}
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            mt: 6
                        }}
                    >
                        <SaveButton
                            variant="contained"
                            onClick={handleReload}
                            noIcon
                            loading={reloading}
                            label={t('pages.reload_now')}
                        />
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default Page;

