import axios from 'axios';

const axiosConfig = {
    baseURL: 'api',
    timeout: 100000,
};

const instance = axios.create(axiosConfig);

export default instance;
