import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {
    Card,
    CardContent,
    Grid,
    IconButton,
    Stack,
    Box,
    Tooltip,
    Typography,
    ListItem,
    List,
    Link,
    Chip, SvgIcon,
} from '@mui/material';
import DeleteIcon from "@untitled-ui/icons-react/build/esm/Trash01";
import DownloadIcon from "@untitled-ui/icons-react/build/esm/Download01";
import RenameIcon from "@untitled-ui/icons-react/build/esm/Edit05";
import { bytesToSize } from "src/omnia/utils/bytesToSize";
import {useCreateSrc} from "src/omnia/hooks/use-create-src";
import FileRenameDialog from "./file-rename-dialog";
import FileGenericViewer from "./file-generic-viewer";
import moment from "moment";
import {Link as RouterLink} from "react-router-dom";
import TogglePublicIcon from "@untitled-ui/icons-react/build/esm/Globe04";
import {useSelector} from "react-redux";
import FullscreenWrapper from "src/omnia/components/elements/fullscreen-wrapper";
import UserAvatar from "../../modules/core/UserAvatar";
import {useTheme} from "@mui/system";
import {useTranslation} from "react-i18next";
import useApi from "../../../hooks/use-api";
import {useIsMobile} from "../../../hooks/use-is-mobile";

function FileModal({ file, open, deleteCallback, onUpdate, onClose }){

    const getSrc = useCreateSrc();
    const theme = useTheme();
    const { t } = useTranslation();
    const { get, post } = useApi();
    const { isMobile } = useIsMobile();
    const [actions, setActions] = useState([]);
    const [usage, setUsage] = useState([]);
    const [renameDialogOpen, setRenameDialogOpen] = useState(false);
    const [data, setData] = useState(null);
    const owner = useSelector(state => {
        if(file && file.owner){
            // check if the file owner exists
            if(state.users.users.filter(u => u.id === file.owner).length > 0){
                // return the owner instance of the file
                return state.users.users.find(u => u.id === file.owner);
            } else {
                // in this case the owner is unkown or does not exist - do not show
                return null;
            }
        } else {
            return null;
        }
    });
    const pdfWrapperRef = useRef();

    const downloadFile = () => {
        if(file){
            window.location.href = file.view;
        }
    }

    const handleRename = (update) => {
        setData(update);
        if(onUpdate)
            onUpdate(update);
    }

    const handleTogglePublic = () => {
        post('core/files/' + file.id + '/toggle_public').then(data => {
            setData(data);
            if(onUpdate)
                onUpdate(data);
        });
    }

    const openRenameDialog = () => {
        setRenameDialogOpen(true);
    }

    const closeRenameDialog = () => {
        setRenameDialogOpen(false);
    }

    useEffect(() => {
        if(file)
            setData(file);
    }, [file]);

    useEffect(() => {
        if(open){
            get('core/files/' + file.id + '/usage').then(data => {
                setUsage(data);
            });
        }
    }, [file, open]);

    useEffect(() => {
        if(data){
            let tmp = [];
            if(data.can_delete && (deleteCallback !== null))
                tmp.push({
                    name: (
                        <Tooltip title={t('common.tooltips.remove_file')}>
                            <IconButton color="inherit" >
                                <SvgIcon fontSize="small">
                                    <DeleteIcon />
                                </SvgIcon>
                            </IconButton>
                        </Tooltip>
                    ),
                    action: () => {
                        onClose();
                        // if delete callback exists, call
                        deleteCallback(data.id);
                    }
                });
            if(data.can_edit){
                tmp.push({
                    name: (
                        <Tooltip title={data.is_public ? t('common.tooltips.make_private') : t('common.tooltips.make_public')}>
                            <IconButton color="inherit">
                                <SvgIcon fontSize="small">
                                    <TogglePublicIcon />
                                </SvgIcon>
                            </IconButton>
                        </Tooltip>
                    ),
                    action: handleTogglePublic
                });
                tmp.push({
                    name: (
                        <Tooltip title={t('common.tooltips.rename_file')}>
                            <IconButton color="inherit" >
                                <SvgIcon fontSize="small">
                                    <RenameIcon />
                                </SvgIcon>
                            </IconButton>
                        </Tooltip>
                    ),
                    action: openRenameDialog
                });
            }
            tmp.push({
                name: (
                    <Tooltip title={t('common.tooltips.download')}>
                        <IconButton color="inherit" >
                            <SvgIcon fontSize="small">
                                <DownloadIcon />
                            </SvgIcon>
                        </IconButton>
                    </Tooltip>
                ),
                action: downloadFile
            });
            setActions(tmp);
        } else {
            setActions([]);
        }
    }, [data]);

    if(data === null)
        return null;

    return (
        <FullscreenWrapper
            closeCallback={onClose}
            open={open}
            actions={actions}
            fullScreen={isMobile}
            title={data['name']}
        >
            <FileRenameDialog
                open={renameDialogOpen}
                file={data}
                handleClose={closeRenameDialog}
                onSuccess={handleRename}
            />
            <Grid container spacing={2}>
                <Grid item xs={12} md={5} lg={4}>
                    <Card style={{padding: 0}}>
                        <CardContent style={{paddingTop: 0, paddingBottom: 0}}>
                            <List>
                                <ListItem style={{padding: theme.spacing(2, 0), justifyContent: 'space-between'}} key={'uploader'} disableGutters divider>
                                    <Box display="flex" alignItems="center">
                                        {owner ? (
                                            <>
                                                <UserAvatar
                                                    alt="Person"
                                                    style={{marginRight: 20}}
                                                    user={owner}
                                                    component={RouterLink}
                                                    to={"/groon/profile/" + owner.id}
                                                />
                                                <Stack direction="column">
                                                    <Link
                                                        underline="hover"
                                                        variant="h6"
                                                        component={RouterLink}
                                                        color="textPrimary"
                                                        to={"/groon/profile/" + owner.id}
                                                    >
                                                        {owner.name}
                                                    </Link>
                                                    <Typography color="textSecondary" variant="caption">
                                                        {t("attributes.published")}
                                                    </Typography>
                                                </Stack>
                                            </>
                                        ) : (
                                            <Stack direction="column">
                                                <Link underline="hover" variant="h6" component={RouterLink} color="textPrimary">
                                                    {t("common.module")}
                                                </Link>
                                                <Typography color="textSecondary" variant="caption">
                                                    {t("attributes.generated")}
                                                </Typography>
                                            </Stack>
                                        )}
                                    </Box>
                                </ListItem>
                                <ListItem style={{padding: theme.spacing(2, 0), justifyContent: 'space-between'}} key={'name'} disableGutters divider>
                                    <Typography variant="subtitle2" color="textPrimary" >
                                        {t("attributes.name")}
                                    </Typography>
                                    <Typography noWrap variant="body1" color="textPrimary" >
                                        {data.name}
                                    </Typography>
                                </ListItem>
                                <ListItem style={{padding: theme.spacing(2, 0), justifyContent: 'space-between'}} key={'name'} disableGutters divider>
                                    <Typography variant="subtitle2" color="textPrimary" >
                                        {t("attributes.status")}
                                    </Typography>
                                    <Typography noWrap variant="body1" color="textPrimary" >
                                        {data.is_public ? t('common.public') : t('common.private')}
                                    </Typography>
                                </ListItem>
                                {data.is_public && (
                                    <ListItem style={{padding: theme.spacing(2, 0), justifyContent: 'space-between'}} key={'name'} disableGutters divider>
                                        <Typography variant="subtitle2" color="textPrimary" >
                                            {t("attributes.url")}
                                        </Typography>
                                        <Typography variant="body1" color="textPrimary" >
                                            {data.public_link}
                                        </Typography>
                                    </ListItem>
                                )}
                                <ListItem style={{padding: theme.spacing(2, 0), justifyContent: 'space-between'}} key={'uploaded'} disableGutters divider>
                                    <Typography variant="subtitle2" color="textPrimary" >
                                        {owner ? t('attributes.uploaded') : t('attributes.created')}
                                    </Typography>
                                    <Typography variant="body1" color="textPrimary" >
                                        {moment(data.created_at).format('DD.MM.yyyy')}
                                    </Typography>
                                </ListItem>
                                <ListItem style={{padding: theme.spacing(2, 0), justifyContent: 'space-between'}} key={'size'} disableGutters divider>
                                    <Typography variant="subtitle2" color="textPrimary" >
                                        {t("attributes.size")}
                                    </Typography>
                                    <Typography variant="body1" color="textPrimary" >
                                        {bytesToSize(data.size)}
                                    </Typography>
                                </ListItem>
                                <ListItem style={{padding: theme.spacing(2, 0), justifyContent: 'space-between'}} key={'downloads'} disableGutters>
                                    <Typography variant="subtitle2" color="textPrimary" >
                                        {t("attributes.downloads")}
                                    </Typography>
                                    <Typography variant="body1" color="textPrimary" >
                                        {data.downloads}
                                    </Typography>
                                </ListItem>
                            </List>
                        </CardContent>
                    </Card>
                    <Box mt={2}>
                        {usage.map((u, i) => (
                            <Chip
                                key={i}
                                label={u}
                                color="primary"
                                variant="contained"
                                style={{margin: theme.spacing(0.5)}}
                            />
                        ))}
                    </Box>
                </Grid>
                <Grid item xs={12} md={7} lg={8}>
                    <Card>
                        <CardContent ref={pdfWrapperRef} style={{padding: 0, margin: 0, width: '100%'}}>
                            <FileGenericViewer file={data} pdfWrapperRef={pdfWrapperRef} />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </FullscreenWrapper>
    )
}

FileModal.propTypes = {
    file: PropTypes.object.isRequired,
    deleteCallback: PropTypes.func,
    onUpdate: PropTypes.func,
    onClose: PropTypes.func,
    open: PropTypes.bool.isRequired,
};

FileModal.defaultProps = {
    deleteCallback: null,
};

export default FileModal;