import React, {useCallback, useEffect, useState, memo, useRef, useMemo} from 'react';
import {
    Autocomplete,
    Box,
    Chip,
    IconButton,
    Paper,
    Stack,
    SvgIcon,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import XIcon from "@untitled-ui/icons-react/build/esm/XClose";
import useOmniaApi from "../../../hooks/use-omnia-api";
import PropTypes from "prop-types";
import debounce from "lodash.debounce";
import {styled} from "@mui/system";
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import {useTranslation} from "react-i18next";

const StyledPaper = styled(Paper)({
    minWidth: '300px !important',
});

function SmartSelector({
                           options,
                           endpoint,
                           query,
                           error,
                           helperText,
                           label,
                           multiple,
                           values,
                           disabled,
                           handleChange,
                           onChange,
                           onInputChange,
                           onSelectionLoad,
                           name,
                           labelField,
                           identField,
                           translate,
                           renderOptionChip,
                           secondaryLabelField,
                           ...rest
                       }) {

    const [opt, setOpt] = useState([]);
    const [selectedOpt, setSelectedOpt] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [allOptions, setAllOptions] = useState(false);
    const [loadingSelected, setLoadingSelected] = useState(false);
    const { get } = useOmniaApi();
    const allOptionsRef = useRef();
    allOptionsRef.current = allOptions;
    const requestIdRef = useRef(0); // For tracking API requests
    const [duplicateLabels, setDuplicateLabels] = useState({});

    const onChangeHandler = (event, newValue) => {

        if (endpoint) {
            if (multiple) {
                setInputValue('');
            } else {
                setInputValue(newValue ? newValue[labelField] : '');
            }
            debouncedChangeHandler(null);
        }

        const updateEvent = {
            target: {
                name: name,
                value: newValue ? (multiple ? newValue.map((item) => item[identField]) : newValue[identField]) : null,
                object: newValue
            }
        };

        handleChange?.(updateEvent);
        if (onChange)
            onChange(updateEvent);
    }

    const loadOptions = (searchTerm) => {
        const currentRequestId = ++requestIdRef.current; // Increment request ID
        if (!allOptionsRef.current) {
            setLoading(true);
            get(endpoint, {
                ...query,
                ...(searchTerm ? {
                    search: searchTerm, page: 1, size: 50
                } : {
                    page: 1,
                    size: 50
                })
            }).then((response) => {
                if (currentRequestId === requestIdRef.current) { // Only update if it's the latest request
                    setAllOptions(Array.isArray(response));
                    if (Array.isArray(response)) {
                        setOpt(response);
                    } else {
                        setOpt(response.results || []);
                    }
                }
            }).catch(errors => {
                console.log('Smart Selector could not fetch details: ', errors);
            }).finally(() => {
                if (currentRequestId === requestIdRef.current) {
                    setLoading(false);
                }
            })
        }
    }

    const debouncedChangeHandler = useCallback(debounce(loadOptions, 500), []);

    const handleInputChange = (event, value, reason) => {
        if (reason !== 'reset') {
            if (typeof value !== 'object') {
                setInputValue(value);
            }
            debouncedChangeHandler(value);
        }
    };

    const resetValue = () => {
        if (window.confirm(t('notify.are_you_sure'))) {
            handleChange?.({
                target: {
                    name: name,
                    value: null
                }
            })
        }
    }

    const totalOptions = useMemo(() => {
        const optionMap = new Map();
        [...opt, ...selectedOpt].forEach(option => {
            optionMap.set(option[identField], option);
        });
        return Array.from(optionMap.values());
    }, [opt, selectedOpt, identField]);

    const selectedValue = multiple ? totalOptions.filter((i) => values?.includes(i[identField])) : (totalOptions.find((i) => i[identField] === values) || null);

    const getValueByPath = (obj, path) => {
        return path.split('.').reduce((o, p) => o?.[p], obj);
    };

    useEffect(() => {
        const labelCounts = {};
        const labelsMap = {};
        totalOptions.forEach(option => {
            const label = translate ? t(option[labelField]) : option[labelField];
            labelCounts[label] = (labelCounts[label] || 0) + 1;
            if (!labelsMap[label]) {
                labelsMap[label] = [option];
            } else {
                labelsMap[label].push(option);
            }
        });
        const duplicates = {};
        Object.keys(labelsMap).forEach(label => {
            if (labelCounts[label] > 1) {
                labelsMap[label].forEach(option => {
                    duplicates[option[identField]] = true;
                });
            }
        });
        setDuplicateLabels(duplicates);
    }, [totalOptions, labelField, identField, translate, t]);

    useEffect(() => {
        if (onSelectionLoad) {
            onSelectionLoad(multiple ? selectedOpt : selectedOpt[0]);
        }
    }, [selectedOpt, multiple]);

    useEffect(() => {
        if (endpoint) {
            setLoadingSelected(true);
            if (multiple && values.length > 0) {
                get(endpoint, {[identField + '__in']: values}).then((response) => {
                    setSelectedOpt(response || []);
                }).finally(() => {
                    setLoadingSelected(false);
                })
            }
            if (!multiple && values) {
                get(endpoint, {[identField + '__in']: values}).then((response) => {

                    const rawResponse = response || [];
                    const filteredResponse = rawResponse.filter((item) => multiple ? values.includes(item[identField]) : item[identField] === values);

                    setSelectedOpt(filteredResponse);

                    if (inputValue === '' && filteredResponse.length > 0) {
                        setInputValue(filteredResponse[0][labelField]);
                    }

                }).finally(() => {
                    setLoadingSelected(false);
                });
            }
        }
    }, [endpoint, values]);

    useEffect(() => {
        if (endpoint) {
            loadOptions(null);
        } else {
            if (typeof (options) !== "undefined") {
                setOpt(options);
            } else {
                setOpt([]);
            }
        }
    }, [options, query]);

    useEffect(() => {
        if (typeof inputValue === 'string') {
            onInputChange?.(inputValue);
        }
    }, [inputValue]);

    if ((!multiple && (values && !selectedValue)) || (multiple && (values.length > 0 && selectedValue.length === 0)))
        return (
            <TextField
                label={loadingSelected ? t('common.loading') : t('common.no_data')}
                value={null}
                fullWidth={true}
                disabled={true}
                InputProps={{
                    endAdornment: (
                        <Tooltip title={t('common.reset')}>
                            <IconButton size="small" onClick={resetValue}>
                                <SvgIcon fontSize="small">
                                    <XIcon/>
                                </SvgIcon>
                            </IconButton>
                        </Tooltip>
                    )
                }}
                {...rest}
            />
        );

    return (
        <Autocomplete
            options={totalOptions}
            multiple={multiple}
            value={selectedValue}
            onChange={onChangeHandler}
            noOptionsText={loading ? t('common.loading') : t('common.sst_no_results')}
            sx={{width: '100%'}}
            disabled={disabled}
            isOptionEqualToValue={(option, value) => option[identField] === value[identField]}
            renderTags={(value) => {
                if (multiple) {
                    if (value.length > 2) {
                        return (
                            <Typography variant="body2" sx={{pl: 0.5}}>
                                {value.length} ausgewählt
                            </Typography>
                        );
                    } else {
                        return value.map((option, index) => (
                            <Typography key={option[identField]} variant="body2" sx={{pl: 0.5}}>
                                {translate ? t(option[labelField]) : option[labelField]}{index !== value.length - 1 ? ', ' : ''}
                            </Typography>
                        ));
                    }
                }
            }}
            getOptionLabel={(option) => {
                if (typeof option === 'string') {
                    return option;
                }
                const isDuplicate = duplicateLabels[option[identField]];
                const label = translate ? t(option[labelField]) : option[labelField];
                const secondaryLabel = isDuplicate
                    ? secondaryLabelField
                        ? getValueByPath(option, secondaryLabelField) || option[identField]
                        : option[identField]
                    : '';
                return isDuplicate ? `${label} (${secondaryLabel})` : label;
            }}
            renderOption={(props, option) => {
                const isDuplicate = duplicateLabels[option[identField]];
                const label = translate ? t(option[labelField]) : option[labelField];
                const secondaryLabel = isDuplicate
                    ? secondaryLabelField
                        ? getValueByPath(option, secondaryLabelField) || option[identField]
                        : option[identField]
                    : '';
                return (
                    <li {...props}>
                        <Stack
                            direction="row"
                            spacing={2}
                            sx={{ width: '100%' }}
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Typography>
                                {label} {(isDuplicate && renderOptionChip) && `(${secondaryLabel})`}
                            </Typography>
                            {(isDuplicate && !renderOptionChip) && (
                                <Chip size="small" label={secondaryLabel} />
                            )}
                            {renderOptionChip && renderOptionChip(option)}
                        </Stack>
                    </li>
                );
            }}
            componentsProps={{
                paper: {
                    component: StyledPaper,
                },
            }}
            renderInput={(params) => {
                return (
                    <TextField
                        error={error}
                        helperText={helperText}
                        disabled={disabled}
                        label={label}
                        name={name}
                        {...rest}
                        {...params}
                    />
                )
            }}
            inputValue={translate ? t(inputValue) : inputValue}
            onInputChange={handleInputChange}
        />
    )

}

SmartSelector.propTypes = {
    values: PropTypes.oneOfType([PropTypes.array, PropTypes.number]).isRequired,
    handleChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    options: PropTypes.array,
    error: PropTypes.bool,
    endpoint: PropTypes.string,
    multiple: PropTypes.bool,
    translate: PropTypes.bool,
    disabled: PropTypes.bool,
    query: PropTypes.object,
    helperText: PropTypes.string,
    label: PropTypes.string,
    labelField: PropTypes.string,
    identField: PropTypes.string,
    onChange: PropTypes.func,
    secondaryLabelField: PropTypes.string, // Added prop
}

SmartSelector.defaultProps = {
    error: false,
    endpoint: null,
    touched: false,
    disabled: false,
    translate: false,
    multiple: true,
    query: {},
    labelField: 'name',
    identField: 'id',
    helperText: '',
    label: null,
    secondaryLabelField: null, // Default to null
}

export default memo(SmartSelector, (prevProps, nextProps) => {

    const areArraysOfObjectsEqual = (array1, array2, key) => {
        const sortedArray1 = sortBy(array1, key);
        const sortedArray2 = sortBy(array2, key);
        return isEqual(sortedArray1, sortedArray2);
    };

    const valueChecker = {
        endpoint: prevProps.endpoint === nextProps.endpoint,
        options: areArraysOfObjectsEqual(prevProps.options, nextProps.options, prevProps.identField),
        query: isEqual(prevProps.query, nextProps.query),
        error: prevProps.error === nextProps.error,
        helperText: prevProps.helperText === nextProps.helperText,
        label: prevProps.label === nextProps.label,
        multiple: prevProps.multiple === nextProps.multiple,
        values: isEqual(prevProps.values, nextProps.values),
        disabled: prevProps.disabled === nextProps.disabled,
        name: prevProps.name === nextProps.name,
        labelField: prevProps.labelField === nextProps.labelField,
        identField: prevProps.identField === nextProps.identField,
        translate: prevProps.translate === nextProps.translate,
        secondaryLabelField: prevProps.secondaryLabelField === nextProps.secondaryLabelField, // Include in comparison
    }

    return Object.values(valueChecker).every(value => value);
});
