import produce from 'immer';
import {
    UPDATE_SITE,
    UPDATE_ENTITY,
    REMOVE_ENTITY,
    INIT_ENTITY, SET_ADD_ELEMENT,
} from 'src/omnia/store/actions/site-designer-actions';

const initialState = {
    site: null,
    addElementOpen: false,
    initialized: false,
    sections: [],
    rows: [],
    columns: [],
    elements: [],
    texts: [],
    images: []
};

// A utility function to handle updates
function handleUpdate(collection, item) {
    const index = collection.findIndex(i => i.id === item.id);
    if (index !== -1) {
        collection[index] = item;
    } else {
        collection.push(item);
    }
    // Sort collection after update, only if items have 'position' attribute
    if (item.hasOwnProperty('position')) {
        collection.sort((a, b) => a.position - b.position);
    }
}

// A utility function to handle removals
function handleRemove(collection, itemId) {
    const index = collection.findIndex(i => i.id === itemId);
    if (index !== -1) {
        collection.splice(index, 1);
    }
}

const siteDesignerReducer = (state = initialState, action) => {

    switch (action.type) {

        case UPDATE_SITE: {
            return produce(state, draft => {
                draft.site = action.payload.site;
                draft.initialized = true;
            });
        }

        case SET_ADD_ELEMENT: {
            return produce(state, draft => {
                draft.addElementOpen = action.payload.open;
            });
        }

        case INIT_ENTITY: {
            return produce(state, draft => {
                const entity = action.payload.entity;
                draft[entity] = action.payload.objects;
                // Sort collection after initialization, only if items have 'position' attribute
                if (draft[entity].length > 0 && draft[entity][0].hasOwnProperty('position')) {
                    draft[entity].sort((a, b) => a.position - b.position);
                }
            });
        }

        case UPDATE_ENTITY: {
            return produce(state, draft => {
                const entity = action.payload.entity;
                handleUpdate(draft[entity], action.payload.object);
            });
        }

        case REMOVE_ENTITY: {
            return produce(state, draft => {
                const entity = action.payload.entity;
                handleRemove(draft[entity], action.payload.objectId);
            });
        }

        default: {
            return state;
        }
    }
};

export default siteDesignerReducer;
