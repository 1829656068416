import React, {useCallback, useEffect, useState} from "react";
import {useDialog} from "../../../../hooks/use-dialog";
import {useTranslation} from "react-i18next";
import {useNotifications} from "../../../../hooks/use-notifications";
import useOmniaApi from "../../../../hooks/use-omnia-api";
import useCoreFormik from "../../../../hooks/formik/use-core-formik";
import {Box, Checkbox, Chip, ClickAwayListener, IconButton, Stack} from "@mui/material";
import {TaskDrawer} from "./task-drawer";
import InlineEditInput from "../../../elements/inline-edit-input";
import OnIcon from "../../../elements/icon";
import PropTypes from "prop-types";
import moment from "moment";

export default function TaskListItem({task, onClose, onSave, onChanged, defaultEditing, contact, project, ...rest}) {
    const taskDialog = useDialog();
    const {t} = useTranslation();
    const {notifySuccess} = useNotifications();
    const {put} = useOmniaApi();
    const [isEditing, setIsEditing] = useState(defaultEditing);
    const [clickedField, setClickedField] = useState('');

    const onSubmit = (values, helpers) => {

        // Send the data to the API
        put('core/tasks', values)
            .then((updatedTask) => {
                // Call on save
                onSave?.(updatedTask);
                // When this point is reached, validation was performed
                setIsEditing(false);
                onClose?.();
            })
            .finally(() => {
                helpers.setSubmitting(false);
            });
    };

    const {taskFormik} = useCoreFormik(task, onSubmit);

    const priorityPrefixMap = {
        low: '',
        medium: '!',
        high: '!!',
        urgent: '!!!'
    }

    const handleEdit = (activeField) => {
        setClickedField(activeField);
        setIsEditing(true);
    };

    const handleClickAway = useCallback(() => {
        if(isEditing){
            taskFormik.handleSubmit();
        }
        setIsEditing(false);
    }, [isEditing])

    useEffect(() => {
        if (task)
            taskFormik.setValues(task);
    }, [task]);

    return (
        <Box
            sx={{
                width: '100%',
                '&:hover .task-icon-button': {
                    opacity: 1,
                },
            }}
        >
            {task && (
                <TaskDrawer
                    task={task}
                    open={taskDialog.open}
                    onClose={taskDialog.handleClose}
                />
            )}
            <Stack
                direction="row"
                justifyContent="space-between"
                spacing={2}
                sx={{px: 2, width: '100%'}}
                alignItems="flex-start"
                {...rest}
            >
                <Checkbox
                    name="is_done"
                    onChange={(event, val) => {
                        taskFormik.setFieldValue('is_done', val);
                        taskFormik.handleSubmit();
                    }}
                    checked={taskFormik.values?.is_done}
                    color="primary"
                />
                <ClickAwayListener sx={{width: '100%'}} onClickAway={handleClickAway}>
                    <Stack
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={1}
                        sx={{width: '100%', pt: 1.5}}
                    >
                        <Box sx={{width: '100%'}}>
                            {isEditing ? (
                                <>
                                    <InlineEditInput
                                        name="name"
                                        variant="h6"
                                        prefix={priorityPrefixMap?.[taskFormik.values.priority] || ''}
                                        prefixColor='error'
                                        sx={{lineHeight: 1}}
                                        defaultEditing={(clickedField === 'name') || defaultEditing}
                                        onChange={(value) => {
                                            if (value !== '') onChanged?.();
                                            taskFormik.setFieldValue('name', value);
                                        }}
                                        value={taskFormik.values.name}
                                        placeholder={t('attributes.name')}
                                        error={taskFormik.touched.name && !!taskFormik.errors.name}
                                        helperText={taskFormik.touched.name && taskFormik.errors.name}
                                    />
                                    <InlineEditInput
                                        name="description"
                                        variant="body1"
                                        color="textSecondary"
                                        defaultEditing={clickedField === 'description'}
                                        onChange={(value) => {
                                            if (value !== '') onChanged?.();
                                            taskFormik.setFieldValue('description', value);
                                        }}
                                        value={taskFormik.values.description}
                                        placeholder={t('attributes.notes')}
                                        error={taskFormik.touched.description && !!taskFormik.errors.description}
                                        helperText={taskFormik.touched.description && taskFormik.errors.description}
                                    />
                                </>
                            ) : (
                                <>
                                    <InlineEditInput
                                        variant="h6"
                                        prefix={priorityPrefixMap?.[taskFormik.values.priority] || ''}
                                        prefixColor='error'
                                        onClick={() => handleEdit('name')}
                                        value={taskFormik.values.name}
                                        placeholder={t('attributes.name')}
                                        sx={{lineHeight: 1}}
                                    />

                                    {taskFormik.values.description && (
                                        <InlineEditInput
                                            variant="body1"
                                            onClick={() => handleEdit('description')}
                                            value={taskFormik.values.description}
                                            placeholder={t('attributes.notes')}
                                            color="textSecondary"
                                        />
                                    )}
                                </>
                            )}
                        </Box>
                        {task?.id && (
                            <Stack direction="row" alignItems="center" spacing={1}>
                                {!['open', 'done'].includes(task?.status) && (
                                    <Chip
                                        color="primary"
                                        label={t('core.tasks.states.' + task?.status)}
                                        size="small"
                                    />
                                )}
                                {task?.due_date && (
                                    <Chip
                                        label={moment(task?.due_date).fromNow()}
                                        color={moment(task?.due_date).isBefore(moment()) ? 'error' : 'default'}
                                        size="small"
                                    />
                                )}
                                {task?.order && (
                                    <Chip
                                        icon={<OnIcon sx={{ml: 1}} iconName="Atom02" size="small" />}
                                        label={task?.order?.number}
                                        color="default"
                                        variant="outlined"
                                        size="small"
                                    />
                                )}
                                {task?.project && (
                                    <Chip
                                        icon={<OnIcon sx={{ml: 1}} iconName="Headphones02" size="small" />}
                                        label={task?.project?.title}
                                        color="default"
                                        variant="outlined"
                                        size="small"
                                    />
                                )}
                                {task?.contact && (
                                    <Chip
                                        icon={<OnIcon sx={{ml: 1}} iconName="Users02" size="small" />}
                                        label={task?.contact?.name}
                                        color="default"
                                        variant="outlined"
                                        size="small"
                                    />
                                )}
                            </Stack>
                        )}
                    </Stack>
                </ClickAwayListener>
                {task?.id && (
                    <IconButton
                        onClick={taskDialog.handleOpen}
                        className="task-icon-button"
                        sx={{
                            opacity: 0,
                            transition: 'opacity 0.3s',
                        }}
                    >
                        <OnIcon iconName="InfoCircle"/>
                    </IconButton>
                )}
            </Stack>
        </Box>
    );
};

TaskListItem.defaultProps = {
    defaultEditing: false,
    project: null,
    order: null,
    contact: null
}

TaskListItem.propTypes = {
    task: PropTypes.object,
    project: PropTypes.object,
    contact: PropTypes.object,
    order: PropTypes.object,
    onClose: PropTypes.func,
    onSave: PropTypes.func,
    defaultEditing: PropTypes.bool
}