import React, {useCallback, useRef, useState} from "react";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {
    Avatar,
    Box,
    CircularProgress,
    Dialog,
    DialogContent,
    Divider,
    InputAdornment,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    OutlinedInput,
} from "@mui/material";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import debounce from "lodash.debounce";
import useOmniaApi from "../../../hooks/use-omnia-api";
import {useNavigate} from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import HoverableFileCard from "../file-representations/files-overview/hoverable-file-card";
import TaskListItem from "../../modules/core/my-tasks/task-list-item";
import ContextCard from "../../modules/connections/context/context-card";
import {OrderListTable} from "../../modules/resources/orders/order-list-table";

function SearchDialog({popover, size, sx}){
    const { t } = useTranslation();
    const [body, setBody] = useState('');
    const { get } = useOmniaApi({autoError: false});
    const textInputRef = useRef();
    const inputEl = useRef(null);
    const [searchResults, setSearchResults] = useState({});
    const [loadingEndpoints, setLoadingEndpoints] = useState([]);
    const navigate = useNavigate();
    textInputRef.current = body;

    const handleSearch = () => {
        setLoadingEndpoints(['search']);
        get('setup/search', {search: textInputRef.current}).then(response => {
            setSearchResults(response);
        }).finally(() => {
            setLoadingEndpoints(prev => prev.filter(endpoint => endpoint !== 'search'));
        })

    }

    const outlinedFixedHeight = {
        small: 45,
        medium: 45,
        large: 45
    }

    const handleOpenOrder = (order) => {
        handleNavigate('/groon/resources/order/' + order);
    }

    const debouncedChangeHandler = useCallback(debounce(handleSearch, 250), [handleSearch]);

    const handleChange = useCallback((event) => {
        setBody(event.target.value);
        if(event.target.value !== "")
            debouncedChangeHandler();
    }, []);

    const handleNavigate = (link) => {
        popover.handleClose();
        navigate(link);
    }

    return (
        <Dialog
            open={popover.open}
            onClose={popover.handleClose}
            maxWidth="sm"
            fullWidth={true}
            TransitionProps={{
                onEntered: () => {
                    if (body && inputEl.current) {
                        inputEl.current.select();
                    }
                }
            }}
        >
            <DialogContent sx={{p: 0}}>
                <Box sx={{p: 1}}>
                    <OutlinedInput
                        fullWidth
                        multiline
                        maxRows={8}
                        autoFocus={true}
                        inputRef={inputEl}
                        onChange={handleChange}
                        size={size}
                        sx={{ ...sx, ...{ minHeight: outlinedFixedHeight?.['sm'] } }}
                        placeholder={t('common.groon_search')}
                        value={body}
                        endAdornment={(
                            <InputAdornment position="end">
                                {loadingEndpoints.length > 0 && (
                                    <CircularProgress size={20} />
                                )}
                            </InputAdornment>
                        )}
                        inputProps={{
                            fontSize: 40
                        }}
                    />
                </Box>
                {body !== '' && (
                    <List sx={{padding: 0, maxHeight: 600, overflowY: 'auto'}}>

                        {Object.keys(searchResults).map(key => {

                            const results = searchResults[key];

                            return (
                                <>
                                    {(key === 'contacts' && results?.length > 0) ? (
                                        <>
                                            <Divider sx={{my: 1}} />
                                            <ListItem>
                                                <ListItemText primary={t('core.search.contacts')} primaryTypographyProps={{variant: 'overline'}} />
                                            </ListItem>
                                            {results?.map(contact => (
                                                <ListItem key={contact.id} sx={{p: 0}}>
                                                    <ListItemButton onClick={() => handleNavigate(contact?.object_link)}>
                                                        <ListItemAvatar>
                                                            <Avatar src={contact?.avatar?.view} />
                                                        </ListItemAvatar>
                                                        <ListItemText primary={contact?.name} secondary={contact?.position} />
                                                    </ListItemButton>
                                                </ListItem>
                                            ))}
                                        </>
                                    ) : (key === 'users' && results?.length > 0) ? (
                                        <>
                                            <Divider sx={{my: 1}} />
                                            <ListItem>
                                                <ListItemText primary={t('core.search.users')} primaryTypographyProps={{variant: 'overline'}} />
                                            </ListItem>
                                            {results?.map(user => (
                                                <ListItem key={user.id} sx={{p: 0}}>
                                                    <ListItemButton onClick={() => handleNavigate(user?.object_link)}>
                                                        <ListItemAvatar>
                                                            <Avatar src={user?.avatar?.view} />
                                                        </ListItemAvatar>
                                                        <ListItemText primary={user?.name} secondary={user?.position} />
                                                    </ListItemButton>
                                                </ListItem>
                                            ))}
                                        </>
                                    ) : (key === 'tasks' && results?.length > 0) ? (
                                        <>
                                            <Divider sx={{my: 1}} />
                                            <ListItem>
                                                <ListItemText primary={t('core.search.tasks')} primaryTypographyProps={{variant: 'overline'}} />
                                            </ListItem>
                                            {results?.map(task => (
                                                <TaskListItem
                                                    task={task}
                                                    key={'task-' + task.id}
                                                />
                                            ))}
                                        </>
                                    ) : (key === 'files' && results?.length > 0) ? (
                                        <>
                                            <ListItem>
                                                <ListItemText
                                                    primary={t('core.search.files')}
                                                    primaryTypographyProps={{ variant: 'overline' }}
                                                />
                                            </ListItem>

                                            <Swiper
                                                spaceBetween={10}
                                                slidesPerView={3}
                                                style={{ padding: 15, paddingTop: 5}}
                                                breakpoints={{
                                                    320: { slidesPerView: 1, spaceBetween: 2 },
                                                    600: { slidesPerView: 2, spaceBetween: 4 },
                                                    900: { slidesPerView: 3, spaceBetween: 6 }
                                                }}
                                            >
                                                {results.map(file => (
                                                    <SwiperSlide key={file.id || file.name}>
                                                        <HoverableFileCard
                                                            file={file}
                                                            allowDeletion={false}
                                                        />
                                                    </SwiperSlide>
                                                ))}
                                            </Swiper>
                                        </>
                                    ) : (key === 'contexts' && results?.length > 0) ? (
                                        <>
                                            <ListItem>
                                                <ListItemText
                                                    primary={t('core.search.contexts')}
                                                    primaryTypographyProps={{ variant: 'overline' }}
                                                />
                                            </ListItem>

                                            <Swiper
                                                spaceBetween={10}
                                                slidesPerView={1}
                                                style={{ padding: 15, paddingTop: 5}}
                                                breakpoints={{
                                                    400: { slidesPerView: 1, spaceBetween: 2 },
                                                    // 800: { slidesPerView: 2, spaceBetween: 4 },
                                                }}
                                            >
                                                {results.map(context => (
                                                    <SwiperSlide key={context.id || context.name}>
                                                        <ContextCard context={context} />
                                                    </SwiperSlide>
                                                ))}
                                            </Swiper>
                                        </>
                                    ) : (key === 'orders' && results?.length > 0) ? (
                                        <>
                                            <Divider sx={{my: 1}} />
                                            <ListItem>
                                                <ListItemText primary={t('core.search.orders')} primaryTypographyProps={{variant: 'overline'}} />
                                            </ListItem>
                                            <OrderListTable
                                                orders={results}
                                                onOpenDrawer={handleOpenOrder}
                                                minimal={true}
                                            />
                                        </>
                                    ) : (key === 'offers' && results?.length > 0) ? (
                                        <>
                                            <Divider sx={{my: 1}} />
                                            <ListItem>
                                                <ListItemText primary={t('core.search.offers')} primaryTypographyProps={{variant: 'overline'}} />
                                            </ListItem>
                                            {results?.map(offer => (
                                                <ListItem key={offer.id} sx={{p: 0}}>
                                                    <ListItemButton onClick={() => handleNavigate(offer?.object_link)}>
                                                        <ListItemText primary={offer?.object_name} secondary={offer?.type} />
                                                    </ListItemButton>
                                                </ListItem>
                                            ))}
                                        </>
                                    ) : (results?.length > 0) && (
                                        <>
                                            <Divider sx={{my: 1}} />
                                            <ListItem>
                                                <ListItemText primary={t('core.search.' + key)} primaryTypographyProps={{variant: 'overline'}} />
                                            </ListItem>
                                            {results?.map(sharableObject => (
                                                <ListItem key={sharableObject.id} sx={{p: 0}}>
                                                    <ListItemButton onClick={() => handleNavigate(sharableObject?.object_link)}>
                                                        <ListItemText
                                                            primary={sharableObject?.object_name}
                                                            secondary={sharableObject?.type || sharableObject?.kind || sharableObject?.subline || sharableObject?.description}
                                                            primaryTypographyProps={{noWrap: true}}
                                                            secondaryTypographyProps={{noWrap: true}}
                                                        />
                                                    </ListItemButton>
                                                </ListItem>
                                            ))}
                                        </>
                                    )}
                                </>
                            )
                        })}

                    </List>
                )}
            </DialogContent>
        </Dialog>
    )
}

SearchDialog.propTypes = {
    popover: PropTypes.object,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    sx: PropTypes.object
};

SearchDialog.defaultProps = {
    size: 'large',
    sx: {}
};

export default SearchDialog;