import React, {useCallback} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const useCoreFormik = (initialData, onSubmit, enableReinitialize = false) => {

    const { t } = useTranslation();

    const TASK_PRIORITIES = [
        {
            label: t('core.tasks.priorities.low'),
            value: 'low'
        },
        {
            label: t('core.tasks.priorities.medium'),
            value: 'medium'
        },
        {
            label: t('core.tasks.priorities.high'),
            value: 'high'
        },
        {
            label: t('core.tasks.priorities.urgent'),
            value: 'urgent'
        }
    ]

    const TASK_STATUS = [
        {
            label: t('core.tasks.states.open'),
            value: 'open'
        },
        {
            label: t('core.tasks.states.in_progress'),
            value: 'in_progress'
        },
        {
            label: t('core.tasks.states.done'),
            value: 'done'
        },
        {
            label: t('core.tasks.states.on_hold'),
            value: 'on_hold'
        },
        {
            label: t('core.tasks.states.canceled'),
            value: 'canceled'
        }
    ]

    const shortLinkFormik = useCallback(useFormik({
        initialValues: initialData ? initialData : {
            name: '',
            link: '',
        },
        enableReinitialize: enableReinitialize,
        validationSchema: Yup.object().shape({
            name: Yup.string().max(1000, 'Name is too long'),
            link: Yup.string().url('Enter valid url like https://www.url.com/').required(),
        }),
        onSubmit: onSubmit
    }), [initialData, onSubmit]);

    const userFormik = useCallback(useFormik({
        initialValues: initialData ? initialData : {
            username: '',
            email: '',
            first_name: '',
            last_name: '',
            is_admin: false,
            is_blocked: false,
            is_deleted: false,
            title: '',
            gender: 'u', // Default to 'Unknown' as per your model
            position: '',
            phone: '',
            street: '',
            number: '',
            city: '',
            postal: '',
            country: '',
            bio: '',
            interests: '',
            work_location: '',
            date_of_birth: null, // Handle date format as needed
            user_rights: [], // Assuming array of rights IDs
            hired_at: (new Date()).toISOString().split('T')[0],
        },
        enableReinitialize: enableReinitialize,
        validationSchema: Yup.object().shape({
            username: Yup.string().required(t('form.is_required', {field: t('attributes.username')})).max(30, t('max_characters', {value: 30})),
            email: Yup.string().email(t('form.invalid_email')).required(t('form.is_required', {field: t('attributes.email')})),
            first_name: Yup.string().required(t('form.is_required', {field: t('attributes.first_name')})).max(50, t('max_characters', {value: 50})),
            last_name: Yup.string().required(t('form.is_required', {field: t('attributes.last_name')})).max(50, t('max_characters', {value: 50})),
            is_admin: Yup.boolean(),
            is_blocked: Yup.boolean(),
            is_deleted: Yup.boolean(),
            title: Yup.string().max(25, t('max_characters', {value: 25})),
            gender: Yup.string().oneOf(['m', 'f', 'd', 'u'], t('form.invalid_field')),
            position: Yup.string().max(200, t('max_characters', {value: 200})),
            phone: Yup.string().nullable(true).max(25, t('max_characters', {value: 25})),
            street: Yup.string().max(200, t('max_characters', {value: 200})).nullable(true),
            number: Yup.string().max(40, t('max_characters', {value: 40})).nullable(true),
            city: Yup.string().max(200, t('max_characters', {value: 200})).nullable(true),
            postal: Yup.string().max(20, t('max_characters', {value: 20})).nullable(true),
            country: Yup.string().max(100, t('max_characters', {value: 100})).nullable(true),
            bio: Yup.string().nullable(true),
            interests: Yup.string().max(200, t('max_characters', {value: 200})),
            work_location: Yup.string().max(150, t('max_characters', {value: 150})),
            date_of_birth: Yup.date().nullable(true),
            user_rights: Yup.array(),
            hired_at: Yup.date().nullable(true),
        }),
        onSubmit: onSubmit
    }), [initialData, onSubmit])

    const workflowFormik = useCallback(useFormik({
        initialValues: initialData ? initialData : {
            name: '',
            is_active: false,
            custom_data: {},
        },
        enableReinitialize: enableReinitialize,
        validationSchema: Yup.object().shape({
            name: Yup.string().required(t('form.is_required', {field: t('attributes.name')})).max(1000, t('form.max_characters', {value: 1000})),
            is_active: Yup.boolean(),
            custom_data: Yup.object().nullable(),
        }),
        onSubmit: onSubmit
    }), [initialData, onSubmit]);

    const taskFormik = useCallback(useFormik({
        initialValues: initialData ? initialData : {
            name: '',
            description: '',
            state: 'open',
            priority: 'low',
            due_date: null,
            is_archived: false,
            subscribers: [],
            attachments: [],
            assigned_to: [],
            project: null,
            contact: null,
            order: null,
            is_done: false,
        },
        enableReinitialize: enableReinitialize,
        validationSchema: Yup.object().shape({
            name: Yup.string()
                .required(t('form.is_required', { field: t('attributes.name') }))
                .max(200, t('form.max_characters', { value: 200 })),
            description: Yup.string().nullable(),
            state: Yup.string()
                .oneOf(TASK_STATUS.map(status => status.value), t('form.invalid_field')),
            priority: Yup.string()
                .oneOf(TASK_PRIORITIES.map(priority => priority.value), t('form.invalid_field')),
            due_date: Yup.date()
                .nullable()
                .typeError(t('form.invalid_date')),
            is_archived: Yup.boolean(),
            project: Yup.object()
                .nullable()
                .typeError(t('form.invalid_field')),
            contact: Yup.object()
                .nullable()
                .typeError(t('form.invalid_field')),
            order: Yup.object()
                .nullable()
                .typeError(t('form.invalid_field')),
            is_done: Yup.boolean(),
        }),
        onSubmit: onSubmit
    }), [initialData, onSubmit]);

    return {
        // Constants
        TASK_STATUS,
        TASK_PRIORITIES,

        // Formiks
        shortLinkFormik,
        userFormik,
        workflowFormik,
        taskFormik
    }

}

export default useCoreFormik;