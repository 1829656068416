export const UPDATE_SITE = '@site-designer/update-rights';
export const UPDATE_ENTITY = '@site-designer/update-entity';
export const REMOVE_ENTITY = '@site-designer/remove-entity';
export const INIT_ENTITY = '@site-designer/init-entity';
export const SET_ADD_ELEMENT = '@site-designer/set-add-element';

export function updateSite(site){
    return (dispatch) => dispatch({
        type: UPDATE_SITE,
        payload: {
            site
        }
    })
}

export function setAddElementOpen(open){
    return (dispatch) => dispatch({
        type: SET_ADD_ELEMENT,
        payload: {
            open
        }
    })
}

export function updateEntity(object, entity){
    return (dispatch) => dispatch({
        type: UPDATE_ENTITY,
        payload: {
            object,
            entity
        }
    })
}

export function removeEntity(objectId, entity){
    return (dispatch) => dispatch({
        type: REMOVE_ENTITY,
        payload: {
            objectId,
            entity
        }
    })
}

export function initEntity(objects, entity){
    return (dispatch) => dispatch({
        type: INIT_ENTITY,
        payload: {
            objects,
            entity
        }
    })
}
