import React from "react";
import {SeverityPill} from "../../../elements/severity-pill";
import {useTranslation} from "react-i18next";
import {Stack, Typography} from "@mui/material";
import PropTypes from "prop-types";

const severityMap = {
    offer: "success",
    offer_declined: "error",
    in_creation: "success",
    waiting_for_processing: "warning",
    waiting_for_processing_partly: "warning",
    is_processing: "success",
    is_processing_partly: "success",
    processing_overdue: "error",
    processing_overdue_partly: "error",
    in_delivery: "success",
    in_delivery_partly: "success",
    delivered: "success",
    delivered_partly: "success",
    completed: "success",
    completed_partly: "success",
    in_return: "warning",
    in_return_partly: "warning",
    return_overdue: "error",
    return_overdue_partly: "error",
    returned: "success",
    returned_partly: "success",
    canceled: "error",
    canceled_partly: "error"
};

function OrderStateChip({order}){

    const { t } = useTranslation();

    const state = (order?.state === 'delivered' && order?.is_service_order) ? 'delivered_service' : order?.state;

    return (
        <SeverityPill color={severityMap?.[order?.state] || 'warning'}>
            {t('resources.orders.states.' + state + (order?.is_partly ? '_partly' : ''))}
        </SeverityPill>
    )

}

OrderStateChip.propTypes = {
    order: PropTypes.object.isRequired,
};

export default OrderStateChip;