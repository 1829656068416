import React from "react";
import PropTypes from 'prop-types';
import Menu01Icon from '@untitled-ui/icons-react/build/esm/Menu01';
import {IconButton, Stack, SvgIcon} from '@mui/material';
import {AccountButton} from '../account-button';
import {NotificationsButton} from '../notifications-button';
import {ChatButton} from "../chat-button";
import BlurredBox from "../../../elements/blurred-box";
import {useSecurityCheck} from "../../../../hooks/use-security-check";
import {AssistantButton} from "../assistant-button";
import {WorkflowButton} from "../workflow-button";

const TOP_NAV_HEIGHT = 64;
const SIDE_NAV_WIDTH = 250;

export const TopNav = (props) => {
    const {onMobileNavOpen, mobileNavActive, ...other} = props;
    const { isModuleInstalled, hasRights } = useSecurityCheck();

    return (
        <BlurredBox
            component="header"
            sx={{
                position: 'sticky',
                left: {
                    lg: mobileNavActive ? '0px' : `${SIDE_NAV_WIDTH}px`
                },
                top: 0,
                width: {
                    lg: mobileNavActive ? '100%' : `calc(100% - ${SIDE_NAV_WIDTH}px)`
                },
                zIndex: (theme) => theme.zIndex.appBar
            }}
            {...other}
        >
            <Stack
                alignItems="center"
                direction="row"
                justifyContent="space-between"
                spacing={2}
                sx={{
                    minHeight: TOP_NAV_HEIGHT,
                    px: 2
                }}
            >
                <Stack
                    alignItems="center"
                    direction="row"
                    spacing={2}
                >
                    {mobileNavActive && (
                        <IconButton onClick={onMobileNavOpen}>
                            <SvgIcon>
                                <Menu01Icon/>
                            </SvgIcon>
                        </IconButton>
                    )}
                </Stack>
                <Stack
                    alignItems="center"
                    direction="row"
                    spacing={1}
                >
                    {hasRights('process_automation') && (
                        <WorkflowButton/>
                    )}
                    <AssistantButton/>
                    {isModuleInstalled('intranet') && (
                        <ChatButton/>
                    )}
                    {/*<ContactsButton />*/}
                    <NotificationsButton/>
                    <AccountButton/>
                </Stack>
            </Stack>
        </BlurredBox>
    )

};

TopNav.propTypes = {
    onMobileNavOpen: PropTypes.func,
    mobileNavActive: PropTypes.bool
};