import React, {useState} from "react";
import {ListItem, ListItemIcon, ListItemText, Tooltip, IconButton, Link, Typography} from '@mui/material';
import {bytesToSize} from "src/omnia/utils/bytesToSize";
import {
    Download as DownloadIcon,
    File as FileIcon
} from 'react-feather';
import {useCreateSrc} from "src/omnia/hooks/use-create-src";
import {Link as RouterLink} from "react-router-dom";
import PropTypes from "prop-types";
import {useTheme} from "@mui/system";
import FileModal from "../../../../elements/file-representations/file-modal";
import {useTranslation} from "react-i18next";
import {ItemIcon} from "../../my-files/item-icon";

function PostFileItem({file, color, simpleIcon, ...rest}) {

    const theme = useTheme();
    const getSrc = useCreateSrc();
    const [openDialog, setOpenDialog] = useState(false);
    const {t} = useTranslation();

    const handleOpenDialog = () => {
        setOpenDialog(true);
    }

    const handleCloseDialog = () => {
        setOpenDialog(false);
    }

    const downloadFile = (file) => {
        window.location.href = getSrc(file.view);
    };

    return (
        <ListItem key={'file_' + file.id} {...rest}>
            <ListItemIcon style={{marginRight: theme.spacing(2), color: 'inherit'}}>
                {simpleIcon ? (
                    <FileIcon/>
                ) : (
                    <ItemIcon type='file' extension={file.extension}/>
                )}
            </ListItemIcon>
            <ListItemText
                primary={(
                    <Link underline="hover" to="#" onClick={handleOpenDialog} component={RouterLink} color='inherit'>
                        <Typography noWrap variant="subtitle1" color='inherit'>
                            {file.name}
                        </Typography>
                    </Link>
                )}
                primaryTypographyProps={{variant: 'h5', color: 'inherit'}}
                secondary={bytesToSize(file.size)}
                secondaryTypographyProps={{variant: 'caption', color: 'inherit'}}
            />
            <Tooltip enterDelay={1000} title={t("common.tooltips.download")}>
                <IconButton
                    edge="end"
                    color='inherit'
                    onClick={() => downloadFile(file)}
                    size="large"
                >
                    <DownloadIcon/>
                </IconButton>
            </Tooltip>

            <FileModal
                open={openDialog}
                file={file}
                onClose={handleCloseDialog}
            />

        </ListItem>
    );
}

PostFileItem.propTypes = {
    file: PropTypes.object,
    simpleIcon: PropTypes.bool,
}

PostFileItem.defaultProps = {
    simpleIcon: false
}

export default PostFileItem;