import React, {useEffect, useState} from "react";
import {ChatMessage} from "./chat-message";
import {useSelector} from "react-redux";
import {imageTypes} from "../../../../../utils/file-type-helpers";
import PropTypes from "prop-types";

function ChatMessageDistributer({message, thread, handleLoaded, lastUserMessage, firstUserMessage, isChannelMessage, smallVersion}){

    const [files, setFiles] = useState([]);
    const user = useSelector(state => state.account.user);
    const [featureImages, setFeatureImages] = useState([]);
    const [isEmoji, setIsEmoji] = useState(false);

    useEffect(() => {
        if ((message.files.length > 0)) {
            setFiles(message.files.filter(i => !imageTypes.includes(i.type.toLowerCase())));
            setFeatureImages(message.files.filter(i => imageTypes.includes(i.type.toLowerCase())));
        } else {
            setFiles([]);
            setFeatureImages([]);
        }
        // check if message is emoji
        setIsEmoji(/\p{Extended_Pictographic}/u.test(message.body) && !/[a-zA-Z]/.test(message.body));
    }, [message]);

    return (
        <>
            {featureImages.map((feature, index) => (
                <ChatMessage
                    message={message}
                    thread={thread}
                    key={message.id + '-' + index}

                    contentType={'image'}
                    position={message.sender.id === user.id ? 'right' : 'left'}
                    isEmoji={isEmoji}
                    body={feature}

                    lastUserMessage={lastUserMessage}
                    firstUserMessage={firstUserMessage}

                    isChannelMessage={isChannelMessage}
                    smallVersion={smallVersion}
                    onLoaded={handleLoaded}
                />
            ))}
            <ChatMessage
                message={message}
                key={message.id}
                thread={thread}

                contentType={'text'}
                files={files}

                position={message.sender.id === user.id ? 'right' : 'left'}
                isEmoji={isEmoji}
                body={message.body}

                lastUserMessage={lastUserMessage}
                firstUserMessage={firstUserMessage}

                isChannelMessage={isChannelMessage}
                smallVersion={smallVersion}
                onLoaded={handleLoaded}
            />
        </>
    )
}

ChatMessageDistributer.propTypes = {
    message: PropTypes.object.isRequired,
    thread: PropTypes.object.isRequired,
    handleLoaded: PropTypes.func.isRequired,
    lastUserMessage: PropTypes.bool.isRequired,
    firstUserMessage: PropTypes.bool.isRequired,
    isChannelMessage: PropTypes.bool.isRequired,
    smallVersion: PropTypes.bool.isRequired
}

export default ChatMessageDistributer;