import React from 'react';
import {ThemeProvider, StyledEngineProvider} from '@mui/system';
import Auth from 'src/omnia/components/general/auth/Auth';
import OmniaSocket from 'src/omnia/components/general/wireless';
import ScrollReset from 'src/omnia/components/elements/scroll-reset';
import {LocalizationProvider} from '@mui/x-date-pickers-pro';
import {AdapterDateFns} from '@mui/x-date-pickers-pro/AdapterDateFns';
import {createTheme} from 'src/omnia/theme';
import ChatBar from "./components/modules/core/connect/chat-bar";
import {Toaster} from "react-hot-toast";
import Sockets from "src/sockets";
import {SettingsProvider, SettingsConsumer} from "./context/settings-context";
import {Provider} from 'react-redux';
import {store, persistor} from './store';
import 'react-quill/dist/quill.snow.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'simplebar-react/dist/simplebar.min.css';
import CssBaseline from "@mui/material/CssBaseline";
import {PersistGate} from 'redux-persist/integration/react';
import moment from 'moment';
import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import translationsOmniaEnglish from "src/omnia/locale/en.json";
import translationsCustomEnglish from "src/locale/en.json";
import translationsOmniaGerman from "src/omnia/locale/de.json";
import translationsCustomGerman from "src/locale/de.json";
import SecurityChecksBlocker from "./components/modules/core/security-checks-blocker";
import {AppModeConsumer, AppModeProvider} from "./context/app-mode-context";
import FontMetaProvider from "./components/general/font-meta-provider";
import {alpha} from "@mui/material/styles";
import TransitionOverlay from "./transition-overlay";
import ContentRenderer from "./content-renderer";
import numeral from 'numeral';
import 'moment/locale/de';
import 'moment/locale/en-ca';
import 'numeral/locales/en-gb';
import 'numeral/locales/de';
import Search from "./components/general/search";

function App() {

    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <AppModeProvider>
                    <AppModeConsumer>
                        {(appMode) => (
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Auth>
                                    <SettingsProvider>
                                        <SettingsConsumer>
                                            {(settings) => {

                                                let language = 'de';
                                                let theme = null;

                                                if(appMode.isBackend) {
                                                    language = settings.language;
                                                    theme = {
                                                        ...appMode.backendTheme,
                                                        ...{
                                                            contrast: settings.contrast,
                                                            paletteMode: settings.paletteMode,
                                                        }
                                                    }
                                                }
                                                if(appMode.isService){
                                                    theme = {
                                                        ...appMode.serviceTheme,
                                                        ...{
                                                            contrast: "normal",
                                                            direction: "ltr",
                                                        }
                                                    }
                                                }
                                                if(appMode.isWebsite){
                                                    theme = {
                                                        ...appMode.websiteTheme,
                                                        ...{
                                                            contrast: "normal",
                                                            direction: "ltr",
                                                            paletteMode: settings.paletteMode,  // TODO: this is wrong
                                                        }
                                                    }
                                                }

                                                // Setup global system components
                                                moment.locale(language);
                                                numeral.locale(language);

                                                i18n.use(initReactI18next).init({
                                                    resources: {
                                                        en: {
                                                            translation: {
                                                                ...translationsOmniaEnglish,
                                                                ...translationsCustomEnglish
                                                            }
                                                        },
                                                        de: {
                                                            translation: {
                                                                ...translationsOmniaGerman,
                                                                ...translationsCustomGerman
                                                            }
                                                        }
                                                    },
                                                    lng: language,
                                                    fallbackLng: "en",
                                                    interpolation: {escapeValue: false}
                                                });
                                                const muiTheme = createTheme(theme);

                                                return (
                                                    <StyledEngineProvider injectFirst>
                                                        <ThemeProvider theme={muiTheme}>
                                                            <TransitionOverlay />
                                                            <FontMetaProvider>
                                                                <CssBaseline/>
                                                                <ScrollReset/>

                                                                <Toaster
                                                                    position="top-center"
                                                                    toastOptions={{
                                                                        style: {
                                                                            backdropFilter: 'blur(6px)',
                                                                            background: alpha(muiTheme.palette.background.paper, 0.8),
                                                                            color: muiTheme.palette.common.white,
                                                                            boxShadow: muiTheme.shadows[16],
                                                                            zIndex: muiTheme.zIndex.appBar + 1
                                                                        }
                                                                    }}
                                                                    autoClose={2000}
                                                                    newestOnTop={true}
                                                                />
                                                                <SecurityChecksBlocker>

                                                                    <ContentRenderer />

                                                                    {appMode?.isBackend && (
                                                                        <>
                                                                            <ChatBar/>
                                                                            <OmniaSocket/>
                                                                            <Search/>
                                                                            <Sockets/>
                                                                        </>
                                                                    )}

                                                                </SecurityChecksBlocker>
                                                            </FontMetaProvider>
                                                        </ThemeProvider>
                                                    </StyledEngineProvider>
                                                )
                                            }}
                                        </SettingsConsumer>
                                    </SettingsProvider>
                                </Auth>
                            </LocalizationProvider>
                        )}
                    </AppModeConsumer>
                </AppModeProvider>
            </PersistGate>
        </Provider>
    );
}

export default App;
