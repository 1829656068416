export const SET_UNREAD_NOTIFICATIONS = '@service/set-unread-notifications';
export const SET_USER = '@service/set-user';
export const SET_LOADING_USER = '@service/set-loading-user';

export function setUnreadServiceNotifications(unread) {
    return (dispatch) => dispatch({
        type: SET_UNREAD_NOTIFICATIONS,
        payload: unread
    })
}

export function setUser(user) {
    return (dispatch) => dispatch({
        type: SET_USER,
        payload: user
    })
}

export function setLoadingUser(loading) {
    return (dispatch) => dispatch({
        type: SET_LOADING_USER,
        payload: loading
    })
}