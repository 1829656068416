import {useTheme} from "@mui/system";
import {useIsMobile} from "src/omnia/hooks/use-is-mobile";
import React, {useEffect, useState} from "react";
import {Avatar, Box, Card, CardMedia, Fade, IconButton, Link, Stack, SvgIcon, Tooltip, Typography} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import Linkify from "react-linkify";
import PerfectScrollbar from "react-perfect-scrollbar";
import PostFileItem from "src/omnia/components/modules/core/connect/post-card/post-file-item";
import moment from "moment/moment";
import {Lightbox} from "react-modal-image";
import { imageTypes, videoTypes, docTypes } from 'src/omnia/utils/file-type-helpers';
import PropTypes from "prop-types";
import HtmlWrapper from "src/omnia/components/elements/html-wrapper";
import {APP_SETTING} from "../../../../../../setup";
import {useDialog} from "../../../../../hooks/use-dialog";
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ActionDetailsDialog from "../../../intelligence/assistants/action-details-dialog";
import {useTranslation} from "react-i18next";
import OnIcon from "../../../../elements/icon";
import {useSelector} from "react-redux";
import BookmarkIcon from '@mui/icons-material/Bookmark';
import useOmniaApi from "../../../../../hooks/use-omnia-api";

export const MessageRenderer = (props) => {

    const {
        message,
        assistant,

        contentType,
        position,
        body,
        isEmoji,
        files,
        onFileUpdate,
        smallVersion,

        hideReactions,
        lastUserMessage,
        firstUserMessage,
        isShortMessage,
        ...other
    } = props;

    const theme = useTheme();
    const { isMobile } = useIsMobile();
    const { t } = useTranslation();
    const user = useSelector(state => state.user);
    const [openedFile, setOpenedFile] = useState(null);
    const { put } = useOmniaApi();
    const [hovered, setHovered] = useState(false);
    const actionDialog = useDialog();

    const longAgo = moment().diff(moment(message.sent_at), 'days') > 1;

    const senderLogo = (
        <>
            {message?.role === 'user'
                ? (
                    <Avatar
                        src={user?.avatar || null}
                        sx={{
                            width: 22,
                            height: 22,
                        }}
                    />
                )
                : message?.sender_logo ? (
                    <Box
                        component="img"
                        src={message?.sender_logo + (theme.palette.mode === 'dark' ? '-light.png' : '-dark.png')}
                        alt="Assistant Logo"
                        sx={{
                            width: 20,
                            height: 20,
                        }}
                    />
                ) : message?.sender_icon ? (
                    <OnIcon iconName={message?.sender_icon} size="small" />
                ) : assistant?.icon ? (
                    <OnIcon iconName={assistant?.icon} size="small" />
                ) : (
                    <Box
                        component="img"
                        src={assistant?.llm?.logo ? (assistant?.llm?.logo + (theme.palette.mode === 'dark' ? '-light.png' : '-dark.png')) : null}
                        alt="Assistant Logo"
                        sx={{
                            width: 20,
                            height: 20,
                        }}
                    />
                )}
        </>
    );

    const isChatBubble = (smallVersion || contentType === 'image') || isShortMessage;

    const handleRefresh = () => {
        alert(t('common.coming_soon'))
    }

    const handleLike = () => {
        put('core/assistant-chat-messages', {id: message.id, is_liked: !message.is_liked}).then(response => {
            console.log(response);
        })
    }

    const handleDislike = () => {
        put('core/assistant-chat-messages', {id: message.id, is_disliked: !message.is_disliked}).then(response => {
            console.log(response);
        })
    }

    const handleCopy = () => {
        alert(t('common.coming_soon'));
    }

    const handleSave = () => {
        put('core/assistant-chat-messages/' + message?.id + '/bookmark').then(response => {
            console.log(response);
        })
    }

    const openActionDetails = (actions) => {
        actionDialog.handleOpen(actions);
    }

    const chatBubble = (
        <>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: position === 'right' ? 'flex-end' : 'flex-start',
                    maxWidth: '100%'
                }}
                id="ChatBubble"
                {...other}
            >
                <Stack
                    alignItems="flex-start"
                    direction={position === 'right' ? 'row-reverse' : 'row'}
                    spacing={2}
                    sx={{
                        // maxWidth: 500,
                        maxWidth: 'min(100%, 500px)',
                        ml: position === 'right' ? 'auto' : 0,
                        mr: position === 'left' ? 'auto' : 0
                    }}
                >

                    <Box sx={{display: 'flex', flexGrow: 1, flexDirection: 'column', width: '100%'}}>
                        {isEmoji ? (
                            <>
                                <Box>
                                    {(firstUserMessage && (position === "left")) && (
                                        <Box sx={{
                                            px: 1.75,
                                            py: smallVersion ? 0 : 0.75,
                                            pt: 0.75,
                                        }}>
                                            <Link
                                                underline={message?.assistant_id ? "hover" : "none"}
                                                color="inherit"
                                                component={RouterLink}
                                                to={'/groon/ai/assistants/' + message?.assistant_id}
                                                sx={{
                                                    cursor: 'pointer',
                                                    fontWeight: 600,
                                                    fontSize: smallVersion ? 12 : 13,
                                                }}
                                            >
                                                {message?.sender_name || assistant?.name}
                                            </Link>
                                        </Box>
                                    )}
                                </Box>
                                <Box
                                    style={{
                                        fontSize: smallVersion ? 40 : 50,
                                        textAlign: position
                                    }}
                                >
                                    {body}
                                </Box>
                            </>
                        ) : (
                            <Card
                                // onHoverChange={setHovered}
                                sx={{
                                    backgroundColor: position === 'right' ? 'primary.main' : 'background.paper',
                                    color: position === 'right' ? 'primary.contrastText' : 'text.primary',
                                    borderRadius: theme?.config?.message_radius + 'px',
                                    maxWidth: '100%'
                                }}
                            >
                                {contentType === 'image' && (
                                    <CardMedia
                                        component="img"
                                        onClick={() => setOpenedFile(body)}
                                        image={body}
                                        height="auto"
                                        maxWidth="100%"
                                        sx={{ maxWidth: '100%' }}
                                    />
                                )}
                                {contentType === 'text' && (
                                    <>
                                        <Typography
                                            sx={{
                                                px: 1.5,
                                                py: 0.75
                                            }}
                                            color="inherit"
                                            variant={(smallVersion || isMobile) ? "body2" : "body1"}
                                        >
                                            <Linkify properties={{target: '_blank', style: {color: position === "right" ? theme.palette.primary.contrastText : theme.palette.primary.main}}}>
                                                <HtmlWrapper
                                                    html={body}
                                                    styles={{
                                                        fontSize: (smallVersion || isMobile) ? theme.config?.font_body2_size : theme.config?.font_subtitle1_size
                                                    }}
                                                />
                                            </Linkify>
                                        </Typography>
                                        {files && (files.length > 0) && (
                                            <Box mt={2}>
                                                <PerfectScrollbar>
                                                    <Box style={{maxHeight: 500}}>
                                                        {files.map((file, i) => {
                                                            return (
                                                                <PostFileItem
                                                                    key={message.id + '-file-' + i}
                                                                    simpleIcon={true}
                                                                    file={file}
                                                                    showDivider={i < files.length - 1}
                                                                    deleteCallback={onFileUpdate}
                                                                    onUpdate={onFileUpdate}
                                                                    sx={{color: (position === "right" ? theme.palette.primary.contrastText : theme.palette.text.primary)}}
                                                                />
                                                            )
                                                        })}
                                                    </Box>
                                                </PerfectScrollbar>
                                            </Box>
                                        )}
                                    </>
                                )}

                            </Card>
                        )}
                    </Box>
                </Stack>
            </Box>
            {(lastUserMessage && longAgo) && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: position === 'right' ? 'flex-end' : 'flex-start',
                    }}
                    pl={position === 'right' ? 0 : 1}
                    pr={position === 'left' ? 0 : 1}
                >
                    <Typography
                        color="text.secondary"
                        noWrap
                        variant="caption"
                    >
                        {moment(message.sent_at).fromNow()}
                    </Typography>
                </Box>
            )}

        </>
    )

    const largeMessage = (
        <Stack direction="column" spacing={1} sx={{maxWidth: '100%'}} id="LargeMessage">
            <Typography
                color="inherit"
                variant="subtitle1"
                // sx={{lineHeight: 1.5}}
            >
                <Linkify properties={{target: '_blank', style: {color: isChatBubble ? theme.palette.primary.contrastText : theme.palette.primary.main}}}>
                    <HtmlWrapper html={body} />
                </Linkify>
            </Typography>
            {files && (files.length > 0) && (
                <Box>
                    <PerfectScrollbar>
                        <Box style={{maxHeight: 500}}>
                            {files.map((file, i) => {
                                return (
                                    <PostFileItem
                                        key={message.id + '-file-' + i}
                                        simpleIcon={true}
                                        file={file}
                                        showDivider={i < files.length - 1}
                                        deleteCallback={onFileUpdate}
                                        onUpdate={onFileUpdate}
                                        sx={{color: (position === "right" ? theme.palette.primary.contrastText : theme.palette.text.primary)}}
                                    />
                                )
                            })}
                        </Box>
                    </PerfectScrollbar>
                </Box>
            )}
            {(lastUserMessage && longAgo) && (
                <Box
                    pl={0}
                    pr={0}
                >
                    <Typography
                        color="text.secondary"
                        noWrap
                        variant="caption"
                    >
                        {moment(message.sent_at).fromNow()}
                    </Typography>
                </Box>
            )}
        </Stack>
    )

    if(!body)
        return null;

    return (
        <Stack
            id="MessageRendererComponent"
            direction={(message?.role === 'user' && isChatBubble) ? "row-reverse" : "row"}
            sx={{
                width: '100%',
                ml: message?.role === 'user' ? (isChatBubble ? 0 : '-35px') : senderLogo ? '-33px' : '0px',
                mt: firstUserMessage ? (smallVersion ? 2 : 4) : (smallVersion ? 0.1 : 1),
            }}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            spacing={2}
        >
            {message?.role !== 'system' && (
                <>
                    {(message?.role === 'user') ? (
                        <>
                            {!isChatBubble && senderLogo}
                        </>
                    ) : (
                        <>
                            {senderLogo && (
                                <Tooltip enterDelay={1000} title={message?.sender_name || null} placement='left'>
                                    {senderLogo}
                                </Tooltip>
                            )}
                        </>
                    )}
                </>
            )}
            <Box sx={{width: '100%'}}>
                {message?.role === 'system' ? (
                    <Box sx={{maxWidth: 500, marginLeft: '40px'}}>
                        <Typography color="textSecondary" variant="overline">
                            {body}
                        </Typography>
                    </Box>
                ) : isChatBubble ? chatBubble : largeMessage}
                {(message?.procedures?.length > 0) && (
                    <Link
                        color="textSecondary"
                        variant="overline"
                        href='#'
                        underline='hover'
                        onClick={() => openActionDetails(message?.procedures)}
                    >
                        Handlung wurde beendet
                    </Link>
                )}
                {openedFile && (
                    <div style={{zIndex: 1600}}>
                        <Lightbox
                            large={openedFile}
                            medium={openedFile}  // FIXME: we should use .view_medium here
                            onClose={() => setOpenedFile(null)}
                        />
                    </div>
                )}
                <ActionDetailsDialog
                    open={actionDialog.open}
                    onClose={actionDialog.handleClose}
                    actions={actionDialog.data}
                />
                {((message?.role !== 'system') && !hideReactions) && (
                    <Fade in={hovered}>
                        <Stack
                            direction={(message?.role === 'user' && isChatBubble) ? "row-reverse" : "row"}
                            sx={{width: '100%', mt: 1}}
                        >
                            <Stack
                                direction="row"
                                spacing={0}
                            >
                                {(!message?.is_disliked && (message?.role !== 'user')) && (
                                    <Tooltip enterDelay={1000} title={t('intelligence.assistants.good_reaction')}>
                                        <IconButton color="text.secondary" onClick={handleLike}>
                                            {message?.is_liked ? (
                                                <SvgIcon component={ThumbUpIcon} sx={{fontSize: 'h6.fontSize'}} />
                                            ) : (
                                                <SvgIcon component={ThumbUpAltOutlinedIcon} sx={{fontSize: 'h6.fontSize'}} />
                                            )}
                                        </IconButton>
                                    </Tooltip>
                                )}
                                {(!message?.is_liked && (message?.role !== 'user')) && (
                                    <Tooltip enterDelay={1000} title={t('intelligence.assistants.bad_reaction')}>
                                        <IconButton color="text.secondary" onClick={handleDislike}>
                                            {message?.is_disliked ? (
                                                <SvgIcon component={ThumbDownIcon} sx={{fontSize: 'h6.fontSize'}} />
                                            ) : (
                                                <SvgIcon component={ThumbDownOutlinedIcon} sx={{fontSize: 'h6.fontSize'}} />
                                            )}
                                        </IconButton>
                                    </Tooltip>
                                )}
                                {(message?.role !== 'user') && (
                                    <Tooltip enterDelay={1000} title={t('intelligence.assistants.change_model')}>
                                        <IconButton color="text.secondary" onClick={handleRefresh}>
                                            <OnIcon iconName="RefreshCcw05" size="tiny" />
                                        </IconButton>
                                    </Tooltip>
                                )}
                                <Tooltip enterDelay={1000} title={t('common.copy')}>
                                    <IconButton color="text.secondary" onClick={handleCopy}>
                                        <OnIcon iconName="Copy01" size="tiny" />
                                    </IconButton>
                                </Tooltip>
                                {message?.role === 'user' && (
                                    <Tooltip enterDelay={1000} title={t('intelligence.assistants.save_to_prompt_library')}>
                                        <IconButton color="text.secondary" onClick={handleSave}>
                                            {message?.is_saved ? (
                                                <SvgIcon component={BookmarkIcon} sx={{fontSize: 'h6.fontSize'}} />
                                            ) : (
                                                <SvgIcon component={BookmarkBorderIcon} sx={{fontSize: 'h6.fontSize'}} />
                                            )}
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </Stack>
                        </Stack>
                    </Fade>
                )}
            </Box>
        </Stack>
    )

};

const Message = ({message, smallVersion, index, initial, hideReactions, assistant, lastUserMessage, firstUserMessage, omitAnimation}) => {

    const [show, setShow] = useState(false);
    const [files, setFiles] = useState([]);
    const [featureImages, setFeatureImages] = useState([]);
    const [isEmoji, setIsEmoji] = useState(false);

    const updateFiles = (files) => {
        setFeatureImages([]);
        setFiles([]);
        for (let i = 0; i < files.length; i++) {
            if(imageTypes.includes(files[i].type.toLowerCase())){
                const feature = files[i].view;
                setFeatureImages(prev => prev.concat([feature]));
            } else {
                setFiles(prev => prev.concat([files[i]]));
            }
        }
    }

    useEffect(() => {
        if(initial)
            setShow(false);
        setTimeout(() => {
            setShow(true);
        }, initial ? (index * 30) : 0);
    }, [index, initial]);

    useEffect(() => {
        if (message.files.length > 0) {
            updateFiles(message.files);
        } else {
            setFiles([]);
            setFeatureImages([]);
        }
        // check if message is emoji
        setIsEmoji(/\p{Extended_Pictographic}/u.test(message.message) && !/[a-zA-Z]/.test(message.message));
    }, [message]);

    const content = (
        <Box sx={{width: '100%'}} id="MessageComponent">
            {featureImages.map((feature, index) => (
                <MessageRenderer
                    message={message}
                    key={message.id + '-' + index}

                    assistant={assistant}
                    contentType='image'
                    position={message.sender ? 'right' : 'left'}
                    isEmoji={false}
                    body={feature}
                    lastUserMessage={false}
                    firstUserMessage={false}

                    isShortMessage={false}
                    hideReactions={hideReactions}
                    smallVersion={smallVersion}
                />
            ))}
            <MessageRenderer
                message={message}
                key={message.id}

                assistant={assistant}
                contentType='text'
                files={files}
                onFileUpdate={updateFiles}
                position={message.sender === null ? 'left' : 'right'}
                isEmoji={isEmoji}
                body={message.message}

                lastUserMessage={lastUserMessage}
                firstUserMessage={firstUserMessage}

                isShortMessage={message?.message?.length < 400}
                hideReactions={hideReactions}
                smallVersion={smallVersion}
            />
        </Box>
    )

    if(omitAnimation && !initial)
        return content;

    return (
        <Fade in={show} timeout={APP_SETTING?.transitionDuration || 500}>
            {content}
        </Fade>
    )
};

Message.propTypes = {
    message: PropTypes.object.isRequired,
    smallVersion: PropTypes.bool.isRequired,
    assistant: PropTypes.string,
    lastUserMessage: PropTypes.bool,
    index: PropTypes.number,
    initial: PropTypes.bool,
    firstUserMessage: PropTypes.bool,
    hideReactions: PropTypes.bool,
    omitAnimation: PropTypes.bool
}

MessageRenderer.propTypes = {
    message: PropTypes.object.isRequired,
    contentType: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    isEmoji: PropTypes.bool.isRequired,
    files: PropTypes.array,
    onFileUpdate: PropTypes.func,
    smallVersion: PropTypes.bool,
    hideReactions: PropTypes.bool,
    lastUserMessage: PropTypes.bool,
    firstUserMessage: PropTypes.bool
}

export default Message;