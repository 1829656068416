export const ADD_SPACE = '@spaces/add-space';
export const UPDATE_SPACES = '@spaces/update-spaces';
export const UPDATE_SPACE = '@spaces/update-space';
export const UPDATE_BOARD = '@spaces/update-board';

export function updateSpaces(spaces) {
    return (dispatch) => dispatch({
        type: UPDATE_SPACES,
        payload: spaces
    });
}

export function updateSpace( space ) {
    return (dispatch) => dispatch({
        type: UPDATE_SPACE,
        payload: {space}
    });
}

export function dislikeSpace( space ) {
    return (dispatch) => dispatch({
        type: UPDATE_SPACE,
        payload: {
            space
        }
    });
}

export function likeSpace( space ) {
    return (dispatch) => dispatch({
        type: UPDATE_SPACE,
        payload: {space}
    });
}

export function reloadSpace( space ) {
    return (dispatch) => dispatch({
        type: UPDATE_SPACE,
        payload: {space}
    });
}

export function addSpace( space ) {
    return (dispatch) => dispatch({
        type: ADD_SPACE,
        payload: space
    });
}

export function updateBoard( space, board ) {
    return (dispatch) => dispatch({
        type: UPDATE_BOARD,
        payload: {space, board}
    });
}
