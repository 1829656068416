import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import accountReducer from 'src/omnia/store/reducers/account-reducer';
import rightsReducer from "src/omnia/store/reducers/rights-reducer";
import userGroupsReducer from "src/omnia/store/reducers/user-groups-reducer";
import usersReducer from "src/omnia/store/reducers/users-reducer";
import messagesReducer from "src/omnia/store/reducers/messages-reducer";
import clientReducer from "src/omnia/store/reducers/client-reducer";
import adminReducer from "src/omnia/store/reducers/admin-reducer";
import tasksReducer from "src/omnia/store/reducers/tasks-reducer";
import kanbanReducer from "src/omnia/store/reducers/kanban-reducer";
import spacesReducer from "src/omnia/store/reducers/spaces-reducer";
import cmsSystemReducer from "src/omnia/store/reducers/cms-system-reducer";
import cmsFrontendReducer from "src/omnia/store/reducers/cms-frontend-reducer";
import datatableReducer from "src/omnia/store/reducers/datatable-reducer";
import transactionsReducer from "src/omnia/store/reducers/transactions-reducer";
import siteDesignerReducer from "../store/reducers/site-designer-reducer";
import serviceReducer from "../store/reducers/service-reducer";
import invoicesReducer from "../store/reducers/invoices-reducer";
import { LOGOUT } from "src/omnia/store/actions/account-actions";

const cmsFrontendPersistConfig = {
    key: 'cmsFrontend',
    storage: storage,
    blacklist: ['sites']
};

export function buildRootReducer(additionalReducers) {

    const appReducer = combineReducers({
        account: accountReducer,
        rights: rightsReducer,
        groups: userGroupsReducer,
        tasks: tasksReducer,
        invoices: invoicesReducer,
        admin: adminReducer,
        messages: messagesReducer,
        datatable: datatableReducer,
        service: serviceReducer,
        kanban: kanbanReducer,
        transactions: transactionsReducer,
        cms: cmsSystemReducer,
        siteDesigner: siteDesignerReducer,
        cmsFrontend: persistReducer(cmsFrontendPersistConfig, cmsFrontendReducer),
        client: clientReducer,
        spaces: spacesReducer,
        users: usersReducer,
        ...additionalReducers // add the additional reducers here
    });

    return (state, action) => {
        if (action.type === LOGOUT) {
            state = undefined;
        }

        return appReducer(state, action);
    };
}
