import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import { MobileNav } from '../mobile-nav';
import { SideNav } from './side-nav';
import { useMobileNav } from '../../../../hooks/use-mobile-nav';
import ErrorHandler from '../../../elements/error-handler';
import { FlyingNav } from '../flying-nav';
import { useIsMobile } from '../../../../hooks/use-is-mobile';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ChatAssistantViewer from '../../../modules/core/connect/chat/chat-assistant-viewer';
import React, { useState } from 'react';
import { ChatMessageAdd } from '../../../modules/core/connect/chat/chat-message-add';
import {Slide, Card, Container, Box} from '@mui/material';
import useOmniaApi from '../../../../hooks/use-omnia-api';
import { useTheme } from '@mui/system';
import { useLocation } from 'react-router-dom';

const FlyingLayoutRoot = styled('div')(() => ({
    display: 'flex',
    flex: '1 1 auto',
    maxWidth: '100%',
    height: '100vh',
}));

const FlyingLayoutContainer = styled('div')({
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    width: '100%',
});

const VerticalLayoutRoot = styled('div')(({ isMobile }) => ({
    display: 'flex',
    flex: '1 1 auto',
    maxWidth: '100%',
    paddingTop: isMobile ? 46 : 75,
}));

const VerticalLayoutContainer = styled('div')(() => {
    return {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        width: '100%',
        // bottom: chatOpen ? '600px' : '0px',
        // overflowY: 'auto', // Add this line
    }
});

const AssistantChatOverlay = styled('div')(({ theme }) => ({
    position: 'fixed',
    bottom: 0,
    width: '100%',
    height: '400px',
    backdropFilter: 'blur(10px)',
    backgroundColor: alpha(theme.palette.background.paper, 0.8),
    borderTop: `1px solid ${theme.palette.divider}`,
    zIndex: theme.zIndex.modal - 1,
    display: 'flex',
    flexDirection: 'column',
}));

const ChatMessagesContainer = styled('div')({
    flex: 1,
    overflowY: 'auto',
    padding: '0px',
});

const ChatInputContainer = styled('div')(() => ({
    padding: '8px',
}));

export const FlyingLayout = (props) => {
    const { children, sections, navColor } = props;
    const theme = useTheme();
    const { isFlying, isMobile } = useIsMobile();
    const [isSending, setIsSending] = useState(false);
    const chatOpen = useSelector((state) => state.messages.assistantOpen);
    const user = useSelector((state) => state.account.user);
    const location = useLocation();
    const mobileNav = useMobileNav();
    const mobileNavActive = true;
    const { post, uploadStatus } = useOmniaApi();
    const { t } = useTranslation();

    const chatIsVisible = chatOpen && (location.pathname !== '/groon/launchpad');

    const handleSend = (values) => {
        return new Promise((resolve, reject) => {
            setIsSending(true);
            post('core/assistant-chats/' + user?.current_assistant_chat + '/new_message', values)
                .then((message) => {
                    resolve(message);
                })
                .catch((errors) => {
                    reject(errors);
                })
                .finally(() => {
                    setIsSending(false);
                });
        });
    };

    const reducedSections = sections.flatMap(obj => {
        if (!obj.isModules) {
            // If the object has isHome attribute, keep it as is
            return [obj];
        } else {
            // Otherwise, flatten its items
            return [{'path': null, 'divider': true}].concat(obj.items);
        }
    });

    const sideNavSections = sections;

    if (isFlying) {
        return (
            <>
                <FlyingNav
                    color={navColor}
                    sections={reducedSections}
                    onMobileNavOpen={mobileNav.handleOpen}
                    mobileNavActive={mobileNavActive}
                />
                <FlyingLayoutRoot>
                    <FlyingLayoutContainer>

                        <Box sx={{
                            pb: chatIsVisible ? '400px' : '0px',
                            display: 'flex',
                            flex: '1 1 auto',
                            flexDirection: 'column',
                            width: '100%',
                        }}>
                            <ErrorHandler>{children}</ErrorHandler>
                        </Box>

                        <Slide direction="up" in={chatIsVisible} mountOnEnter unmountOnExit>
                            <AssistantChatOverlay>
                                <ChatMessagesContainer>
                                    <Container maxWidth="xl">
                                        <ChatAssistantViewer
                                            smallVersion={false}
                                            pb={0}
                                        />
                                    </Container>
                                </ChatMessagesContainer>
                                <ChatInputContainer>
                                    <Container maxWidth="xl">
                                        <Card sx={{ p: 0.4 }}>
                                            <ChatMessageAdd
                                                disabled={isSending}
                                                uploadStatus={uploadStatus}
                                                noPadding
                                                sending={isSending}
                                                sx={{ borderRadius: `${parseInt(theme?.config?.card_radius) - 2}px` }}
                                                size="small"
                                                onSend={handleSend}
                                                placeholder={t('core.write_prompt')}
                                                smallVersion={true}
                                            />
                                        </Card>
                                    </Container>
                                </ChatInputContainer>
                            </AssistantChatOverlay>
                        </Slide>
                    </FlyingLayoutContainer>
                </FlyingLayoutRoot>
            </>
        );
    } else {
        return (
            <>
                <FlyingNav
                    color={navColor}
                    sections={reducedSections}
                    onMobileNavOpen={mobileNav.handleOpen}
                    mobileNavActive={mobileNavActive}
                />
                {!mobileNavActive && <SideNav color={navColor} sections={sideNavSections} />}
                {mobileNavActive && (
                    <MobileNav
                        color={navColor}
                        onClose={mobileNav.handleClose}
                        open={mobileNav.open}
                        sections={sideNavSections}
                    />
                )}
                <VerticalLayoutRoot isMobile={isMobile}>
                    <VerticalLayoutContainer chatOpen={chatIsVisible}>
                        <Box sx={{
                            pb: chatIsVisible ? '400px' : '0px',
                            display: 'flex',
                            flex: '1 1 auto',
                            flexDirection: 'column',
                            width: '100%',
                        }}>
                            <ErrorHandler>{children}</ErrorHandler>
                        </Box>
                        <Slide direction="up" in={chatIsVisible} mountOnEnter unmountOnExit>
                            <AssistantChatOverlay>
                                <ChatMessagesContainer>
                                    <Container maxWidth="xl">
                                        <ChatAssistantViewer smallVersion={false} />
                                    </Container>
                                </ChatMessagesContainer>
                                <ChatInputContainer>
                                    <Container maxWidth="xl">
                                        <Card sx={{ p: 0.4 }}>
                                            <ChatMessageAdd
                                                disabled={isSending}
                                                uploadStatus={uploadStatus}
                                                noPadding
                                                sending={isSending}
                                                sx={{ borderRadius: `${parseInt(theme?.config?.card_radius) - 2}px` }}
                                                size="small"
                                                onSend={handleSend}
                                                placeholder={t('core.write_prompt')}
                                                smallVersion={true}
                                            />
                                        </Card>
                                    </Container>
                                </ChatInputContainer>
                            </AssistantChatOverlay>
                        </Slide>
                    </VerticalLayoutContainer>
                </VerticalLayoutRoot>
            </>
        );
    }
};

FlyingLayout.propTypes = {
    children: PropTypes.node,
    navColor: PropTypes.oneOf(['blend-in', 'discreet', 'evident']),
    navMode: PropTypes.oneOf(['normal', 'hidden']),
    sections: PropTypes.array,
};
