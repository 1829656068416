import React from "react";
import {useTranslation} from "react-i18next";
import {LoadingButton} from "@mui/lab";

export default function SaveButton({loading, label, noIcon, ...props}){

    const { t } = useTranslation();

    return (
        <LoadingButton
            variant="contained"
            loading={loading}
            {...props}
        >
            {label ? label : t("common.save")}
        </LoadingButton>
    )
}

SaveButton.defaultProps = {
    loading: false,
    noIcon:  false,
    label: null
}