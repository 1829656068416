import PropTypes from 'prop-types';
import {Box, Stack} from '@mui/material';
import {SideNavItem} from './side-nav-item';
import HistoryPopover from "../../../modules/intelligence/history-popover";
import React from "react";
import {usePopover} from "../../../../hooks/use-popover";
import {useTheme} from "@mui/system";

const renderItems = ({depth = 0, items, pathname}) => items.reduce((acc, item) => reduceChildRoutes({
    acc,
    depth,
    item,
    pathname
}), []);

const reduceChildRoutes = ({acc, depth, item, pathname}) => {

    const checkPath = !!(item.path && pathname);
    const partialMatch = checkPath ? pathname.includes(item.path) : false;
    const exactMatch = checkPath ? pathname === item.path : false;

    if (item.items) {
        acc.push(
            <SideNavItem
                active={partialMatch}
                depth={depth}
                disabled={item.disabled}
                icon={item.icon}
                tooltip={item?.tooltip}
                key={item.title}
                items={item.items}
                label={item.label}
                open={partialMatch}
                title={item.title}
            >
                <Stack
                    component="ul"
                    id='side-nav-section-stack'
                    // spacing={0.5}
                    sx={{
                        listStyle: 'none',
                        m: 0,
                        p: 0
                    }}
                >
                    {renderItems({
                        depth: depth + 1,
                        items: item.items,
                        pathname
                    })}
                </Stack>
            </SideNavItem>
        );
    } else {
        acc.push(
            <SideNavItem
                active={exactMatch}
                depth={depth}
                disabled={item.disabled}
                chip={item.chip || null}
                external={item.external}
                icon={item.icon}
                items={item?.items || []}
                tooltip={item?.tooltip}
                key={item.title}
                label={item.label}
                path={item.path}
                title={item.title}
                superCustomAction={item.superCustomAction}
            />
        );
    }

    return acc;
};

export const SideNavSection = (props) => {
    const {items = [], pathname, title = '', index, ...other} = props;
    const theme = useTheme();

    return (
        <Stack
            component="ul"
            spacing={0.25}
            sx={{
                listStyle: 'none',
                m: 0,
                p: 0
            }}
            {...other}
        >
            {(title && (index !== 0)) && (
                <Box
                    component="li"
                    sx={{
                        color: 'var(--nav-section-title-color)',
                        fontSize: theme.typography.caption.fontSize,
                        fontWeight: theme.typography.caption.fontWeight,
                        pt: 1.5,
                        pb: 0.5,
                    }}
                >
                    {title}
                </Box>
            )}
            {renderItems({items, pathname})}
        </Stack>
    );
};

SideNavSection.propTypes = {
    items: PropTypes.array,
    pathname: PropTypes.string,
    title: PropTypes.string,
    index: PropTypes.number
};
