/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
import produce from 'immer';
import _ from 'lodash';
import {
    RELOAD_TASKS,
    UPDATE_TASK,
    ADD_TASK,
    REMOVE_TASK, SET_FILTERS
} from 'src/omnia/store/actions/tasks-actions';
import objFromArray from 'src/omnia/utils/objFromArray';

const initialState = {
    isLoaded: false,
    filters: {
        search: '',
        status: null,
        type: null,
        assigned: [],
    },
    filtersActive: false,
    tasks: {
        byId: {},
        allIds: []
    },
};

const tasksReducer = (state = initialState, action) => {

    switch (action.type) {

        case SET_FILTERS:
            let { filters } = action.payload;
            return produce(state, (draft) => {
                // check if filters are not normal
                draft.filtersActive = !( (filters['search'] === '') && (filters['status'] === null) && (filters['type'] === null) && (filters['assigned'].length === 0) );
                // Ensure we clear current session
                draft.filters = filters;
            })

        case RELOAD_TASKS: {
            const { tasks } = action.payload;
            return produce(state, (draft) => {
                draft.tasks.byId = objFromArray(tasks);
                draft.tasks.allIds = Object.keys(draft.tasks.byId).map(key => parseInt(key));
                // also set is loaded true
                draft.isLoaded = true;
            });
        }

        case UPDATE_TASK: {
            const { task } = action.payload;
            return produce(state, (draft) => {
                // remove the task from draft
                draft.tasks.byId = _.omit(draft.tasks.byId, task.id);
                _.pull(draft.tasks.allIds, task.id);
                // add the task again to draft
                draft.tasks.byId[task.id] = task;
                draft.tasks.allIds.push(task.id);
            });
        }

        case ADD_TASK: {
            const { task } = action.payload;
            // check if task already exists
            if(!state.tasks.allIds.includes(task.id)){
                return produce(state, (draft) => {
                    draft.tasks.byId[task.id] = task;
                    draft.tasks.allIds.push(task.id);
                });
            } else {
                return state;
            }
        }

        case REMOVE_TASK: {
            const { taskId } = action.payload;
            return produce(state, (draft) => {
                draft.tasks.byId = _.omit(draft.tasks.byId, taskId);
                draft.tasks.allIds = _.pull(draft.tasks.allIds, taskId);
            });
        }

        default: {
            return state;
        }
    }
}

export default tasksReducer;