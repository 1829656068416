import {useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {APP_SETTING} from "../../../../setup";
import {useSelector} from "react-redux";
import {useSecurityCheck} from "../../../hooks/use-security-check";

export const useSections = () => {
    /**
     *
     * TODO: this component can be improved!! When e.g. switching between admin and rest, the menu is for a millisecond
     * empty. This is because the useSections is loaded in the Layout component and should instead be moved up!
     *
     */

    const {t} = useTranslation();
    const {hasRights, isModuleInstalled} = useSecurityCheck();
    const unread = useSelector((state) => state.messages.unread);
    const user = useSelector((state) => state.account.user);
    const cmsEnabled = useSelector(state => state.cms.enabled);
    const unreadRef = useRef();
    unreadRef.current = unread;

    const processItem = (item) => {

        if(!item)
            return null;

        // Translate the title
        if (item.title) {
            item.title = t(item.title);
        }

        // Include chips
        if (item.chip) {
            if(item.chip === 'unread') {
                item.chip = unreadRef.current;
            } else if(item.chip === 'tickets') {
                item.chip = user?.open_requests || 0;
            } else if(['Beta', 'Alpha'].includes(item.chip)) {

            } else {
                console.log('WARNING: Chip could not be processed', item)
            }

        }

        // Check if this should be shown
        if (item?.showIf){
            if(item?.showIf === 'has_tickets'){
                if(user?.open_requests === 0){
                    return null;
                }
            }
            if(item?.showIf === 'cms_enabled'){
                if(!cmsEnabled){
                    return null;
                }
            }
        }

        // If the item has nested items, process them recursively
        if (item.items) {
            item.items = item.items.map(processItem).filter(Boolean);
        }

        // Check permissions if they exist, return null to filter out if checkRights fails
        if (!(!item.permissions || hasRights(item.permissions, 'or'))) {
            return null;
        }

        // Check if module is installed
        if (!(!item.module || isModuleInstalled(item.module))) {
            return null;
        }

        return item;
    };

    const getProcessedNavigation = (menuData) => {

        const clonedMenuData = structuredClone(menuData);

        const result = clonedMenuData.map(section => {
            // Translate the section titles if present
            if (section.title) {
                section.title = t(section.title);
            }

            // Process each item within the section
            if (section.items) {
                section.items = section.items.map(processItem).filter(Boolean);
            }

            return section;
        }).filter(section => section.items && (section.items.length > 0) && (!section?.module || isModuleInstalled(section?.module)));

        return result;
    };

    return getProcessedNavigation(APP_SETTING?.navigation || []);
};

export const defaultNavigationConfig = {
    home: {
        title: 'Home',
        icon: 'Home01',
        path: '/groon',
        items: [
            {
                title: 'common.search',
                path: null,
                superCustomAction: 'search',
                icon: 'SearchMd',
            },
            {
                title: 'layout.home',
                path: '/groon/ai/chats/compose',
                icon: 'Home01',
                tooltip: 'layout.tooltips.home'
            },
            {
                title: 'layout.calendar',
                path: '/groon/calendar',
                icon: 'Calendar',
                tooltip: 'layout.tooltips.calendar'
            },
            {
                title: "layout.my_files",
                path: '/groon/files',
                icon: 'Folder',
                tooltip: 'layout.tooltips.files'
            },
            {
                title: "layout.my_tasks",
                path: '/groon/tasks',
                icon: 'CheckCircle',
                tooltip: 'layout.tooltips.tasks'
            },
            {
                title: "layout.my_tickets",
                path: '/groon/requests',
                icon: 'Bookmark',
                permissions: ["groon_tickets"],
                showIf: 'has_tickets',
                chip: 'tickets',
                tooltip: 'layout.tooltips.tickets'
            },
            {
                title: "layout.my_chats",
                path: '/groon/chat',
                icon: 'Inbox01',
                permissions: ['social_intranet'],
                module: 'intranet',
                chip: 'unread',
                tooltip: 'layout.tooltips.chats'
            },
            {
                title: "intelligence.history",
                path: null,
                superCustomAction: 'extend-intelligence-chats',
                icon: 'ClockRewind',
            },
        ]
    },
    creativeStudio: {
        title: 'layout.creative_studio.title',
        // icon: 'PaintPour',
        icon: 'CameraPlus',
        path: 'groon/creative-studio',
        permissions: ['creative_studio'],
        items: [
            {
                title: 'layout.creative_studio.projects',
                path: '/groon/creative-studio/projects',
                icon: 'Grid01',
                permissions: ["creative_studio"],
                tooltip: 'layout.tooltips.creative_studio.projects'
            },
            {
                title: 'layout.creative_studio.image_creator',
                path: '/groon/creative-studio/image-creator',
                icon: 'Image01',
                permissions: ["creative_studio"],
                tooltip: 'layout.tooltips.creative_studio.image_creator'
            },
            {
                title: 'layout.creative_studio.text_creator',
                path: '/groon/creative-studio/text-creator',
                icon: 'File05',
                permissions: ["creative_studio"],
                tooltip: 'layout.tooltips.creative_studio.text_creator'
            },
        ]
    },
    resources: {
        title: "layout.resources.title",
        path: '/groon/resources',
        icon: 'Atom02',
        permissions: ['erp'],
        module: 'resources',
        chip: 'Beta',
        items: [
            {
                title: 'common.overview',
                permissions: ['erp_financial_transactions', 'erp_warehouse_management_transactions', 'erp_allow_time_booking'],
                icon: 'BarChartSquare02',
                path: '/groon/resources',
                tooltip: 'layout.tooltips.resources.index'
            },
            {
                title: 'layout.resources.transactions',
                permissions: ['erp_financial_transactions', 'erp_warehouse_management_transactions', 'erp_allow_time_booking'],
                icon: 'SwitchHorizontal01',
                path: '/groon/resources/transactions',
                tooltip: 'layout.tooltips.resources.transactions'
            },
            {
                title: "layout.resources.products_services_short",
                permissions: ['tms_services_products'],
                icon: 'Package',
                path: '/groon/resources/products',
                tooltip: 'layout.tooltips.resources.products'
            },
            {
                title: "layout.resources.offers",
                permissions: ['tms_offers'],
                icon: 'FilePlus02',
                path: '/groon/resources/offers',
                tooltip: 'layout.tooltips.resources.offers'
            },
            {
                title: "layout.resources.orders",
                permissions: ['tms_orders'],
                icon: 'ReceiptCheck',
                path: '/groon/resources/orders',
                tooltip: 'layout.tooltips.resources.orders'
            },
            {
                title: "layout.resources.subscriptions",
                permissions: ['tms_subscriptions'],
                icon: 'RefreshCcw05',
                path: '/groon/resources/subscriptions',
                tooltip: 'layout.tooltips.resources.subscriptions'
            },
            {
                title: "layout.resources.invoices",
                permissions: ['tms_invoices'],
                icon: 'CurrencyEuroCircle',
                path: '/groon/resources/invoices',
                tooltip: 'layout.tooltips.resources.invoices'
            }
        ]
    },
    projects: {
        title: "layout.projects.title",
        path: '/groon/projects',
        icon: 'Headphones02',
        permissions: ['projects'],
        module: 'projects',
        items: [
            {
                title: "layout.projects.title",
                icon: 'Grid01',
                path: '/groon/projects/list',
                tooltip: 'layout.tooltips.projects.index'
            },
            {
                title: "layout.projects.browse",
                icon: 'SearchMd',
                path: '/groon/projects/browse',
                tooltip: 'layout.tooltips.projects.browse'
            },
        ]
    },
    connections: {
        title: "layout.connections.title",
        path: '/groon/connections',
        icon: 'Users01',
        permissions: ['connections'],
        module: 'connections',
        chip: 'Beta',
        items: [
            {
                title: "common.overview",
                icon: 'BarChartSquare02',
                path: '/groon/connections/dashboard',
                tooltip: 'layout.tooltips.connections.index'
            },
            {
                title: "layout.connections.contexts",
                icon: 'AlignHorizontalCentre01',
                permissions: ['crm_contexts'],
                path: '/groon/connections/contexts',
                tooltip: 'layout.tooltips.connections.contexts'
            },
            {
                title: "layout.connections.contacts",
                icon: 'UserCheck01',
                permissions: ['contacts'],
                path: '/groon/connections/index',
                tooltip: 'layout.tooltips.connections.contacts'
            },
            {
                title: "layout.connections.leads",
                icon: 'UserUp01',
                permissions: ['connection_leads'],
                path: '/groon/connections/leads',
                tooltip: 'layout.tooltips.connections.leads'
            },
        ]
    },
    marketing: {
        title: "layout.marketing.title",
        path: '/groon/marketing',
        icon: 'Globe04',
        permissions: ['marketing'],
        module: 'marketing',
        chip: 'Alpha',
        items: [
            {
                title: "common.overview",
                icon: 'BarChartSquare02',
                permissions: ['marketing'],
                path: '/groon/marketing',
                tooltip: 'layout.tooltips.marketing.index'
            },
            {
                title: 'layout.marketing.publishing',
                icon: 'PlayCircle',
                permissions: ["publishing"],
                path: '/groon/marketing/publishing',
                tooltip: 'layout.tooltips.marketing.publishing'
            },
            {
                title: 'layout.marketing.media_library',
                icon: 'Image05',
                path: '/groon/marketing/media-library',
                tooltip: 'layout.tooltips.marketing.media_library'
            },
            {
                title: 'layout.marketing.online_presences',
                icon: 'Signal02',
                permissions: ['publishing'],
                path: '/groon/marketing/channels',
                tooltip: 'layout.tooltips.marketing.online_presences'
            },
            {
                title: 'layout.marketing.website',
                icon: 'Browser',
                showIf: 'cms_enabled',
                permissions: ['contents_module_management'],
                path: '/groon/marketing/website',
                tooltip: 'layout.tooltips.marketing.website'
            },
            {
                title: "layout.marketing.campaigns",
                icon: 'VolumeMax',
                permissions: ['performance_marketing'],
                path: '/groon/marketing/campaigns',
                tooltip: 'layout.tooltips.marketing.campaigns'
            },
            {
                title: "layout.marketing.competitors",
                icon: 'UserX01',
                permissions: ['contents_module_management'],
                path: '/groon/marketing/competitors',
                tooltip: 'layout.tooltips.marketing.competitors'
            },
        ]
    },
    analytics: {
        title: "layout.analytics.title",
        path: '/groon/analytics',
        permissions: ['groon_analytics'],
        icon: 'BarLineChart',
        items: [
            {
                title: 'layout.analytics.dashboards',
                icon: 'PieChart01',
                path: '/groon/analytics/dashboards',
                tooltip: 'layout.tooltips.analytics.dashboards'
            },
            {
                title: 'layout.analytics.data_hub',
                icon: 'Data',
                path: '/groon/analytics/datahub',
                tooltip: 'layout.tooltips.analytics.data_hub'
            },
            {
                title: 'layout.intelligence.datasets',
                permissions: ['manage_datasets'],
                icon: 'PresentationChart01',
                path: '/groon/analytics/datasets',
                tooltip: 'layout.tooltips.analytics.datasets'
            },
            {
                title: "layout.my_links",
                path: '/groon/analytics/short-links',
                icon: 'Share01',
                tooltip: 'layout.tooltips.analytics.short_links'
            },
        ]
    },
}