import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import deepEqual from 'deep-equal';

const STORAGE_KEY = 'app.settings';

const restoreSettings = () => {
    let value = null;

    try {
        const restored = window.localStorage.getItem(STORAGE_KEY);

        if (restored) {
            value = JSON.parse(restored);
        }
    } catch (err) {
        console.error(err);
        // If stored details is not a strigified JSON this will fail,
        // that's why we catch the error
    }

    return value;
};

const deleteSettings = () => {
    try {
        window.localStorage.removeItem(STORAGE_KEY);
    } catch (err) {
        console.error(err);
    }
};

const storeSettings = (value) => {
    try {
        window.localStorage.setItem(STORAGE_KEY, JSON.stringify(value));
    } catch (err) {
        console.error(err);
    }
};

const initialSettings = {
    colorPreset: 'indigo',
    contrast: 'normal',
    direction: 'ltr',
    layout: 'vertical',
    language: 'de',
    navColor: 'discreet',
    navMode: 'normal',
    // systemPaletteMode: JSON.parse(localStorage.getItem('app.settings') || JSON.stringify({systemPaletteMode: true}))?.systemPaletteMode || true,
    // paletteMode: JSON.parse(localStorage.getItem('app.settings') || JSON.stringify({paletteMode: 'light'}))?.paletteMode || 'light',
    systemPaletteMode: true,
    paletteMode: 'light',
    responsiveFontSizes: true,
    desktopNotifications: false,
    desktopNotificationsSettingPending: true,
    stretch: false
};

const initialState = {
    ...initialSettings,
    isInitialized: false,
    openDrawer: false
};

export const SettingsContext = createContext({
    ...initialState,
    handleDrawerClose: () => { },
    handleDrawerOpen: () => { },
    handleReset: () => { },
    handleUpdate: () => { },
    isCustom: false
});

export const SettingsProvider = (props) => {
    const { children } = props;
    const [state, setState] = useState(initialState);

    useEffect(() => {
        const restored = restoreSettings();

        if (restored) {
            setState((prevState) => ({
                ...prevState,
                ...restored,
                isInitialized: true
            }));
        } else {
            setState((prevState) => ({
                ...prevState,
                isInitialized: true
            }));
        }
    }, []);

    const handleReset = useCallback(() => {
        deleteSettings();
        setState((prevState) => ({
            ...prevState,
            ...initialSettings
        }));
    }, []);

    const handleUpdate = useCallback((settings) => {
        setState((prevState) => {
            storeSettings({
                colorPreset: prevState.colorPreset,
                contrast: prevState.contrast,
                direction: prevState.direction,
                layout: prevState.layout,
                language: prevState.language,
                navColor: prevState.navColor,
                navMode: prevState.navMode,
                systemPaletteMode: prevState.systemPaletteMode,
                paletteMode: prevState.paletteMode,
                responsiveFontSizes: prevState.responsiveFontSizes,
                desktopNotifications: prevState.desktopNotifications,
                desktopNotificationsSettingPending: prevState.desktopNotificationsSettingPending,
                stretch: prevState.stretch,
                ...settings
            });

            return {
                ...prevState,
                ...settings
            };
        });
    }, []);

    const handleDrawerOpen = useCallback(() => {
        setState((prevState) => ({
            ...prevState,
            openDrawer: true
        }));
    }, []);

    const handleDrawerClose = useCallback(() => {
        setState((prevState) => ({
            ...prevState,
            openDrawer: false
        }));
    }, []);

    const isCustom = useMemo(() => {
        return !deepEqual(initialSettings, {
            colorPreset: state.colorPreset,
            contrast: state.contrast,
            direction: 'ltr',
            layout: state.layout,
            language: state.language,
            navColor: state.navColor,
            navMode: state.navMode,
            systemPaletteMode: state.systemPaletteMode,
            paletteMode: state.paletteMode,
            responsiveFontSizes: state.responsiveFontSizes,
            desktopNotifications: state.desktopNotifications,
            desktopNotificationsSettingPending: state.desktopNotificationsSettingPending,
            stretch: false
        });
    }, [state]);

    useEffect(() => {
        const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

        const handleSystemPaletteChange = () => {
            if (state.systemPaletteMode && state.isInitialized) {
                setState((prevState) => ({
                    ...prevState,
                    paletteMode: mediaQuery.matches ? 'dark' : 'light'
                }));
            }
        };

        mediaQuery.addEventListener('change', handleSystemPaletteChange);

        // Set initial system palette mode
        handleSystemPaletteChange();

        return () => {
            mediaQuery.removeEventListener('change', handleSystemPaletteChange);
        };
    }, [state.isInitialized, state.systemPaletteMode]);

    return (
        <SettingsContext.Provider
            value={{
                ...state,
                handleDrawerClose,
                handleDrawerOpen,
                handleReset,
                handleUpdate,
                isCustom
            }}
        >
            {children}
        </SettingsContext.Provider>
    );
};

SettingsProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export const SettingsConsumer = SettingsContext.Consumer;
