import React, { useState, useEffect } from 'react';
import { Box, Fade, Typography } from '@mui/material';
import { useTheme } from "@mui/system";
import PropTypes from "prop-types";
import DelayedCircularProgress from "../elements/delayed-circular-progress";

function LoadingScreen({ in: inProp, showTransition, hideTransition, inScreen, bgColor }) {
    const theme = useTheme();
    const [showLoadingText, setShowLoadingText] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowLoadingText(true);
        }, 2000);
        return () => clearTimeout(timer);
    }, []);

    function getColorMode() {
        const prefersDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
        return prefersDarkMode ? 'dark' : 'light';
    }

    const colorMode = getColorMode();

    const backgroundColor = bgColor || (colorMode === 'dark' ? '#151513' : '#ffffff');

    const enterTimeout = showTransition === 'smooth' ? 500 : 0;
    const exitTimeout = hideTransition === 'smooth' ? 500 : 0;

    const LoaderContent = (
        <Box>
            {theme?.config ? (
                <DelayedCircularProgress delay={3000} />
            ) : (
                <Typography
                    variant="overline"
                    sx={{ color: colorMode === 'light' ? '#0D0D0D' : '#FAFAED' }}
                    color="textPrimary"
                >
                    Laden
                </Typography>
            )}
        </Box>
    );

    const Loader = (
        <Fade
            in={inProp}
            timeout={{ enter: enterTimeout, exit: exitTimeout }}
            appear={showTransition === 'smooth'}
        >
            <div
                style={{
                    alignItems: 'center',
                    backgroundColor: backgroundColor,
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'fixed',
                    top: 0,
                    zIndex: 9999,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    justifyContent: 'center',
                    padding: 0,
                    margin: 0,
                }}
            >
                {showLoadingText && LoaderContent}
            </div>
        </Fade>
    );

    if (inScreen) {
        return (
            <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {LoaderContent}
            </div>
        );
    }

    return Loader;
}

LoadingScreen.propTypes = {
    in: PropTypes.bool,
    showTransition: PropTypes.oneOf(['instant', 'smooth']),
    hideTransition: PropTypes.oneOf(['instant', 'smooth']),
    inScreen: PropTypes.bool,
    bgColor: PropTypes.string,
};

LoadingScreen.defaultProps = {
    in: true,
    showTransition: 'instant',
    hideTransition: 'instant',
    inScreen: true,
    bgColor: null,
};

export default LoadingScreen;
