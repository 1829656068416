import React from "react";
import usePagination from "../../../../hooks/use-pagination";
import produce from "immer";
import PropTypes from "prop-types";
import { Autocomplete, TextField, Avatar } from '@mui/material';
import OnIcon from "../../../elements/icon";

function IntegrationSelector({ values, handleChange, multiple, name, label, integration, connector }) {
    const tokenIntegrations = usePagination({
        endpoint: 'core/token-integrations',
        page: 1,
        pageSize: 100,
    });
    const configIntegrations = usePagination({
        endpoint: 'core/config-integrations',
        page: 1,
        pageSize: 100,
    });
    const rawIntegrations = []
        .concat(tokenIntegrations?.elements || [])
        .concat(configIntegrations?.elements || []);

    let integrations = produce(rawIntegrations, (draft) => {
        return draft.sort((a, b) => a.name.localeCompare(b.name));
    });

    // Map the IDs in `values` to integration objects for display
    const valueObjects = multiple
        ? integrations.filter((item) => values.includes(item.id))
        : integrations.find((item) => item.id === values) || null;

    const finalOptions = integrations
        .filter(i => !integration || i?.connector?.integration_bases?.includes(integration))
        .filter(i => !connector || i?.connector?.name === connector);

    return (
        <Autocomplete
            multiple={multiple}
            options={finalOptions}
            name={name}
            getOptionLabel={(option) => option.name}
            value={valueObjects}
            onChange={(event, newValue) => {
                if (multiple) {
                    const newIds = newValue.map((item) => item.id);
                    handleChange({
                        target: {
                            name,
                            value: newIds,
                        },
                    });
                } else {
                    const newId = newValue ? newValue.id : null;
                    handleChange({
                        target: {
                            name,
                            value: newId,
                        },
                    });
                }
            }}
            renderOption={(props, option) => {
                const logoUI = option.connector?.logo_ui;
                const iconUI = option.connector?.icon_ui;
                return (
                    <li {...props}>
                        <Avatar src={logoUI} sx={{width: 25, height: 25}}>
                            {!logoUI && iconUI && <OnIcon iconName={iconUI} size="tiny" />}
                        </Avatar>
                        <span style={{ marginLeft: 8 }}>{option.name}</span>
                    </li>
                );
            }}
            renderInput={(params) => <TextField {...params} label={label} />}
        />
    );
}

IntegrationSelector.propTypes = {
    values: PropTypes.arrayOf(PropTypes.string).isRequired, // IDs are strings
    handleChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    multiple: PropTypes.bool,
    label: PropTypes.string,
    integration: PropTypes.object, // Define more specific shape if possible
};

IntegrationSelector.defaultProps = {
    multiple: false,
    name: 'integration',
    label: 'Integration',
};

export default IntegrationSelector;
