export const SAVE_STATE = '@datatable/save-state';
export const LOAD_SETTING = '@datatable/load-setting';
export const UPDATE_DATE_UPDATE = '@datatable/update-details-update';
export const REMOVE_ENDPOINT_FROM_LIST = '@datatable/remove-endpoint-list';
export const ADD_ENDPOINT_TO_LIST = '@datatable/add-endpoint-to-list';
export const SET_MODEL_META = '@datatable/set-model-meta';

export function setModelMeta(endpoint, meta){
    return dispatch => dispatch({
        type: SET_MODEL_META,
        payload: {
            endpoint,
            meta
        }
    })
}

export function updateDataUpdate(endpoint){
    return dispatch => dispatch({
        type: UPDATE_DATE_UPDATE,
        payload: {
            endpoint
        }
    })
}

export function loadTableSettings(settings){
    return dispatch => dispatch({
        type: LOAD_SETTING,
        payload: {
            settings
        }
    })
}

export function addEndpointToList(endpoint){
    return dispatch => dispatch({
        type: ADD_ENDPOINT_TO_LIST,
        payload: {
            endpoint
        }
    })
}

export function removeEndpointFromList(endpoint){
    return dispatch => dispatch({
        type: REMOVE_ENDPOINT_FROM_LIST,
        payload: {
            endpoint
        }
    })
}

export function saveGridState(ident, gridState){
    return dispatch => dispatch({
        type: SAVE_STATE,
        payload: {
            ident, gridState
        }
    })
}