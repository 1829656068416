import {useDispatch} from "react-redux";
import {setIsTransitioning} from "../store/actions/account-actions";

export default function usePerformTransition(callback) {

    const dispatch = useDispatch();

    return (args = {}) => {
        dispatch(setIsTransitioning(true));
        setTimeout(() => {
            callback?.(args);
            setTimeout(() => {
                dispatch(setIsTransitioning(false));
            }, 500);
        }, 500);
    };
}