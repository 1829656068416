import React from "react";
import {IconButton, Tooltip} from '@mui/material';
import {useTranslation} from "react-i18next";
import OnIcon from "../../../elements/icon";
import {useIsMobile} from "../../../../hooks/use-is-mobile";
import {useNavigate} from "react-router-dom";

export const AssistantButton = () => {
    const { isFlying } = useIsMobile();
    const { t } = useTranslation();
    const navigate = useNavigate();

    // const handleOpenAssistantChat = () => {
    //     dispatch(closeMiniThread());
    //     dispatch(updateAssistantOpen(!chatOpen));
    // }

    const handleOpenAiStudio = () => {
        navigate('/groon/ai/studio')
    }

    // return (
    //     <Tooltip enterDelay={1000} title={(chatOpen) ? t("intelligence.hide_assistant") : t("intelligence.show_assistant")} placement={isFlying ? 'left' : 'bottom'}>
    //         <IconButton disabled={isLaunchpad} onClick={handleOpenAssistantChat}>
    //             <OnIcon iconName="Star06" sx={isLaunchpad ? {color: theme.palette.text.disabled} : {}} />
    //         </IconButton>
    //     </Tooltip>
    // )

    return (
        <Tooltip enterDelay={1000} title={t("intelligence.open_ai_studio")} placement={isFlying ? 'left' : 'bottom'}>
            <IconButton onClick={handleOpenAiStudio}>
                <OnIcon iconName="Star06" />
            </IconButton>
        </Tooltip>
    )

};
