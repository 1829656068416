import React, {useRef, useState} from "react";
import PropTypes from 'prop-types';
import Mail04Icon from '@untitled-ui/icons-react/build/esm/Mail04';
import TrashIcon from '@untitled-ui/icons-react/build/esm/Trash01';
import {
    Avatar,
    Box,
    IconButton,
    List,
    Chip,
    ListItem,
    ListItemText,
    Menu,
    MenuItem,
    Stack,
    SvgIcon,
    Tooltip,
    Typography,
} from '@mui/material';
import useApi from "../../../../hooks/use-api";
import {useTheme} from "@mui/system";
import moment from "moment/moment";
import {MoreHoriz as MoreVertIcon} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import OnIcon from "../../../elements/icon";
import SaveButton from "../../../elements/save-button";
import {useIsMobile} from "../../../../hooks/use-is-mobile";
import SmartPopper from "../../../elements/smart-popper";
import DelayedCircularProgress from "../../../elements/delayed-circular-progress";

function SingleNotification({notification, onClickCallback, index, onMarkAsRead, onMarkAsUnread, onRemove}) {

    const [open, setOpen] = useState(false);
    const [hover, setHover] = useState(false);
    const {post, del} = useApi();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const theme = useTheme();
    const optionsRef = useRef(null);

    const handleOpenMenu = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setOpen(true);
    }

    const handleCloseMenu = () => {
        setOpen(false);
    }

    const handleMarkRead = (event) => {
        if(event){
            event.stopPropagation();
            event.preventDefault();
        }
        // mark all as read
        onMarkAsRead(notification);
        // close menu
        handleCloseMenu();
        post('core/notifications/' + notification.id + '/mark_as_read')
            .catch(() => {
                // mark all as read
                onMarkAsUnread(notification);
                // close menu
                handleCloseMenu();
            })
    }

    const handleMarkUnread = (event) => {
        if(event){
            event.stopPropagation();
            event.preventDefault();
        }
        // close menu
        handleCloseMenu();
        // mark all as read
        onMarkAsUnread(notification);
        post('core/notifications/' + notification.id + '/mark_as_unread')
            .catch(() => {
                // close menu
                handleCloseMenu();
                // mark all as read
                onMarkAsRead(notification);
            })
    }

    const handleClick = (event) => {
        if(open)
            return;
        event.preventDefault();
        event.stopPropagation();
        if (onClickCallback)
            onClickCallback();
        handleMarkRead(null);
        navigate(notification.link);
    }

    const handleRemoveNotification = (event) => {
        event.stopPropagation();
        event.preventDefault();
        del('core/notifications', notification.id)
            .then(() => {
                // close menu
                handleCloseMenu();
                // mark all as read
                onRemove(notification);
            })
    }

    return (
        <ListItem
            key={index}
            sx={{
                borderRadius: '10px',
                cursor: notification?.link ? 'pointer' : 'default',
                px: 1,
                py: 1,
                '&:hover': {
                    backgroundColor: 'action.hover'
                },
            }}
            onClick={handleClick}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            <Stack
                direction="row"
                alignItems="center"
                spacing={1.5}
            >
                <Avatar
                    style={{
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                        height: 35,
                        width: 35
                    }}
                >
                    <SvgIcon fontSize="small">
                        <OnIcon iconName={notification.icon || 'Bell01'} />
                    </SvgIcon>
                </Avatar>
                <ListItemText
                    sx={{mt: 0}}
                    primary={(
                        <Stack direction="column" spacing={0} alignItems="flex-start">
                            <Typography variant="caption" color="textSecondary" noWrap>
                                {moment(notification.created_at).fromNow()}
                            </Typography>
                            <Typography variant="subtitle1" color="textPrimary" noWrap>
                                {notification.title}
                            </Typography>
                        </Stack>
                    )}
                    secondary={(
                        <Typography variant="body2" sx={{mt: 0.5, lineHeight: 1.2}} color="textSecondary">
                            {notification.message}
                        </Typography>
                    )}
                />
                {notification.read || hover ? null :
                    <Chip
                        color="primary"
                        size="small"
                        style={{position: 'absolute', height: 10, width: 10, right: 15, top: '50%', transform: 'translateY(-50%)'}}
                    />
                }
            </Stack>
            {hover && (
                <div style={{position: 'absolute', right: 5, top: '50%', transform: 'translateY(-50%)'}}>
                    <IconButton
                        aria-controls={"notification-menu-" + notification.id}
                        ref={optionsRef}
                        aria-haspopup="true"
                        onClick={(event) => handleOpenMenu(event)} // pass the event object
                        size="small"
                    >
                        <MoreVertIcon/>
                    </IconButton>
                    <Menu
                        id={"notification-menu-" + notification.id}
                        keepMounted
                        anchorEl={optionsRef.current}
                        open={open}
                        onClose={handleCloseMenu}
                    >
                        {(notification.read) ? (
                            <MenuItem onClick={handleMarkUnread}>
                                {t("common.mark_unread")}
                            </MenuItem>
                        ) : (
                            <MenuItem onClick={handleMarkRead}>
                                {t("common.mark_read")}
                            </MenuItem>
                        )}
                        <MenuItem onClick={handleRemoveNotification}>
                            <Typography color="error">
                                {t("common.remove_notification")}
                            </Typography>
                        </MenuItem>
                    </Menu>
                </div>
            )}
        </ListItem>
    );
}

export const NotificationsPopover = (props) => {
    const {
        popover,
        moreExists,
        notifications,
        loading,
        initialLoading,
        onMarkAllAsRead,
        onRemoveAll,
        onMarkAsRead,
        onMarkAsUnread,
        onRemove,
        onLoadMore,
        ...other
    } = props;

    const { t } = useTranslation();
    const { isFlying } = useIsMobile();

    return (
        <SmartPopper
            popover={popover}
            placement={isFlying ? 'left-end' : 'bottom-end'}
            width={330}
            {...other}
        >
            <Stack
                alignItems="center"
                direction="row"
                justifyContent="space-between"
                spacing={2}
                p={2}
            >
                <Typography
                    color="inherit"
                    variant="h6"
                >
                    {t("common.notifications")}
                </Typography>
                <Box>
                    <Tooltip enterDelay={1000} title={t("common.mark_all_read")}>
                        <IconButton
                            onClick={onMarkAllAsRead}
                            size="small"
                            color="inherit"
                        >
                            <SvgIcon>
                                <Mail04Icon/>
                            </SvgIcon>
                        </IconButton>
                    </Tooltip>
                    <Tooltip enterDelay={1000} title={t("common.remove_all")}>
                        <IconButton
                            onClick={onRemoveAll}
                            size="small"
                            color="inherit"
                        >
                            <SvgIcon>
                                <TrashIcon/>
                            </SvgIcon>
                        </IconButton>
                    </Tooltip>
                </Box>
            </Stack>
            {initialLoading ? (
                <div style={{
                    height: '400px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <DelayedCircularProgress size={20} />
                </div>
            ) : (
                <List sx={{overflow: 'auto', maxHeight: 390, p: 1}}>
                    {notifications.map((notification, i) => (
                        <SingleNotification
                            notification={notification}
                            key={"single_notif_" + i}
                            onMarkAsRead={onMarkAsRead}
                            onMarkAsUnread={onMarkAsUnread}
                            onRemove={onRemove}
                            onClickCallback={popover.handleClose}
                            index={i}
                        />
                    ))}
                    {moreExists && (
                        <ListItem
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                mt: 1,
                                p: 0
                            }}
                        >
                            <SaveButton
                                color="primary"
                                onClick={onLoadMore}
                                loading={loading}
                                size="small"
                                fullWidth={true}
                                variant="text"
                                label={t("common.load_more")}
                                noIcon
                            />
                        </ListItem>
                    )}
                </List>
            )}
        </SmartPopper>
    )

};

SingleNotification.propTypes = {
    notification: PropTypes.object,
    onClickCallback: PropTypes.func,
    index: PropTypes.number,
    onMarkAsRead: PropTypes.func,
    onMarkAsUnread: PropTypes.func,
    onRemove: PropTypes.func
}

NotificationsPopover.propTypes = {
    popover: PropTypes.object.isRequired,
    notifications: PropTypes.array.isRequired,
    onMarkAllAsRead: PropTypes.func,
    onLoadMore: PropTypes.func,
    moreExists: PropTypes.bool,
    onRemoveAll: PropTypes.func,
    loading: PropTypes.bool,
    initialLoading: PropTypes.bool,
    onMarkAsRead: PropTypes.func,
    onMarkAsUnread: PropTypes.func,
    onRemove: PropTypes.func
};
