import { createComponents } from './create-components';
import { createPalette } from './create-palette';
import { createShadows } from './create-shadows';

export const createOptions = (config) => {
  const { main_logo_light, main_logo_dark, noAdmin } = config;
  const palette = createPalette(config);
  const components = createComponents({ palette, config });
  const shadows = createShadows();

  return {
    components,
    palette,
    shadows,
    logo: {
      light: main_logo_light,
      dark: main_logo_dark
    },
    setup: noAdmin
  };
};
