import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogContent,
    TextField,
    Typography,
    Grid,
    IconButton,
    InputAdornment,
    DialogTitle, DialogActions, Button, Stack
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import MaxIcon from '@untitled-ui/icons-react/build/esm/Maximize01';
import * as Icons from '@untitled-ui/icons-react/build/cjs';
import { useDialog } from '../../hooks/use-dialog';
import { useTranslation } from 'react-i18next';
import PropTypes from "prop-types";
import OnIcon from "./icon";

function IconSelector({ name, value, onChange, onBlur, error, helperText, ...rest }) {
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredIcons, setFilteredIcons] = useState(Object.keys(Icons).slice(0, 50)); // Initially limit to 50 icons
    const dialog = useDialog();
    const { t } = useTranslation();

    useEffect(() => {
        const filtered = Object.keys(Icons).filter(iconName =>
            iconName.toLowerCase().includes(searchTerm.toLowerCase())
        ).slice(0, 500);
        setFilteredIcons(filtered);
    }, [searchTerm]);

    const handleChange = (event) => {
        setSearchTerm(prev => {
            if(prev.length > 2)
                dialog.handleOpen();
            return event.target.value;
        });
    }

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleIconSelect = (iconName) => {
        onChange({ target: { value: iconName, name } });
        dialog.handleClose();
    };

    const handleRemove = () => {
        if(window.confirm(t('notify.are_you_sure'))){
            onChange({ target: { value: null, name } });
            setSearchTerm('');
            dialog.handleClose();
        }
    }

    return (
        <>
            <TextField
                name={name}
                label={t('common.icon')}
                fullWidth
                value={value || ''}
                onChange={handleChange}
                onClick={dialog.handleOpen}
                onBlur={onBlur}
                error={error}
                helperText={helperText}
                InputProps={{
                    endAdornment: value ? (
                        <InputAdornment position="end">
                            <OnIcon iconName={value} size="small" />
                        </InputAdornment>
                    ) : (
                        <InputAdornment position="end">
                            <IconButton size="small" onClick={dialog.handleOpen}>
                                <OnIcon iconName="Maximize01" size="small" />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
                {...rest}
            />
            <Dialog
                open={dialog.open}
                onClose={dialog.handleClose}
                fullWidth
                maxWidth="md"
            >
                <DialogTitle>
                    <Typography variant="h4" gutterBottom color="text.primary">
                        {t('common.select_icon')}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <TextField
                        fullWidth
                        sx={{ mt: 2 }}
                        autoFocus={true}
                        label={t('common.search_icons')}
                        value={searchTerm}
                        onChange={handleSearchChange}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <div style={{ height: '400px', overflowY: 'auto', marginTop: '30px' }}>
                        <Grid container spacing={2}>
                            {filteredIcons.map((iconName, index) => (
                                <Grid item xs={3} sm={2} md={1} key={index}>
                                    <IconButton
                                        onClick={() => handleIconSelect(iconName)}
                                        color="text.primary"
                                        size="large"
                                        title={iconName}
                                    >
                                        {React.createElement(Icons[iconName])}
                                    </IconButton>
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Stack direction="row" justifyContent="space-between" sx={{width: '100%'}}>
                        <Button onClick={handleRemove} variant="outlined" color="error">
                            {t("common.delete")}
                        </Button>
                        <Button onClick={dialog.handleClose} variant="contained" color="primary">
                            {t("common.cancel")}
                        </Button>
                    </Stack>
                </DialogActions>
            </Dialog>
        </>
    );
}

IconSelector.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    error: PropTypes.bool,
    helperText: PropTypes.string,
};

export default IconSelector;
