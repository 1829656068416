import React from 'react';
import PropTypes from 'prop-types';
import Users03Icon from '@untitled-ui/icons-react/build/esm/Users01';
import {
    Box,
    Button,
    Divider,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    SvgIcon,
    Tooltip,
    Typography,
} from '@mui/material';
import { RouterLink } from 'src/omnia/components/elements/router-link';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../../../store/actions/account-actions';
import { useTranslation } from 'react-i18next';
import { useSecurityCheck } from '../../../../hooks/use-security-check';
import OnIcon from '../../../elements/icon';
import { useIsMobile } from '../../../../hooks/use-is-mobile';
import SmartPopper from "../../../elements/smart-popper";
import usePerformTransition from "../../../../hooks/use-perform-transition";

export const AccountPopover = (props) => {
    const { popover, ...other } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { hasRights } = useSecurityCheck();
    const transit = usePerformTransition(() => {
        dispatch(logout());
        navigate('/login');
    })
    const { isMobile, isFlying } = useIsMobile();
    const user = useSelector((state) => state.account.user);
    const { t } = useTranslation();

    const handleLogout = () => {
        transit();
    };

    if (!user) return null;

    return (
        <SmartPopper
            popover={popover}
            placement={isMobile ? "bottom" : (isFlying ? "left-start" : "bottom-end")}
            minWidth={150}
            {...other}
        >
            <Box sx={{ p: 2 }}>
                <Typography variant="h6">{user.name}</Typography>
                <Typography color="text.secondary" variant="body2">
                    {user.position}
                </Typography>
            </Box>
            <Divider />
            <Box sx={{ p: 1 }}>
                {hasRights('social_intranet') && (
                    <Tooltip enterDelay={1000} title={t('common.tooltips.profile')} placement="left">
                        <ListItemButton
                            component={RouterLink}
                            href={'/groon/profile'}
                            onClick={popover.handleClose}
                            sx={{
                                borderRadius: 1,
                                px: 1,
                                py: 0.5,
                            }}
                        >
                            <ListItemIcon>
                                <OnIcon iconName="User01" size="small" />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography variant="body1">{t('layout.profile')}</Typography>
                                }
                            />
                        </ListItemButton>
                    </Tooltip>
                )}
                {(hasRights('social_intranet') && user.user_group) && (
                    <Tooltip enterDelay={1000} title={t('common.tooltips.group')} placement="left">
                        <ListItemButton
                            component={RouterLink}
                            href={'/groon/team/' + user.user_group?.id}
                            onClick={popover.handleClose}
                            sx={{
                                borderRadius: 1,
                                px: 1,
                                py: 0.5,
                            }}
                        >
                            <ListItemIcon>
                                <SvgIcon fontSize="small">
                                    <Users03Icon />
                                </SvgIcon>
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography variant="body1">{t('layout.group')}</Typography>
                                }
                            />
                        </ListItemButton>
                    </Tooltip>
                )}
                {hasRights(['social_intranet', 'manage_organization', 'manage_users'], 'or') && (
                    <Tooltip enterDelay={1000} title={t('common.tooltips.organization')} placement="left">
                        <ListItemButton
                            component={RouterLink}
                            href={'/groon/organization'}
                            onClick={popover.handleClose}
                            sx={{
                                borderRadius: 1,
                                px: 1,
                                py: 0.5,
                            }}
                        >
                            <ListItemIcon>
                                <SvgIcon fontSize="small">
                                    <OnIcon iconName="Building07" />
                                </SvgIcon>
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography variant="body1">
                                        {t('layout.organization')}
                                    </Typography>
                                }
                            />
                        </ListItemButton>
                    </Tooltip>
                )}
                {hasRights('control_center') && (
                    <Tooltip enterDelay={1000} title={t('common.tooltips.control')} placement="left">
                        <ListItemButton
                            component={RouterLink}
                            href={'/groon/control'}
                            onClick={popover.handleClose}
                            sx={{
                                borderRadius: 1,
                                px: 1,
                                py: 0.5,
                            }}
                        >
                            <ListItemIcon>
                                <OnIcon iconName="Speedometer03" size="small" />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography variant="body1">
                                        {t('layout.management')}
                                    </Typography>
                                }
                            />
                        </ListItemButton>
                    </Tooltip>
                )}
                <Tooltip enterDelay={1000} title={t('common.tooltips.configuration')} placement="left">
                    <ListItemButton
                        component={RouterLink}
                        href={'/groon/control/settings'}
                        onClick={popover.handleClose}
                        sx={{
                            borderRadius: 1,
                            px: 1,
                            py: 0.5,
                        }}
                    >
                        <ListItemIcon>
                            <OnIcon iconName="Settings02" size="small" />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Typography variant="body1">
                                    {t('layout.configuration')}
                                </Typography>
                            }
                        />
                    </ListItemButton>
                </Tooltip>
            </Box>
            <Divider sx={{ my: '0 !important' }} />
            <Box
                sx={{
                    display: 'flex',
                    p: 1,
                    justifyContent: 'center',
                }}
            >
                <Button fullWidth onClick={handleLogout} color="inherit" variant="text">
                    {t('layout.logout')}
                </Button>
            </Box>
        </SmartPopper>
    )

};

AccountPopover.propTypes = {
    popover: PropTypes.object,
};
