import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import App from 'src/omnia/App';
import LoadingScreen from "./omnia/components/general/loading-screen";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <HelmetProvider>
        <BrowserRouter>
            <Suspense fallback={<LoadingScreen/>}>
                <App/>
            </Suspense>
        </BrowserRouter>
    </HelmetProvider>
);
