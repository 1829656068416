import { alpha } from "@mui/material/styles";
import chroma from "chroma-js";

const withAlphas = (color) => {
    return {
        ...color,
        alpha4: alpha(color.main, 0.04),
        alpha8: alpha(color.main, 0.08),
        alpha12: alpha(color.main, 0.12),
        alpha30: alpha(color.main, 0.30),
        alpha50: alpha(color.main, 0.50)
    };
};

export const neutral = {
    50: "#f9f9f9",
    100: "#f1f1f1",
    200: "#e5e5e5",
    300: "#d3d3d3",
    400: "#b2b2b2",
    500: "#7f7f7f",
    600: "#525252",
    700: "#373737",
    800: "#252525",
    900: "#0d0d0d"
};

export const getDynamicColor = (hex) => {

    let lightFont = "#FFFFFF";
    let darkFont = "#090909";

    let p = hex || '#2970FF';
    let pl = chroma(p).brighten().hex();
    let pll = chroma(pl).brighten().hex();
    let pd = chroma(p).darken().hex();
    let pdd = chroma(pd).darken().hex();
    let ct = chroma.deltaE(p, lightFont) > chroma.deltaE(p, darkFont) ? lightFont : darkFont;

    const data = withAlphas({
        lightest: pll,
        light: pl,
        main: p,
        dark: pd,
        darkest: pdd,
        contrastText: ct
    });

    return data
};

export const success = withAlphas({
    lightest: "#F0FDF9",
    light: "#3FC79A",
    main: "#10B981",
    dark: "#0B815A",
    darkest: "#134E48",
    contrastText: "#FFFFFF"
});

export const info = withAlphas({
    lightest: "#ECFDFF",
    light: "#CFF9FE",
    main: "#06AED4",
    dark: "#0E7090",
    darkest: "#164C63",
    contrastText: "#FFFFFF"
});

export const warning = withAlphas({
    lightest: "#FFFAEB",
    light: "#FEF0C7",
    main: "#F79009",
    dark: "#B54708",
    darkest: "#7A2E0E",
    contrastText: "#FFFFFF"
});

export const error = withAlphas({
    lightest: "#FEF3F2",
    light: "#FEE4E2",
    main: "#F04438",
    dark: "#B42318",
    darkest: "#7A271A",
    contrastText: "#FFFFFF"
});
