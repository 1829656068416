export const UPDATE_RIGHTS = '@rights/update-rights';
export const REMOVE_RIGHTS = '@rights/remove-rights';
export const ADD_RIGHT = '@rights/add-rights';
export const UPDATE_OPTIONS = '@rights/update-rights-options';
export const ADD_REGISTRATIONS = '@rights/add-registration';
export const UPDATE_REGISTRATION = '@rights/update-registration';

export function addRegistrations(registrations){
    return (dispatch) => dispatch({
        type: ADD_REGISTRATIONS,
        payload: {
            registrations
        }
    })
}

export function updateRegistration(registration){
    return (dispatch) => dispatch({
        type: UPDATE_REGISTRATION,
        payload: {
            registration
        }
    })
}

export function storeRights(rights) {
    return (dispatch) => dispatch({
        type: UPDATE_RIGHTS,
        payload: rights
    });
}

export function storeRightsOptions(rightsOptions){
    return (dispatch) => dispatch({
        type: UPDATE_OPTIONS,
        payload: rightsOptions
    });
}

export function addRight(newRight){
    return (dispatch) => dispatch({
        type: ADD_RIGHT,
        payload: newRight
    })

}

export function deleteRights(removedIds) {
    return (dispatch) => dispatch({
        type: REMOVE_RIGHTS,
        payload: removedIds
    })

}
