export const RELOAD_TASKS = '@jobs/reload-jobs';
export const UPDATE_TASK = '@jobs/update-task';
export const ADD_TASK = '@jobs/add-task';
export const REMOVE_TASK = '@jobs/remove-task';
export const SET_FILTERS = '@jobs/set-filters';

export function setFilters(filters){
    return (dispatch) => dispatch({
        type: SET_FILTERS,
        payload: {
            filters
        }
    })
}

export function reloadTasks(tasks) {
    return (dispatch) => dispatch({
        type: RELOAD_TASKS,
        payload: {
            tasks
        }
    });
}

export function setCompleted(task){
    return (dispatch) => dispatch({
        type: UPDATE_TASK,
        payload: {
            task
        }
    });
}


export function updateTask(task) {
    return (dispatch) => dispatch({
        type: UPDATE_TASK,
        payload: {
            task
        }
    });
}

export function addTask(task) {
    return (dispatch) => dispatch({
        type: ADD_TASK,
        payload: {
            task
        }
    });
}

export function removeTask(taskId) {
    return (dispatch) => dispatch({
        type: REMOVE_TASK,
        payload: {
            taskId
        }
    });
}