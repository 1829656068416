import React, {useState} from "react";
import PropTypes from 'prop-types';
import {Box, Card, Container, Slide, useMediaQuery} from '@mui/material';
import {alpha, styled} from '@mui/material/styles';
import {MobileNav} from '../mobile-nav';
import {SideNav} from './side-nav';
import {TopNav} from './top-nav';
import {useMobileNav} from '../../../../hooks/use-mobile-nav';
import ErrorHandler from "../../../elements/error-handler";
import ChatAssistantViewer from "../../../modules/core/connect/chat/chat-assistant-viewer";
import {ChatMessageAdd} from "../../../modules/core/connect/chat/chat-message-add";
import {useSelector} from "react-redux";
import {useTheme} from "@mui/system";
import {useLocation} from "react-router-dom";
import useOmniaApi from "../../../../hooks/use-omnia-api";
import {useTranslation} from "react-i18next";
import {useSettings} from "../../../../hooks/use-settings";

const SIDE_NAV_WIDTH = 250;

const VerticalLayoutRoot = styled('div')(({mobileNavActive}) => ({
    display: 'flex',
    flex: '1 1 auto',
    maxWidth: '100%',
    paddingLeft: mobileNavActive ? 0 : SIDE_NAV_WIDTH
}));

const VerticalLayoutContainer = styled('div')({
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    width: '100%'
});

const AssistantChatOverlay = styled('div')(({ theme, mobileNavActive }) => ({
    position: 'fixed',
    bottom: 0,
    width: 'calc(100% - ' + (mobileNavActive ? 0 : SIDE_NAV_WIDTH) + 'px)',
    height: '400px',
    backdropFilter: 'blur(10px)',
    backgroundColor: alpha(theme.palette.background.paper, 0.8),
    borderTop: `1px solid ${theme.palette.divider}`,
    zIndex: theme.zIndex.modal - 1,
    display: 'flex',
    flexDirection: 'column',
}));

const ChatMessagesContainer = styled('div')({
    flex: 1,
    overflowY: 'auto',
    padding: '0px',
});

const ChatInputContainer = styled('div')(() => ({
    padding: '8px',
}));

export const VerticalLayout = (props) => {
    const {children, sections, navColor, navMode} = props;
    const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
    const mobileNav = useMobileNav();
    const chatOpen = useSelector((state) => state.messages.assistantOpen);
    const mobileNavActive = !lgUp || (navMode === 'hidden');
    const theme = useTheme();
    const [isSending, setIsSending] = useState(false);
    const user = useSelector((state) => state.account.user);
    const location = useLocation();
    const { post, uploadStatus } = useOmniaApi();
    const settings = useSettings();
    const { t } = useTranslation();

    const chatIsVisible = chatOpen && (location.pathname !== '/groon/launchpad');

    const handleOpenMenu = () => {
        if(!lgUp){
            mobileNav.handleOpen();
        } else {
            settings?.handleUpdate?.({
                navMode: 'normal'
            });
        }
    }

    const handleSend = (values) => {
        return new Promise((resolve, reject) => {
            setIsSending(true);
            post('core/assistant-chats/' + user?.current_assistant_chat + '/new_message', values)
                .then((message) => {
                    resolve(message);
                })
                .catch((errors) => {
                    reject(errors);
                })
                .finally(() => {
                    setIsSending(false);
                });
        });
    };

    return (
        <>
            <TopNav
                onMobileNavOpen={handleOpenMenu}
                mobileNavActive={mobileNavActive}
            />
            {!mobileNavActive && (
                <SideNav
                    color={navColor}
                    sections={sections}
                />
            )}
            {mobileNavActive && (
                <MobileNav
                    color={navColor}
                    onClose={mobileNav.handleClose}
                    open={mobileNav.open}
                    sections={sections}
                />
            )}
            <VerticalLayoutRoot mobileNavActive={mobileNavActive}>
                <VerticalLayoutContainer>
                    <Box sx={{
                        pb: chatIsVisible ? '400px' : '0px',
                        display: 'flex',
                        flex: '1 1 auto',
                        flexDirection: 'column',
                        width: '100%',
                    }}>
                        <ErrorHandler>{children}</ErrorHandler>
                    </Box>
                    <Slide direction="up" in={chatIsVisible} mountOnEnter unmountOnExit>
                        <AssistantChatOverlay mobileNavActive={mobileNavActive}>
                            <ChatMessagesContainer>
                                <Container maxWidth="xl">
                                    <ChatAssistantViewer smallVersion={false} />
                                </Container>
                            </ChatMessagesContainer>
                            <ChatInputContainer>
                                <Container maxWidth="xl">
                                    <Card sx={{ p: 0.4 }}>
                                        <ChatMessageAdd
                                            disabled={isSending}
                                            uploadStatus={uploadStatus}
                                            noPadding
                                            sending={isSending}
                                            sx={{ borderRadius: `${parseInt(theme?.config?.card_radius) - 2}px` }}
                                            size="small"
                                            onSend={handleSend}
                                            placeholder={t('core.write_prompt')}
                                            smallVersion={true}
                                        />
                                    </Card>
                                </Container>
                            </ChatInputContainer>
                        </AssistantChatOverlay>
                    </Slide>
                </VerticalLayoutContainer>
            </VerticalLayoutRoot>
        </>
    );
};

VerticalLayout.propTypes = {
    children: PropTypes.node,
    navColor: PropTypes.oneOf(['blend-in', 'discreet', 'evident']),
    navMode: PropTypes.oneOf(['normal', 'hidden']),
    sections: PropTypes.array,
};