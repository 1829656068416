/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
import produce from "immer";
import {
    UPDATE_SHOW_FILTERS,
    UPDATE_SEARCH_STATE
} from "../actions/invoices-actions";

const initialState = {
    showFilters: null,
    searchState: {
        filters: {
            customers: [],
            endDate: undefined,
            query: '',
            startDate: undefined
        },
        page: 0,
        rowsPerPage: 5
    }
};

const invoicesReducer = (state = initialState, action) => {

    switch (action.type) {

        case UPDATE_SHOW_FILTERS: {
            return produce(state, draft => {
                draft.showFilters = action.payload.showFilters;
            })
        }

        case UPDATE_SEARCH_STATE: {
            return produce(state, draft => {
                draft.searchState = action.payload.searchState;
            })
        }

        default: {
            return state;
        }
    }

};

export default invoicesReducer;
