import React from "react";
import SmartSelector from "../../../elements/smart-selector";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {useNavigate} from "react-router-dom";
import {Box, Button, IconButton, Stack, Tooltip} from "@mui/material";
import {useSecurityCheck} from "../../../../hooks/use-security-check";
import OnIcon from "../../../elements/icon";

function ThemePicker({value, onChange, onBlur, name}){

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { hasRights } = useSecurityCheck();

    const handleEditThemes = () => {
        navigate('/groon/themes');
    }

    return (
        <Stack direction="row" spacing={2} sx={{width: '100%'}} justifyContent="stretch" alignItems="center">
            <SmartSelector
                endpoint='core/themes'
                sx={{width: '100%'}}
                values={value}
                multiple={false}
                handleChange={onChange}
                name={name}
                onBlur={onBlur}
                label={t('common.theme')}
            />
            {hasRights('theme_management') && (
                <Tooltip title={t('common.manage')}>
                    <IconButton onClick={handleEditThemes}>
                        <OnIcon iconName="Brush02" />
                    </IconButton>
                </Tooltip>
            )}
        </Stack>
    )
}

ThemePicker.propTypes = {
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string,
    onBlur: PropTypes.func
}

ThemePicker.defaultProps = {
    name: 'theme'
}

export default ThemePicker;