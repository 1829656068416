import {useRoutes} from "react-router";
import {getRoutes} from "../routes";
import {memo, useMemo} from "react";

const ContentRenderer = memo(() => {
    const routes = useMemo(() => getRoutes(), []);
    return useRoutes(routes);
}, () => {
    return true;
});

export default ContentRenderer;