import authService from 'src/omnia/services/auth-service';
import {loadTableSettings} from "./datatable-actions";
import {logRenderingError} from "./client-actions";

export const LOGIN_REQUEST = '@account/login-request';
export const SILENT_LOGIN = '@account/silent-login';
export const LOGOUT = '@account/logout';
export const UPDATE_AVATAR = '@account/update-avatar';
export const UPDATE_COVER = '@account/update-cover';
export const SET_CURRENT_DASHBOARD = '@account/set-current-settings';
export const RELOAD_ME = '@account/reload';
export const SET_UNREAD_NOTIFICATIONS = '@account/set-unread-notifications';
export const SET_NEW_REPORT = '@account/set-report';
export const REMOVE_REPORT = '@account/remove-report';
export const UPDATE_WORKING_HOURS = '@account/update-working-hours';
export const UPDATE_LAUNCHPAD = '@account/update-launchpad';
export const SET_IS_TRANSITIONING = '@account/set-is-transitioning';
export const SET_WIRELESS_RECONNECT = '@account/set-wireless-reconnect';
export const SET_GPU_AVAILABLE = '@account/set-gpu-available';
export const SET_INSTALLED_MODULES = '@account/set-installed-modules';


export function setInstalledModules(modules) {
    return dispatch => dispatch({
        type: SET_INSTALLED_MODULES,
        payload: modules
    })
}


export function setGpuAvailable(available) {
    return dispatch => dispatch({
        type: SET_GPU_AVAILABLE,
        payload: available
    })
}


export function setWirelessReconnect(reconnect) {
    return dispatch => dispatch({
        type: SET_WIRELESS_RECONNECT,
        payload: reconnect
    })
}


export function setIsTransitioning(isTransitioning) {
    return dispatch => dispatch({
        type: SET_IS_TRANSITIONING,
        payload: isTransitioning
    })
}


export function updateLaunchpad(launchpad) {
    return dispatch => dispatch({
        type: UPDATE_LAUNCHPAD,
        payload: launchpad
    })
}


export function updateWorkingHours(hours){
    return dispatch => dispatch({
        type: UPDATE_WORKING_HOURS,
        payload: hours
    })
}

export function setNewReport(report) {
    return dispatch => dispatch({
        type: SET_NEW_REPORT,
        payload: report
    })
}

export function removeReport() {
    return dispatch => dispatch({
        type: REMOVE_REPORT,
    })
}

export function setUserData(user) {

    return (dispatch) => {

        // Dispatch datatable settings
        let tableSetting = [];
        try {
            // Attempt to decode the state json
            tableSetting = JSON.parse(user['table_settings']);
        } catch (e) {
            // Dispatch the table setting
            dispatch(loadTableSettings(tableSetting));
            dispatch(logRenderingError("", e, "Silent Login"));
        }

        // Dispatch the table setting
        dispatch(loadTableSettings(tableSetting));

        // Dispatch silent login
        dispatch({
            type: SILENT_LOGIN,
            payload: {
                user
            }
        })
    };
}

export function logout() {
    return (dispatch) => {
        authService.logout();

        dispatch({
            type: LOGOUT
        });
    };
}

export function register() {
    return true;
}

export function updateOwnAvatar(avatar) {
    return (dispatch) => dispatch({
        type: UPDATE_AVATAR,
        payload: avatar
    })
}

export function updateOwnCover(cover) {
    return (dispatch) => dispatch({
        type: UPDATE_COVER,
        payload: cover
    })
}

export function setCurrentDashboard(dashboardId) {
    return (dispatch) => dispatch({
        type: SET_CURRENT_DASHBOARD,
        payload: dashboardId
    })
}

export function setUnreadNotifications(unread){
    return (dispatch) => dispatch({
        type: SET_UNREAD_NOTIFICATIONS,
        payload: { unread }
    })
}
