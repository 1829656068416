import {Box, ButtonBase, Typography} from "@mui/material";
import OnIcon from "../../elements/icon";
import React from "react";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";

const SearchButton = ({ depth, offset }) => {
    const { t } = useTranslation();

    return (
        <ButtonBase
            sx={{
                alignItems: 'center',
                borderRadius: 1,
                display: 'flex',
                justifyContent: 'flex-start',
                pl: `${(depth === 0 ? 10 : 42) + offset}px`,
                pr: '14px',
                py: '8px',
                width: '100%',
                textAlign: 'left',
                backgroundColor: 'var(--nav-item-hover-bg)',
                '&:hover': {
                    backgroundColor: 'var(--nav-item-active-bg)'
                }
            }}
        >
            <Box
                component="span"
                sx={{
                    alignItems: 'center',
                    color: 'var(--nav-item-icon-color)',
                    display: 'inline-flex',
                    justifyContent: 'center',
                    mr: 2,
                }}
            >
                <OnIcon iconName="SearchMd" size="small" />
            </Box>
            <Box
                component="span"
                sx={{
                    color: 'var(--nav-item-icon-color)',
                    flexGrow: 1,
                    fontFamily: (theme) => theme.typography.fontFamily,
                    fontWeight: depth > 0 ? 400 : 600,
                    whiteSpace: 'nowrap',
                }}
            >
                <Typography
                    variant={depth > 0 ? "body2" : "body1"}
                    sx={{ fontWeight: depth > 0 ? 400 : 600, lineHeight: 1 }}
                >
                    {t('common.search')}
                </Typography>
            </Box>
            <Box
                component="span"
                sx={{
                    color: 'var(--nav-item-icon-color)',
                    display: 'flex',
                    alignItems: 'center',
                    ml: 2
                }}
            >
                <Typography variant="body2" sx={{ mr: 0.5 }}>⌘</Typography>
                <Typography variant="body2">K</Typography>
            </Box>
        </ButtonBase>
    );
};

SearchButton.propTypes = {
    depth: PropTypes.number,
    offset: PropTypes.number
};

export default SearchButton;