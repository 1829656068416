import PropTypes from 'prop-types';
import {Box, Divider, Link, Stack} from '@mui/material';
import {Link as RouterLink} from "react-router-dom";
import Logo from "../../../general/logo";
import React, {useEffect, useState} from "react";
import {useSettings} from "src/omnia/hooks/use-settings";
import {useIsMobile} from "../../../../hooks/use-is-mobile";

export const TenantSwitch = (props) => {

    const settings = useSettings();
    const [logoColor, setLogoColor] = useState("light");
    const { isMobile, isFlying } = useIsMobile();

    useEffect(() => {
        if(settings.layout === 'vertical'){
            if (settings.paletteMode === "light") {
                if (settings.navColor === "evident") {
                    setLogoColor('light');
                } else {
                    setLogoColor('dark');
                }
            } else {
                setLogoColor('light');
            }
        } else {
            setLogoColor(settings.paletteMode === 'light' ? 'dark' : 'light');
        }
    }, [settings, isFlying]);

    return (
        <Box
            style={{
                width: '100%',
                height: '100%',
                padding: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            {isMobile ? (
                <Logo
                    isHeader
                    width='auto'
                    height={75}
                    color={logoColor}
                />
            ) : (
                <Logo
                    isHeader
                    width={'70%'}
                    height={'auto'}
                    color={logoColor}
                />
            )}
        </Box>
    );
};

TenantSwitch.propTypes = {
    sx: PropTypes.object
};
