import React, {useCallback, useEffect, useState, useRef} from "react";
import { isDesktop } from "react-device-detect";
import { toast } from "react-hot-toast";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import {useSettings} from "./use-settings";

export const useNotifications = () => {
    const theme = useTheme();
    const [notifications, setNotifications] = useState([]);
    const [screenIsVisible, setScreenIsVisible] = useState(true);
    const settings = useSettings();
    const settingsRef = useRef();
    const themeRef = useRef();
    const notificationsRef = useRef();
    themeRef.current = theme;
    settingsRef.current = settings;
    notificationsRef.current = notifications;

    const isScreenVisible = useCallback(() => {
        return document.visibilityState === "visible";
    }, []);

    const closeAllNotifications = useCallback(() => {
        if (isScreenVisible()) {
            notificationsRef.current.forEach(notification => notification.close());
            setNotifications([]);
        }
    }, [notifications, isScreenVisible]);

    const desktopNotificationsAllowed = useCallback(() => {

        try {
            if (!isDesktop) return false;
            if (Notification.permission === "granted") {
                return settingsRef.current?.desktopNotifications || false;
            } else {
                return false;
            }
        } catch (e) {
            return false;
        }

    }, []);

    const flashDesktopNotification = useCallback((message, title) => {
        if (desktopNotificationsAllowed()) {
            const tmp = new Notification(title, {
                title,
                body: message,
                icon: "/api/setup/favicon/",  // FIXME: Might fail here
                dir: "ltr",
            });
            setNotifications((prev) => prev.concat([tmp]));
        }
    }, [desktopNotificationsAllowed, closeAllNotifications]);

    const notify = useCallback((message, variant = "default", asDesktop = false, duration = 3000) => {
        // Check for desktop notification to be thrown
        if (!isScreenVisible() && asDesktop && desktopNotificationsAllowed())
            flashDesktopNotification(message, "GROON");

        // Check if this message is still showing
        const commonProps = {
            duration: duration,
            id: message,
            style: { backgroundColor: themeRef.current.palette.background.default },
        };

        const content = (
            <Typography variant="body1" color={themeRef.current.palette.text.primary}>
                {message}
            </Typography>
        );

        if (variant === "success") {
            toast.success(content, commonProps);
        } else if (variant === "error") {
            toast.error(content, commonProps);
        } else {
            toast(content, commonProps);
        }
    }, [isScreenVisible, desktopNotificationsAllowed, flashDesktopNotification]);

    const notifySuccess = useCallback((t) => {
        {
            const successMessages = [
                'notify.success',
                'notify.success1',
                'notify.success2',
                'notify.success3',
                'notify.success4',
                'notify.success5',
                'notify.success6',
                'notify.success7',
                'notify.success8',
                'notify.success9',
                'notify.success10'
            ];
            const randomIndex = Math.floor(Math.random() * successMessages?.length);
            const selectedMessageKey = successMessages?.[randomIndex];
            notify(t(selectedMessageKey || 'notify.success'), 'success');
        }
    }, [notify])

    const flashStatusChange = useCallback((message) => {
            toast(
                <Typography variant="body1" color={themeRef.current.palette.text.primary}>
                    {message}
                </Typography>,
                {
                    style: {
                        backgroundColor: themeRef.current.palette.background.default,
                    },
                }
            );
        }, []);

    useEffect(() => {
        const onVisibilityChange = () => {
            const isVisible = document.visibilityState === "visible";
            if (screenIsVisible !== isVisible) setScreenIsVisible(isVisible);
            if(isVisible){
                closeAllNotifications();
            }
        };
        document.addEventListener("visibilitychange", onVisibilityChange);
        return () => {
            document.removeEventListener("visibilitychange", onVisibilityChange);
        };
    }, [screenIsVisible]);

    return {
        screenIsVisible,
        notify,
        notifySuccess,
        flashStatusChange,
        flashDesktopNotification,
        isScreenVisible,
    };
};
