/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
import produce from 'immer';
import {
    LOGIN_REQUEST,
    SET_UNREAD_NOTIFICATIONS,
    SET_CURRENT_DASHBOARD,
    UPDATE_COVER,
    SET_NEW_REPORT,
    REMOVE_REPORT,
    LOGOUT,
    SILENT_LOGIN,
    RELOAD_ME,
    UPDATE_AVATAR,
    UPDATE_LAUNCHPAD,
    UPDATE_WORKING_HOURS,
    SET_IS_TRANSITIONING,
    SET_WIRELESS_RECONNECT,
    SET_GPU_AVAILABLE,
    SET_INSTALLED_MODULES
} from 'src/omnia/store/actions/account-actions';

const initialState = {
    user: null,
    unread: 0,
    gpuAvailable: false,
    wirelessReconnect: null,
    competencies: [],
    isTransitioning: false,
    report: null,
    launchpad: null,
    responsibilities: [],
    currentDashboard: null,
    modules: {
        resources: false,
        projects: false,
        connections: false,
        marketing: false,
        intranet: false,
        intelligence: false,
    },
    workingHours: {
        day: 0,
        week: 0,
        month: 0,
        accountId: null
    }
};

const accountReducer = (state = initialState, action) => {

    switch (action.type) {

        case SET_INSTALLED_MODULES: {
            return produce(state, (draft) => {
                draft.modules = action.payload;
            });
        }

        case SET_GPU_AVAILABLE: {
            return produce(state, (draft) => {
                draft.gpuAvailable = action.payload;
            });
        }

        case SET_WIRELESS_RECONNECT: {
            return produce(state, (draft) => {
                draft.wirelessReconnect = action.payload;
            });
        }

        case SET_IS_TRANSITIONING: {
            return produce(state, (draft) => {
                draft.isTransitioning = action.payload;
            });
        }

        case UPDATE_LAUNCHPAD: {
            const launchpad = action.payload;
            return produce(state, (draft) => {
                draft.launchpad = launchpad;
            });
        }

        case UPDATE_WORKING_HOURS: {
            const hours = action.payload;
            return produce(state, (draft) => {
                draft.workingHours = hours;
            });
        }

        case REMOVE_REPORT: {
            return produce(state, (draft) => {
                draft.report = null;
            });
        }

        case SET_NEW_REPORT: {
            return produce(state, (draft) => {
                draft.report = action.payload;
            });
        }

        case SET_UNREAD_NOTIFICATIONS: {
            const {unread} = action.payload;
            return produce(state, (draft) => {
                draft.unread = unread;
            });
        }

        case LOGIN_REQUEST: {
            return produce(state, (draft) => {
                // Ensure we clear current session
                draft.user = null;
            });
        }

        case LOGOUT: {
            return produce(state, (draft) => {
                draft.user = null;
            });
        }

        case SILENT_LOGIN: {
            const {user} = action.payload;

            return produce(state, (draft) => {
                draft.user = user;
            });
        }

        case RELOAD_ME: {
            const user = action.payload;

            // compare the existing state and the incomming details
            if (JSON.stringify(state.user) !== JSON.stringify(user)) {
                return produce(state, (draft) => {
                    draft.user = user;
                });
            } else {
                return state;
            }
        }

        case UPDATE_AVATAR:
            return produce(state, draft => {
                draft.user['avatar'] = action.payload;
            })

        case UPDATE_COVER:
            return produce(state, draft => {
                draft.user['cover'] = action.payload;
            })

        case SET_CURRENT_DASHBOARD:
            return produce(state, draft => {
                draft.currentDashboard = action.payload;
            })

        default: {
            return state;
        }

    }
};

export default accountReducer;
