import React from "react";
import {Button, IconButton, MenuItem, Stack, Tooltip, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {useSecurityCheck} from "../../../hooks/use-security-check";
import useOmniaApi from "../../../hooks/use-omnia-api";
import {useNotifications} from "../../../hooks/use-notifications";
import {useNavigate} from "react-router-dom";
import OnIcon from "../icon";

function RemoveSharableObject({entity, endpoint, onRemove, removeTooltip, variant, noRedirect, removeLabel, removeRight, disabled, withIcon, ...rest}){
    const { t } = useTranslation();
    const { hasRights } = useSecurityCheck();
    const { del } = useOmniaApi();
    const { notifySuccess } = useNotifications();
    const navigate = useNavigate();

    const handleRemove = (event) => {
        if(event){
            event.preventDefault();
            event.stopPropagation();
        }
        if(window.confirm(t('notify.are_you_sure'))){
            del(endpoint, entity?.id).then(() => {
                notifySuccess(t);
                if(!noRedirect)
                    navigate(entity?.entity_link || '/groon');
                onRemove?.();
            });
        }
    }

    // FIXME: what about public?

    if(!entity)
        return null;

    return (
        <>
            {!entity?.is_owner ? (
                <Tooltip title={t('common.tooltips.stop_sharing_tooltip', {name: entity?.object_name})} placement='left'>
                    {variant === 'button' && (
                        <Button color="error" variant="outlined" onClick={handleRemove} {...rest}>
                            {t('common.stop_sharing')}
                        </Button>
                    )}
                    {variant === 'menu-item' && (
                        <MenuItem onClick={handleRemove} disabled={disabled} {...rest}>
                            <Stack direction="row" alignItems="center" spacing={2}>
                                {withIcon && (
                                    <OnIcon iconName="Trash01" size="small" color="error" />
                                )}
                                <Typography color="error">
                                    {t('common.stop_sharing')}
                                </Typography>
                            </Stack>
                        </MenuItem>
                    )}
                    {variant === 'icon-button' && (
                        <IconButton color="error" variant={variant} onClick={handleRemove} {...rest}>
                            <OnIcon iconName="Trash01" color="error" />
                        </IconButton>
                    )}
                </Tooltip>
            ) : (
                <Tooltip title={t(removeTooltip || 'common.tooltips.remove_object_tooltip', {name: entity?.object_name})} placement='left'>
                    {variant === 'button' && (
                        <Button color="error" variant="outlined" onClick={handleRemove} disabled={!hasRights(removeRight) || disabled} startIcon={<OnIcon iconName="Trash01" />} {...rest}>
                            {t(removeLabel || 'common.remove_object', {name: entity?.entity_name})}
                        </Button>
                    )}
                    {variant === 'menu-item' && (
                        <MenuItem onClick={handleRemove} disabled={!hasRights(removeRight) || disabled} {...rest}>
                            <Stack direction="row" alignItems="center" spacing={2}>
                                {withIcon && (
                                    <OnIcon iconName="Trash01" size="small" color="error" />
                                )}
                                <Typography color="error">
                                    {t(removeLabel || 'common.remove_object', {name: entity?.entity_name})}
                                </Typography>
                            </Stack>
                        </MenuItem>
                    )}
                    {variant === 'icon-button' && (
                        <IconButton color="error" variant={variant} disabled={!hasRights(removeRight) || disabled} onClick={handleRemove} {...rest}>
                            <OnIcon iconName="Trash01" color="error" />
                        </IconButton>
                    )}
                </Tooltip>
            )}
        </>
    )

}

RemoveSharableObject.propTypes = {
    entity: PropTypes.object.isRequired,
    endpoint: PropTypes.string.isRequired,
    removeTooltip: PropTypes.string,
    removeLabel: PropTypes.string,
    removeRight: PropTypes.any,
    onRemove: PropTypes.func,
    disabled: PropTypes.bool,
    withIcon: PropTypes.bool,
    noRedirect: PropTypes.bool,
    variant: PropTypes.oneOf(['button', 'menu-item', 'icon-button'])
}

RemoveSharableObject.defaultProps = {
    removeTooltip: null,
    removeLabel: null,
    removeRight: [],
    onRemove: null,
    disabled: false,
    withIcon: false,
    variant: 'menu-item',
    noRedirect: false,
}

export default RemoveSharableObject;