import {useSelector} from "react-redux";
import {useAppMode} from "./use-app-mode";

export const useSecurityCheck = () => {

    const {isService} = useAppMode();
    const user = useSelector(state => state.account.user);
    const serviceUser = useSelector(state => state.service.user);
    const moduleData = useSelector(state => state.account.modules);

    const isModuleInstalled = ( module ) => {
        // check if module is a key in moduleData, if not, raise an error
        if(!moduleData.hasOwnProperty(module)){
            console.log('Module name not found in installedModuleData: ', module);
            return false;
        }

        // check if the module is installed
        return moduleData[module];
    }

    const hasRights = ( wantedRights, operation="and" ) => {

        if(isService){

            // TODO: implement rights for service registrations
            // console.log('No right handling for service user ', serviceUser);
            return true;

        } else {
            if(typeof(wantedRights) === "string"){

                // Simply check the passed right
                return user.rights.includes(wantedRights);

            } else if(typeof(wantedRights) === "object"){

                // Check if there are any rights at all
                if(wantedRights.length === 0)
                    return true;

                // Check all passed rights
                let successfulChecks = [];
                for (let i = 0; i < wantedRights.length; i++)
                    if(user.rights.includes(wantedRights[i]))
                        successfulChecks.push(wantedRights[i]);

                // Check the success of the operation
                if(operation.toLowerCase() === "or"){
                    return successfulChecks.length > 0;
                } else if(operation.toLowerCase() === "and"){
                    return successfulChecks.length === wantedRights.length;
                } else {
                    return false;
                }
            } else {
                console.log('useSecurityCheck does not recognize the type of rights to check')
                return false;
            }
        }

    }

    return { hasRights, isModuleInstalled };
}