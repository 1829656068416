/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
import produce from 'immer';
import _ from 'lodash';
import {
    GET_BOARD,
    UPDATE_LIST,
    CHANGE_ATTACHMENTS,
    CLEAR_LIST,
    DELETE_LIST,
    CHANGE_LABELS,
    UPDATE_CARD,
    ARCHIVE_LIST,
    CHANGE_MEMBERS,
    MOVE_CARD,
    DELETE_CARD,
    ADD_COMMENT,
    ADD_CHECKLIST,
    UPDATE_CHECKLIST,
    DELETE_CHECKLIST,
    UPDATE_CHECK_ITEM,
    DELETE_CHECK_ITEM,
    SHOW_ARCHIVED,
    HIDE_ARCHIVED,
    UPDATE_QUERY,
    UPDATE_LABELS,
    SET_SPACE_ID,
    ADD_UPDATE_CARDS,
    SET_LOADING,
    SET_BOARD_ID
} from 'src/omnia/store/actions/kanban-actions';
import objFromArray from 'src/omnia/utils/objFromArray';

// todo: rebuild the whole thing for many boards with ids
const initialState = {
    labels: [],
    filter: {
        showArchived: false,
        query: ""
    },
    // current board id
    boardId: -1,
    spaceId: -1,
    lists: {
        byId: {},
        allIds: []
    },
    cards: {
        byId: {},
        allIds: []
    },
    members: [],
    loading: true,
    newCardInformation: [],

};

const kanbanReducer = (state = initialState, action) => {

    switch (action.type) {

        case SET_LOADING: {
            return produce(state, (draft) => {
                draft.loading = action.payload;
                if(state.loading && !action.payload){

                    // check for cards to remove (based on newCardInformation)
                    const newCardIds = new Set(draft.newCardInformation);
                    Object.values(draft.cards.byId).forEach((card)=> {
                        if(!newCardIds.has(card.id)){
                            const {listId, position} = draft.cards.byId[card.id];
                            draft.cards.byId = _.omit(draft.cards.byId, card.id);
                            _.pull(draft.cards.allIds, card.id);
                            _.pull(draft.lists.byId[listId].cards, card.id);

                            // Adjust positions of remaining cards in the list
                            draft.lists.byId[listId].cards.forEach((remainingCardId) => {
                                const card = draft.cards.byId[remainingCardId];
                                if (card.position > position) {
                                    card.position -= 1;
                                }
                            });

                        }
                    })

                }
                if(!state.loading && action.payload){
                    // reset the array newCardInformation
                    draft.newCardInformation = [];
                }
            });
        }

        case SET_SPACE_ID: {
            return produce(state, (draft) => {
                draft.spaceId = action.payload;
            });
        }

        case UPDATE_QUERY: {
            return produce(state, (draft) => {
                draft.filter['query'] = action.payload;
            });
        }

        case SHOW_ARCHIVED: {
            return produce(state, (draft) => {
                draft.filter['showArchived'] = true;
            });
        }

        case HIDE_ARCHIVED: {
            return produce(state, (draft) => {
                draft.filter['showArchived'] = false;
            });
        }

        case UPDATE_LABELS: {
            return produce(state, (draft) => {
                draft.labels = action.payload;
            });
        }

        case SET_BOARD_ID: {
            // This also reset the board
            return produce(state, (draft) => {
                draft.boardId = action.payload;
                // Check if this board id is different
                if (state.boardId !== action.payload){
                    draft.cards.byId = {};
                    draft.cards.allIds = [];
                    draft.lists.byId = {};
                    draft.lists.allIds = [];
                    draft.members = [];
                }
            });
        }

        case ADD_UPDATE_CARDS: {
            const cardsToUpdate = action.payload;

            return produce(state, (draft) => {

                cardsToUpdate.forEach(card => {
                    draft.newCardInformation.push(card.id)

                    // Handle the card
                    if (state.cards.byId[card.id]) {
                        // Update existing card
                        draft.cards.byId[card.id] = { ...state.cards.byId[card.id], ...card };
                    } else {
                        // Add new card
                        draft.cards.byId[card.id] = card;
                        draft.cards.allIds.push(card.id);
                    }

                    // Ensure card is added to the correct list
                    const list = draft.lists.byId[card.listId];
                    if (list) {
                        if (!list.cards.includes(card.id)) {
                            list.cards.push(card.id);
                        }
                    }
                });
            });
        }

        case GET_BOARD: {

            const board = action.payload;

            return produce(state, (draft) => {

                // check if the current states boardId is different from this one
                if (state.boardId !== board.id){
                    draft.cards.byId = {};
                    draft.cards.allIds = [];
                }

                draft.labels = board.labels;
                draft.boardId = board.id;
                draft.lists.byId = objFromArray(board.lists);
                draft.lists.allIds = Object.keys(draft.lists.byId).map(id => parseInt(id));
                draft.members = board.members;
            });
        }

        case UPDATE_LIST: {
            const { list } = action.payload;
            return produce(state, (draft) => {
                // Update the list but with the current cards order (not per list serializer)
                draft.lists.byId[list.id] = {...list, ...{cards: [] }};  // FIXME: we do not need cards ... right?
                 // Check if the list ID exists in allIds, otherwise add it
                const index = _.indexOf(state.lists.allIds, list.id);
                if(index === -1){
                    draft.lists.allIds = state.lists.allIds.concat([list.id]);
                }
            });
        }

        case CLEAR_LIST: {
            const {listId} = action.payload;

            return produce(state, (draft) => {
                const {cards} = draft.lists.byId[listId];

                draft.lists.byId[listId].cards = [];
                draft.cards.byId = _.omit(draft.cards.byId, cards);
                _.pull(draft.cards.allIds, ...cards);
            });
        }

        case ARCHIVE_LIST:
            const {listId} = action.payload;

            return produce(state, (draft) => {
                for (let i = 0; i < state.lists.byId[listId].length; i++)
                    draft.lists.byId[listId][i].is_archived = true;
            });

        case DELETE_LIST: {
            const { listId } = action.payload;

            if (!state.lists.allIds.includes(listId))
                return state;

            return produce(state, (draft) => {
                const cardsToRemove = draft.lists.byId[listId]?.cards || [];

                // Remove each card and their IDs from the state
                cardsToRemove.forEach(cardId => {
                    delete draft.cards.byId[cardId];
                    const index = Object.values(draft.cards.allIds).indexOf(cardId);
                    if (index > -1) {
                        draft.cards.allIds.splice(index, 1)
                    }
                });

                // Once cards of the lists are removed from the list..we are now removing the list information from lists
                delete draft.lists.byId[listId];
                draft.lists.allIds = state.lists.allIds.filter(id => id !== listId);
            });
        }

        case CHANGE_MEMBERS: {
            const {card} = action.payload;

            return produce(state, (draft) => {
                draft.cards.byId[card.id]['members'] = card.members;
            });
        }

        case CHANGE_LABELS: {
            const {card} = action.payload;

            return produce(state, (draft) => {
                draft.cards.byId[card.id]['labels'] = card.labels;
            });
        }

        case CHANGE_ATTACHMENTS: {
            const {card} = action.payload;

            return produce(state, (draft) => {
                draft.cards.byId[card.id]['attachments'] = card.attachments;
            });
        }

        case UPDATE_CARD: {
            const {card} = action.payload;

            return produce(state, (draft) => {
                // _.merge(draft.cards.byId[card.id], card);
                draft.cards.byId[card.id] = card;

                // If the card id does not exist in allIds, add it
                if (!draft.cards.allIds.includes(card.id)) {
                    draft.cards.allIds.push(card.id);
                }
            });
        }

        case MOVE_CARD: {
            const {cardId, position, listId} = action.payload;

            return produce(state, (draft) => {
                const { listId: sourceListId, position: originalPosition } = draft.cards.byId[cardId];

                // Ensure sourceListId is valid
                if (!draft.lists.byId[sourceListId]) {
                    return;  // Exit if source list is undefined
                }

                // Moving within the same list
                if (listId === sourceListId) {
                    const cards = draft.lists.byId[sourceListId].cards;

                    if (position < originalPosition) {
                        // Moving up (higher index to lower index)
                        cards.forEach((otherCardId) => {
                            // Check if we're dealing with a card that is not the one being moved
                            if(otherCardId === cardId){
                                draft.cards.byId[otherCardId].position = position
                            }
                            if (otherCardId !== cardId &&
                                draft.cards.byId[otherCardId].position >= position &&
                                draft.cards.byId[otherCardId].position < originalPosition) {

                                // Increment the position of cards between the new and original position
                                draft.cards.byId[otherCardId].position += 1;
                            }
                        });

                        // Set the new position for the moved card
                        draft.cards.byId[cardId].position = position;
                    }
                    else if (position > originalPosition) {
                        // Moving down (lower index to higher index)
                        cards.forEach((otherCardId) => {
                            if (draft.cards.byId[otherCardId].position > originalPosition && draft.cards.byId[otherCardId].position <= position) {
                                draft.cards.byId[otherCardId].position -= 1;
                            }
                        });
                    }

                    // Update the moved card's position
                    draft.cards.byId[cardId].position = position;

                    // Reorder the cards array based on the updated positions
                    draft.lists.byId[sourceListId].cards.sort((a, b) => draft.cards.byId[a].position - draft.cards.byId[b].position);

                } else if (listId) { // Moving to a different list
                    // Remove card from source list
                    _.pull(draft.lists.byId[sourceListId].cards, cardId);

                    // Adjust positions in the source list
                    draft.lists.byId[sourceListId].cards.forEach((otherCardId) => {
                        if (draft.cards.byId[otherCardId].position > originalPosition) {
                            draft.cards.byId[otherCardId].position -= 1;
                        }
                    });

                    // Adjust positions in the new list
                    draft.lists.byId[listId].cards.forEach((otherCardId) => {
                        if (draft.cards.byId[otherCardId].position >= position) {
                            draft.cards.byId[otherCardId].position += 1;
                        }
                    });

                    // Update the card's listId and position
                    draft.cards.byId[cardId].listId = listId;
                    draft.cards.byId[cardId].position = position;

                    // Insert the card into the new list
                    draft.lists.byId[listId].cards.splice(position, 0, cardId);
                } else {
                    // If no listId is provided, it means adding the card back to its original list (shouldn't normally happen)
                    draft.lists.byId[sourceListId].cards.splice(position, 0, cardId);
                }
            });
        }

        case DELETE_CARD: {
            const { cardId } = action.payload;

            if (!state.cards.allIds.includes(cardId)){
                return state;
            }

            return produce(state, (draft) => {
                const { listId, position } = draft.cards.byId[cardId];
                draft.cards.byId = _.omit(draft.cards.byId, cardId);
                _.pull(draft.cards.allIds, cardId);
                _.pull(draft.lists.byId[listId].cards, cardId);

                // Adjust positions of remaining cards in the list
                draft.lists.byId[listId].cards.forEach((remainingCardId) => {
                    const card = draft.cards.byId[remainingCardId];
                    if (card.position > position) {
                        card.position -= 1;
                    }
                });
            });
        }

        case ADD_COMMENT: {

            return produce(state, (draft) => {
                draft.cards.byId[parseInt(action.payload.cardId)].comments.push(action.payload.comment);
            });

        }

        case ADD_CHECKLIST: {
            return state;
            // return produce(state, (draft) => {
            //   draft.cards.byId[cardId].checklists.push(checklist);
            // });
        }

        case UPDATE_CHECKLIST: {
            const {cardId, checklist} = action.payload;

            return produce(state, (draft) => {
                const card = draft.cards.byId[cardId];

                card.checklists = _.map(card.checklists, (_checklist) => {
                    if (_checklist.id === checklist.id) {
                        return checklist;
                    }

                    return _checklist;
                });
            });
        }

        case DELETE_CHECKLIST: {
            const {cardId, checklistId} = action.payload;

            return produce(state, (draft) => {
                const card = draft.cards.byId[cardId];

                card.checklists = _.reject(card.checklists, {id: checklistId});
            });
        }

        case UPDATE_CHECK_ITEM: {
            const {
                cardId,
                checklistId,
                checkItemId,
                checkItem
            } = action.payload;

            return produce(state, (draft) => {
                const card = draft.cards.byId[cardId];

                card.checklists = _.map(card.checklists, (checklist) => {
                    if (checklist.id === checklistId) {
                        _.assign(checklist, {
                            checkItems: _.map(checklist.checkItems, (_checkItem) => {
                                if (_checkItem.id === checkItemId) {
                                    return checkItem;
                                }

                                return _checkItem;
                            })
                        });
                    }

                    return checklist;
                });
            });
        }

        case DELETE_CHECK_ITEM: {
            const {cardId, checklistId, checkItemId} = action.payload;

            return produce(state, (draft) => {
                const card = draft.cards.byId[cardId];

                card.checklists = _.map(card.checklists, (checklist) => {
                    if (checklist.id === checklistId) {
                        _.assign(checklist, {
                            checkItems: _.reject(checklist.checkItems, {id: checkItemId})
                        });
                    }

                    return checklist;
                });
            });
        }

        default: {
            return state;
        }
    }

};

export default kanbanReducer;
