export const SET_TASK_QUERY = '@groon/control/set-task-query';
export const SET_ONLY_ACTIVE = '@groon/control/set-only-active';
export const SET_SERVERS = '@groon/control/set-servers';
export const UPDATE_SERVER = '@groon/control/update-server';
export const DELETE_SERVER = '@groon/control/delete-server';
export const SET_WORKLOADS = '@groon/control/set-workloads';
export const UPDATE_WORKLOAD = '@groon/control/update-workload';
export const DELETE_WORKLOAD = '@groon/control/delete-workload';
export const SET_WORKERS = '@groon/control/set-tasks';
export const SET_QUEUES = '@groon/control/set-queues';
export const UPDATE_QUEUE = '@groon/control/update-queue';
export const UDPATE_WORKER = '@groon/control/update-worker';
export const UDPATE_WORKER_LOG = '@groon/control/update-worker-log';
export const DELETE_WORKER = '@groon/control/worker-delete';
export const DELETE_QUEUE = '@groon/control/delete-queue';
export const UPDATE_REQUEST_COUNT = '@groon/control/update-request-count';


export function updateRequestCount(count){
    return dispatch => dispatch({
        type: UPDATE_REQUEST_COUNT,
        payload: count
    })
}


export function deleteQueue(queueId){
    return dispatch => dispatch({
        type: DELETE_QUEUE,
        payload: queueId
    })
}


export function deleteWorker(workerId){
    return dispatch => dispatch({
        type: DELETE_WORKER,
        payload: workerId
    })
}


export function setQueues(queues){
    return dispatch => dispatch({
        type: SET_QUEUES,
        payload: queues
    })
}

export function updateQueue(queue){
    return dispatch => dispatch({
        type: UPDATE_QUEUE,
        payload: queue
    })
}


export function recordWorkerLog(data){
    return dispatch => dispatch({
        type: UDPATE_WORKER_LOG,
        payload: data
    })
}

export function setWorkers(workers){
    return dispatch => dispatch({
        type: SET_WORKERS,
        payload: workers
    })
}

export function updateWorker(worker){
    return dispatch => dispatch({
        type: UDPATE_WORKER,
        payload: worker
    })
}

export function deleteWorkload(workloadId){
    return dispatch => dispatch({
        type: DELETE_WORKLOAD,
        payload: workloadId
    })
}

export function updateWorkload(workload){
    return dispatch => dispatch({
        type: UPDATE_WORKLOAD,
        payload: workload
    })
}

export function deleteServer(serverId){
    return dispatch => dispatch({
        type: DELETE_SERVER,
        payload: serverId
    })
}

export function setTaskQuery(query){
    return dispatch => dispatch({
        type: SET_TASK_QUERY,
        payload: query
    })
}

export function updateServer(server){
    return dispatch => dispatch({
        type: UPDATE_SERVER,
        payload: server
    })
}

export function setOnlyActive(onlyActive){
    return dispatch => dispatch({
        type: SET_ONLY_ACTIVE,
        payload: onlyActive
    })
}

export function setServers(servers){
    return dispatch => dispatch({
        type: SET_SERVERS,
        payload: servers
    })
}

export function setWorkloads(workloads){
    return dispatch => dispatch({
        type: SET_WORKLOADS,
        payload: workloads
    })
}