export const useCreateSrc = () => {

    return (url, isUser=false) => {
        let downloadUrl = null;
        if(typeof(url) !== "undefined" && url !== null){
            if(typeof(url) === "string"){
                downloadUrl = url;
            } else if(typeof(url) === "object"){
                if(Object.keys(url).includes('view') && typeof(url['view']) === "string"){
                    downloadUrl = url.view;
                }
            }
        } else {
            if(isUser){
                downloadUrl = null;
            } else {
                downloadUrl = null;
            }
        }
        return downloadUrl;
    };
}