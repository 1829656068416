export const ADD_TRANSACTIONS_INITIAL = '@transactions/add-transactions-initial';
export const ADD_ACCOUNTS_INITIAL = '@transactions/add-accounts-initial';
export const ADD_TRANSACTION = '@transactions/add-transaction';
export const REMOVE_TRANSACTION = '@transactions/remove-transaction';
export const UPDATE_TRANSACTION = '@transactions/update-transaction';
export const UPDATE_ACCOUNT = '@transactions/update-account';
export const ADD_ACCOUNT = '@transactions/add-account';
export const REMOVE_ACCOUNT = '@transactions/remove-account';


export function loadAccounts(accounts) {
    return (dispatch) => dispatch({
        type: ADD_ACCOUNTS_INITIAL,
        payload: {
            accounts: accounts,
        },
    });
}

export function loadTransactions(transactions) {
    return (dispatch) => dispatch({
        type: ADD_TRANSACTIONS_INITIAL,
        payload: {
            transactions: transactions,
        },
    });
}

export function updateTransaction( transaction, update ) {
    return (dispatch) => dispatch({
        type: UPDATE_TRANSACTION,
        payload: {
            transaction,
            update
        }
    })
}

export function updateAccount( account, update ){
    return (dispatch) => dispatch({
        type: UPDATE_ACCOUNT,
        payload: {
            account,
            update
        }
    })
}

export function addAccount( account ) {
    return (dispatch) => dispatch({
        type: ADD_ACCOUNT,
        payload: {
            account
        }
    })
}

export function addTransaction( transaction ) {
    return (dispatch) => dispatch({
        type: ADD_TRANSACTION,
        payload: {
            transaction
        }
    })
}

export function removeTransaction( transactionId ) {
    return (dispatch) => dispatch({
        type: REMOVE_TRANSACTION,
        payload: {
            transactionId
        }
    })
}

export function removeAccount( accountId ) {
    return (dispatch) => dispatch({
        type: REMOVE_ACCOUNT,
        payload: {
            accountId
        }
    })
}