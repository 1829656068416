import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Navigate} from 'react-router';
import PropTypes from 'prop-types';
import {useAppMode} from "../../../hooks/use-app-mode";

function AuthGuard({children}) {
    const user = useSelector((state) => state.account.user);
    const appMode = useAppMode();

    useEffect(() => {
        appMode.setIsService(false);
        appMode.setIsBackend(true);
    }, []);

    if (!user) {
        return <Navigate to="/login"/>;
    } else {
        return children;
    }
}

AuthGuard.propTypes = {
    children: PropTypes.any
};

export default AuthGuard;
