export const UPDATE_SHOW_FILTERS = '@invoices/update-show-filters';
export const UPDATE_SEARCH_STATE = '@invoices/update-search-state';

export function setShowFilters(showFilters) {
    return (dispatch) => dispatch({
        type: UPDATE_SHOW_FILTERS,
        payload: {
            showFilters
        }
    })
}

export function setSearchState(searchState) {
    return (dispatch) => dispatch({
        type: UPDATE_SEARCH_STATE,
        payload: {
            searchState
        }
    })
}