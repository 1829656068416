import { common } from "@mui/material/colors";
import { alpha } from "@mui/material/styles";
import { neutral } from "../colors";
import adjustLuminanceToTarget from "../../utils/adjust-color-luminence";

export const createPalette = (config) => {
    const { contrast } = config;

    return {
        action: {
            active: neutral[500],
            disabled: alpha(neutral[900], 0.38),
            disabledBackground: alpha(neutral[900], 0.12),
            focus: alpha(neutral[900], 0.16),
            hover: alpha(neutral[900], 0.04),
            selected: alpha(neutral[900], 0.12)
        },
        background: {
            default: contrast === "high" ? (config?.light_default_contrast || '#f9f9f9') : (config?.light_default_normal || '#ffffff'),
            paper: config?.light_paper || '#ffffff',
            discrete: config?.light_discrete || '#f9f9f9',
            evident: config?.light_evident || '#2c2c27',
        },

        divider: config?.light_divider || '#F2F4F7',

        info: {
            lightest: config?.info_color_lightest || '#ECFDFF',
            light: config?.info_color_light || '#CFF9FE',
            main: config?.info_color_main || '#06AED4',
            dark: config?.info_color_dark || '#0E7090',
            darkest: config?.info_color_darkest || '#164C63',
            contrastText: config?.info_color_contrast_text || '#FFFFFF',
        },
        error: {
            lightest: config?.error_color_lightest || '#FEF3F2',
            light: config?.error_color_light || '#FEE4E2',
            main: config?.error_color_main || '#F04438',
            dark: config?.error_color_dark || '#B42318',
            darkest: config?.error_color_darkest || '#7A271A',
            contrastText: config?.error_color_contrast_text || '#FFFFFF',
        },
        success: {
            lightest: config?.success_color_lightest || '#F0FDF9',
            light: config?.success_color_light || '#3FC79A',
            main: config?.success_color_main || '#10B981',
            dark: config?.success_color_dark || '#0B815A',
            darkest: config?.success_color_darkest || '#134E48',
            contrastText: config?.success_color_contrast_text || '#FFFFFF',
        },
        warning: {
            lightest: config?.warning_color_lightest || '#FFFAEB',
            light: config?.warning_color_light || '#FEF0C7',
            main: config?.warning_color_main || '#F79009',
            dark: config?.warning_color_dark || '#B54708',
            darkest: config?.warning_color_darkest || '#7A2E0E',
            contrastText: config?.warning_color_contrast_text || '#FFFFFF',
        },
        primary: {
            lightest: config?.primary_color_lightest || '#97978b',
            light: config?.primary_color_light || '#6e6e63',
            main: config?.primary_color_main || '#5b5b50',
            dark: config?.primary_color_dark || '#3c3c32',
            darkest: config?.primary_color_darkest || '#1c1c12',
            contrastText: config?.primary_color_contrast_text || '#FFFFFF',
        },
        secondary: {
            lightest: config?.secondary_color_lightest || '#eaeadd',
            light: config?.secondary_color_light || '#dbdbce',
            main: config?.secondary_color_main || '#b7b7aa',
            dark: config?.secondary_color_dark || '#97978b',
            darkest: config?.secondary_color_darkest || '#6f6f63',
            contrastText: config?.secondary_color_contrast_text || '#090909',
        },
        accent: {
            lightest: config?.accent_color_lightest || '#e6e159',
            light: config?.accent_color_light || '#e3dc3e',
            main: config?.accent_color_main || '#e1ca37',
            dark: config?.accent_color_dark || '#dfb42d',
            darkest: config?.accent_color_darkest || '#dc9d23',
            contrastText: config?.accent_color_contrast_text || '#090909',
        },

        neutral,
        mode: "light",
        text: {
            primary: config?.light_primary_text || '#0D0D0D',
            secondary: config?.light_secondary_text || '#7F7F7F',
            disabled: alpha(config?.light_primary_text || '#0D0D0D', 0.38)
        },
    };
};
