/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
import produce from 'immer';
import {
    UPDATE_RIGHTS,
    UPDATE_OPTIONS,
    REMOVE_RIGHTS,
    ADD_RIGHT,
    ADD_REGISTRATIONS,
    UPDATE_REGISTRATION
} from 'src/omnia/store/actions/rights-actions';
import _ from "lodash";

const initialState = {
    // list of rights objects
    rights: [],
    // list of rights options
    options: [],
    // list of job registrations
    jobRegistrations: [],
    loadingJobs: true
};

const rightsReducer = (state = initialState, action) => {

    let tmp = null;

    switch (action.type) {

        case ADD_REGISTRATIONS:
            const { registrations } = action.payload;
            return produce(state, (draft) => {
                draft.loadingJobs = false;
                draft.jobRegistrations = registrations;
            })

        case UPDATE_REGISTRATION:
            const { registration } = action.payload;
            return produce(state, (draft) => {
                let index = _.findIndex(state.jobRegistrations, {id: registration.id});
                if( index !== -1 ){
                    let key = "";
                    for(let i = 0; i < Object.keys(registration).length; i++){
                        key = Object.keys(registration)[i];
                        draft.jobRegistrations[index][key] = registration[key];
                    }
                }
            })

        case UPDATE_OPTIONS: {
            return produce(state, (draft) => {
                // Ensure we clear current session
                draft.options = action.payload;
            });
        }

        case UPDATE_RIGHTS: {
            return produce(state, (draft) => {
                // Ensure we clear current session
                draft.rights = action.payload;
            });
        }

        case ADD_RIGHT: {
            if(state.rights.filter(r => r.id === action.payload.id).length === 0){
                return produce(state, draft => {
                    draft.rights = state.rights.concat([action.payload]);
                })
            } else {
                return produce(state, draft => {
                    tmp = state.rights.indexOf(state.rights.find(r => r.id === action.payload.id));
                    draft.rights[tmp] = action.payload;
                })
            }
        }

        case REMOVE_RIGHTS: {
            return produce(state, draft => {
                draft.rights = state.rights.filter(r => !action.payload.includes(r.id));
            });
        }

        default: {
            return state;
        }
    }
};

export default rightsReducer;
