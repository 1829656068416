import React, { useState, useRef, useEffect } from 'react';
import {InputBase, Stack, Typography, useTheme} from '@mui/material';
import PropTypes from 'prop-types';

const InlineEditInput = ({ prefix, prefixColor, prefixVariant, name, placeholder, value, onChange, defaultEditing, fullWidth, variant, sx, ...props }) => {
    const [editing, setEditing] = useState(defaultEditing);
    const [show, setShow] = useState(true);
    const inputRef = useRef(null);
    const theme = useTheme();

    const handleInputClick = () => {
        if (!editing) {
            setEditing(true);
        }
    };

    const handleChange = () => {
        if (onChange) {
            onChange(inputRef.current.value);
        }
    }

    useEffect(() => {
        if (editing) {
            inputRef.current.focus();
            // Move cursor to the end of the text
            const length = inputRef.current.value.length;
            inputRef.current.setSelectionRange(length, length);
        }
    }, [editing]);

    const handleInputBlur = () => {
        setEditing(false);
        if (onChange) {
            onChange(inputRef.current.value);
        }
    };

    // Extract typography styles for the given variant
    const typographyStyles = theme.typography[variant];

    if(!show)
        return null;

    return (
        <Stack
            direction="row"
            alignItems="center"
            spacing={1}
        >
            {prefix && (
                <Typography variant={prefixVariant || variant} sx={typographyStyles} color={prefixColor}>
                    {prefix}
                </Typography>
            )}
            <InputBase
                inputRef={inputRef}
                name={name}
                fullWidth={fullWidth}
                value={value}
                placeholder={placeholder}
                readOnly={!editing}
                onBlur={handleInputBlur}
                onChange={handleChange}
                onClick={handleInputClick}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        inputRef.current.blur();
                    }
                }}
                sx={{
                    ...{
                        ...typographyStyles,
                        padding: 0,
                        margin: 0,
                        lineHeight: 'inherit',
                        cursor: editing ? 'text' : 'default',
                        height: 'auto',
                    },
                    ...sx
                }}
                inputProps={{
                    style: {
                        padding: 0,
                        margin: 0,
                        height: 'auto',
                        color: 'text.secondary'
                    },
                }}
                {...props}
            />
        </Stack>
    );
};

InlineEditInput.defaultProps = {
    variant: 'body1',
    sx: {},
    prefixVariant: null,
    prefix: '',
    prefixColor: 'text.primary',
    defaultEditing: false,
    fullWidth: true,
    placeholder: '',
};

InlineEditInput.propTypes = {
    value: PropTypes.string,
    sx: PropTypes.object,
    placeholder: PropTypes.string,
    name: PropTypes.string,
    prefixVariant: PropTypes.string,
    prefixColor: PropTypes.string,
    prefix: PropTypes.string,
    fullWidth: PropTypes.bool,
    defaultEditing: PropTypes.bool,
    onChange: PropTypes.func,
    variant: PropTypes.string,
};

export default InlineEditInput;
