export const UPDATE_GROUPS = '@userGroups/update-groups';
export const UPDATE_GROUP = '@userGroups/update-group';
export const ADD_GROUP = '@userGroups/add-group';
export const REMOVE_GROUPS = '@userGroups/delete-groups';

export function storeUserGroups(groups) {
    return (dispatch) => dispatch({
        type: UPDATE_GROUPS,
        payload: groups
    });
}

export function addUserGroup(group) {
    // dispatch reducer action
    return (dispatch) => dispatch({
        type: ADD_GROUP,
        payload: group
    });
}

export function updateUserGroup(data) {
    return (dispatch) => dispatch({
        type: UPDATE_GROUP,
        payload: data
    });
}

export function removeUserGroups(deletedGroups) {
    // dispatch reducer action
    return (dispatch) => dispatch({
        type: REMOVE_GROUPS,
        payload: deletedGroups
    });
}