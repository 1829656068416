import { useContext, useMemo } from "react";
import { AppModeContext } from 'src/omnia/context/app-mode-context';
import { APP_SETTING } from "../../setup";

export const useAppMode = () => {
    const context = useContext(AppModeContext);

    if (context === undefined) {
        throw new Error('useAppMode must be used within a AppModeProvider');
    }

    const serviceDefinition = useMemo(() => {
        return APP_SETTING?.services?.find(s => s.identifier === context?.service?.service_identifier) || null;
    }, [context?.service?.service_identifier, APP_SETTING?.services]);

    return useMemo(() => ({
        ...context,
        path: serviceDefinition?.register_redirect || null
    }), [context, serviceDefinition]);
};
