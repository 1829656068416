/* eslint-disable react/no-array-index-key */
import { mergeRoutes } from "src/omnia/routes-composer";
import React, { lazy, Suspense } from "react";
import AuthGuard from "./omnia/components/general/auth/AuthGuard";
import { Layout as OmniaLayout } from "./omnia/components/layouts/dashboard/index";
import LoadingScreen from "./omnia/components/general/loading-screen";
import { Outlet } from "react-router";

const DemoComponent = lazy(() => import("src/views/demo-view"));

export const getRoutes = () => {
  return mergeRoutes([
    {
      path: "/demo",
      element: (
        <AuthGuard>
          <OmniaLayout>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </OmniaLayout>
        </AuthGuard>
      ),
      children: [
        {
          path: "/demo/crm/client-profiles",
          element: <DemoComponent />
        },
        {
          path: "/demo/crm/communication-tracking",
          element: <DemoComponent />
        },
        {
          path: "/demo/crm/service-history",
          element: <DemoComponent />
        },
        {
          path: "/demo/crm/feedback-analysis",
          element: <DemoComponent />
        },
        {
          path: "/demo/pm/task-assignment",
          element: <DemoComponent />
        },
        {
          path: "/demo/pm/progress-tracking",
          element: <DemoComponent />
        },
        {
          path: "/demo/pm/milestone-reporting",
          element: <DemoComponent />
        },
        {
          path: "/demo/pm/resource-allocation",
          element: <DemoComponent />
        },
        {
          path: "/demo/financial/invoice-generation",
          element: <DemoComponent />
        },
        {
          path: "/demo/financial/expense-tracking",
          element: <DemoComponent />
        },
        {
          path: "/demo/financial/budget-forecasting",
          element: <DemoComponent />
        },
        {
          path: "/demo/financial/revenue-analysis",
          element: <DemoComponent />
        },
        {
          path: "/demo/marketing/campaign-planning",
          element: <DemoComponent />
        },
        {
          path: "/demo/marketing/social-media-integration",
          element: <DemoComponent />
        },
        {
          path: "/demo/marketing/lead-generation",
          element: <DemoComponent />
        },
        {
          path: "/demo/marketing/analytics-dashboard",
          element: <DemoComponent />
        },
        {
          path: "/demo/employee-hub/personal-information",
          element: <DemoComponent />
        },
        {
          path: "/demo/employee-hub/performance-reviews",
          element: <DemoComponent />
        },
        {
          path: "/demo/employee-hub/training-modules",
          element: <DemoComponent />
        },
        {
          path: "/demo/employee-hub/leave-management",
          element: <DemoComponent />
        }
      ]
    }
  ])
};