import {useEffect, useState} from "react";
import {APP_SETTING} from "../../setup";

function useGenerateVisibility(count, delay){

    const [visible, setVisible] = useState(new Array(count || 0).fill(false));

    useEffect(() => {
        const elements = new Array(count || 0).fill(false);
        const timeouts = elements.map((_, index) =>
            setTimeout(() => {
                setVisible(prevVisible => {
                    const newVisible = [...prevVisible];
                    newVisible[index] = true;
                    return newVisible;
                });
            }, ((delay ? delay : 25) * index))
        );

        return () => timeouts.forEach(clearTimeout);
    }, [count]);

    return {
        visible
    }
}

useGenerateVisibility.defaultProps = {
    delay: 25
}

export default useGenerateVisibility;