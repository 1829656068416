import React from "react";
import {IconButton, Tooltip} from '@mui/material';
import {useTranslation} from "react-i18next";
import OnIcon from "../../../elements/icon";
import {useIsMobile} from "../../../../hooks/use-is-mobile";
import {useNavigate} from "react-router-dom";

export const WorkflowButton = () => {
    const { isFlying } = useIsMobile();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleOpen = () => {
        navigate('/groon/workflows')
    }

    return (
        <Tooltip enterDelay={1000} title={t("core.open_workflows")} placement={isFlying ? 'left' : 'bottom'}>
            <IconButton onClick={handleOpen}>
                <OnIcon iconName="Route" />
            </IconButton>
        </Tooltip>
    )

};
