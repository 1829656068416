export const createShadows = () => {
  return [
    'none',
    '0px 0px 10px rgba(255, 255, 255, 0.14)',
    '0px 0px 10px rgba(255, 255, 255, 0.14)',
    '0px 0px 20px rgba(255, 255, 255, 0.14)',
    '0px 0px 25px rgba(255, 255, 255, 0.14)',
    '0px 0px 30px rgba(255, 255, 255, 0.14)',
    '0px 0px 30px rgba(255, 255, 255, 0.14)',
    '0px 0px 30px rgba(255, 255, 255, 0.14)',
    '0px 0px 30px rgba(255, 255, 255, 0.14)',
    '0px 0px 60px rgba(255, 255, 255, 0.14)',
    '0px 0px 70px rgba(255, 255, 255, 0.14)',
    '0px 0px 75px rgba(255, 255, 255, 0.14)',
    '0px 0px 75px rgba(255, 255, 255, 0.14)',
    '0px 0px 75px rgba(255, 255, 255, 0.14)',
    '0px 0px 75px rgba(255, 255, 255, 0.14)',
    '0px 0px 75px rgba(255, 255, 255, 0.14)',
    '0px 0px 75px rgba(255, 255, 255, 0.14)',
    '0px 0px 110px rgba(255, 255, 255, 0.14)',
    '0px 0px 110px rgba(255, 255, 255, 0.14)',
    '0px 0px 120px rgba(255, 255, 255, 0.14)',
    '0px 0px 125px rgba(255, 255, 255, 0.14)',
    '0px 0px 250px rgba(255, 255, 255, 0.14)',
    '0px 0px 250px rgba(255, 255, 255, 0.14)',
    '0px 0px 250px rgba(255, 255, 255, 0.14)',
    '0px 0px 250px rgba(255, 255, 255, 0.14)'
  ];
};
