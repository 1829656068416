import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from "prop-types";
import {Box} from "@mui/material";

const DelayedCircularProgress = ({ delay = 1000, ...props }) => {
    const [showSpinner, setShowSpinner] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowSpinner(true);
        }, delay);

        return () => clearTimeout(timer);
    }, [delay]);

    return showSpinner ? <CircularProgress {...props} /> : <Box />;
};

DelayedCircularProgress.propTypes = {
    delay: PropTypes.number,
    props: PropTypes.object
}

export default DelayedCircularProgress;