import { useEffect } from 'react';
import { useLocation } from 'react-router';
import {useDispatch} from "react-redux";
import {setScrollPositionTop} from "src/omnia/store/actions/client-actions";

function ScrollReset() {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(setScrollPositionTop());
  }, [location.pathname]);

  return null;
}

export default ScrollReset;
