import useApi from "./use-api";
import {useNotifications} from "./use-notifications";

const UseOmniaApi = ({ autoError = true, withAuthorization = true, authorizationHeader = null, fileFields = ['file', 'files'] } = {}) => {
    /**
     * This is a hook around the Omnia API
     *
     * Use this hook for simple access to the Omnia API. You have a few options to pass in:
     *
     * @param {boolean} autoError - If true, the hook will automatically show an error message if the API call fails
     * @param {boolean} withAuthorization - If true, the hook will automatically add the authorization header to the request
     * @param {string} authorizationHeader - If set, the hook will use this authorization header instead of the users token
     */

    const { notify } = useNotifications();

    const { get, post, del, put, isUploading, uploadStatus } = useApi(autoError, () => {}, withAuthorization, authorizationHeader, fileFields);

    const flashErrors = (errors) => {
        /**
         * This method will flash all errors to the user
         */
        for(let key in errors){
            notify(errors[key].message, 'error');
        }
    }

    return {
        get, post, del, put, flashErrors, isUploading, uploadStatus
    }
}

export default UseOmniaApi;