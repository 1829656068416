import PropTypes from 'prop-types';
import {Stack, Box, Typography, Fade} from '@mui/material';
import moment from "moment/moment";
import ChatMessageDistributer from "./chat-message-distributer";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import useGenerateVisibility from "../../../../../hooks/use-generate-visibility";
import {setIn} from "formik";
import {APP_SETTING} from "../../../../../../setup";

export const ChatMessages = (props) => {

    const {messages, thread, typing, onLoadedContent, smallVersion, ...other} = props;
    const { t } = useTranslation();
    const [ initial, setInitial ] = useState(true);
    const { visible } = useGenerateVisibility(messages?.length, 25);

    const handleLoaded = () => {
        onLoadedContent();
    }

    useEffect(() => {
        setTimeout(() => {
            setInitial(false);
        }, 2000);
    }, []);

    return (
        <Stack
            id="ChatMessagesStack"
            spacing={0.3}
            sx={{p: 3}}
            {...other}
        >
            {messages.map((message, index) => {

                return (
                    <Fade in={initial ? visible[index] : true} timeout={APP_SETTING?.transitionDuration || 500} key={message.id}>
                        <Box>
                            <ChatMessageDistributer
                                message={message}
                                key={message.id}
                                thread={thread}

                                // TODO: make this real
                                isChannelMessage={false}
                                smallVersion={smallVersion}
                                handleLoaded={handleLoaded}
                                lastUserMessage={
                                    (index === messages.length - 1) ||
                                    (
                                        (messages[index].sender.id !== messages[index + 1].sender.id) ||
                                        (
                                            (((new Date(messages[index + 1].created_at)) - (new Date(messages[index].created_at))) > 300000) &&
                                            ((moment(messages[index + 1].created_at).fromNow()) !== (moment(messages[index].created_at).fromNow()))
                                        )
                                    )
                                }
                                firstUserMessage={
                                    (index === 0) ||
                                    (
                                        (messages[index].sender.id !== messages[index - 1].sender.id) ||
                                        (
                                            (((new Date(messages[index].created_at)) - (new Date(messages[index - 1].created_at))) > 300000) &&
                                            ((moment(messages[index].created_at).fromNow()) !== (moment(messages[index - 1].created_at).fromNow()))
                                        )
                                    )
                                }
                            />
                        </Box>
                    </Fade>
                );
            })}

            {typing.map(user => (
                <Box pl={1}>
                    <Typography variant="caption">
                        {t('core.is_typing', {name: user.name})}
                    </Typography>
                </Box>
            ))}

        </Stack>
    );
};

ChatMessages.propTypes = {
    messages: PropTypes.array,
    thread: PropTypes.object,
    typing: PropTypes.array,
    onLoadedContent: PropTypes.func,
    smallVersion: PropTypes.bool,
    participants: PropTypes.array,
};
