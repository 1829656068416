import React, {useState, useCallback, useEffect} from 'react';
import {Autocomplete, TextField, Divider, Box, Typography} from '@mui/material';
import debounce from 'lodash.debounce';
import useApi from '../../hooks/use-api';
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";

function MultiSectionAutocomplete({ config, placeholder, onSelect }) {
    const { t } = useTranslation();
    const [inputValue, setInputValue] = useState('');
    const [rawOptions, setRawOptions] = useState({});
    const [options, setOptions] = useState([]);
    const { get } = useApi();

    const handleQueryChange = (value) => {
        config.forEach((c) => {
            get(c.endpoint, {
                ...{page: 1, size: 20},
                ...(value ? {search: value} : {}),
                ...(c?.query || {})
            }).then((response) => {
                setRawOptions((prev) => {
                    return {
                        ...prev,
                        [c.kind]: response.results
                    };
                })
            })
        });
    };

    const debouncedChangeHandler = useCallback(debounce(handleQueryChange, 500), []);

    const handleInputChange = (event, value, reason) => {

        if(reason !== 'reset'){
            setInputValue(value);
            // Trigger the debounced handler
            debouncedChangeHandler(value);
        } else {
            debouncedChangeHandler();
        }

    };

    const handleSelect = (event, value, reason) => {
        if (reason === 'selectOption' && value) {
            const { kind, ...entity } = value;
            onSelect(kind, entity);
            // Clear the input after selection
            setInputValue('');
        }
    };

    useEffect(() => {
        let aggregatedOptions = [];
        config.forEach((c) => {
            if (typeof rawOptions?.[c.kind] !== "undefined") {
                aggregatedOptions = [
                    ...aggregatedOptions,
                    ...rawOptions[c.kind].map((o) => {
                        return {
                            ...o,
                            groupLabel: c.label,
                            kind: c.kind
                        }
                    })
                ];
            }
        });
        setOptions(aggregatedOptions.sort((a, b) => {
            const aName = a?.name || a?.first_name || a?.title;
            const bName = b?.name || b?.first_name || b?.title;

            // Compare by `kind` first
            const kindComparison = a.kind.localeCompare(b.kind);
            if (kindComparison !== 0) {
                return kindComparison;
            }

            // If `kind` is the same, compare by `name` (or equivalent)
            return aName.localeCompare(bName);
        }));
    }, [rawOptions]);

    useEffect(() => {
        handleQueryChange();
    }, []);

    return (
        <Autocomplete
            multiple={false}
            options={options}
            groupBy={(option) => option.groupLabel}
            getOptionLabel={(option) => option.name || 'Unknown'}
            value={null}
            inputValue={inputValue}
            onInputChange={handleInputChange}
            onChange={handleSelect}
            filterOptions={(x) => x}
            renderGroup={(params) => (
                <Box key={params.group}>
                    {params.group !== options[0]?.groupLabel && <Divider />}
                    <Typography variant="overline" sx={{px: 2}}>
                        {params.group}
                    </Typography>
                    {params.children}
                </Box>
            )}
            renderInput={(params) => (
                <TextField {...params} label={placeholder || t('common.search')} />
            )}
        />
    );
}

MultiSectionAutocomplete.propTypes = {
    config: PropTypes.array.isRequired,
    onSelect: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
}

export default MultiSectionAutocomplete;
