import React, {useEffect, useState} from "react";
import { Popover } from "@mui/material";
import { HexColorPicker } from "react-colorful";
import { default as AdvancedColorPicker} from "react-color";
import PropTypes from "prop-types";
import {useTheme} from "@mui/system";
import chroma from 'chroma-js';

function ColorPicker({color, opacity, onChange, withHex, disabled, style, size, label, noBorder, ...rest}){

    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleOpen = (event) => {
        if(!disabled){
            setAnchorEl(event.currentTarget);
            setOpen(true);
        }
    }

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
    }

    const handleColorUpdate = (color) => {
        if(onChange)
            onChange(color);
    }

    const handleAdvancedColorUpdate = (data) => {
        if(onChange)
            onChange(data.hex);
    }

    let colorToDisplay;
    try {
        colorToDisplay = chroma(color).alpha(parseFloat(opacity / 100)).css();
    } catch (e) {
        colorToDisplay = color;
    }

    const lightFont = "#FFFFFF";
    const darkFont = "#090909";

    let ct = darkFont;
    try {
        ct = chroma.deltaE(colorToDisplay, lightFont) > chroma.deltaE(colorToDisplay, darkFont) ? lightFont : darkFont;
    } catch (e) {
        console.log('Color Picker error: ' + e)
    }

    return (
        <>
            <div
                onClick={handleOpen}
                style={{...{
                    height: size === 'small' ? '40px' : (size === 'medium' ? '45px' : '50px'),
                    cursor: disabled ? '' : 'pointer',
                    borderStyle: noBorder ? 'none' : 'solid',
                    borderWidth: noBorder ? 0 : 1,
                    padding: noBorder ? 0 : theme.spacing(0.25),
                    borderRadius: 8,
                    borderColor: noBorder ? null : theme.palette.action.disabledBackground
                }, ...style}}
                {...rest}
            >
                <div
                    style={{
                        backgroundColor: colorToDisplay,
                        color: ct,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: noBorder ? 0 : theme.spacing(1)
                    }}
                >
                    {label}
                </div>
            </div>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                {withHex ? (
                    <AdvancedColorPicker
                        color={color ? color : '#FFFFFF'}
                        defaultView="hex"
                        onChange={handleAdvancedColorUpdate}
                    />
                ) : (
                    <HexColorPicker
                        color={color ? color : '#FFFFFF'}
                        onChange={handleColorUpdate}
                    />
                )}
            </Popover>
        </>
    )

}

ColorPicker.propTypes = {
    color: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    style: PropTypes.object,
    withHex: PropTypes.bool,
    noBorder: PropTypes.bool,
    opacity: PropTypes.number,
    disabled: PropTypes.bool,
}

ColorPicker.defaultProps = {
    withHex: false,
    size: 'medium',
    disabled: false,
    label: null,
    noBorder: false,
    opacity: 100,
    style: {}
}

export default ColorPicker;