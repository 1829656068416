import React, {useState} from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Divider,
    List,
    ListItem, ListItemText,
    Stack,
    Typography
} from "@mui/material";
import {useIsMobile} from "../../../../hooks/use-is-mobile";
import {useTranslation} from "react-i18next";

function ActionDetailsDialog({open, onClose, actions}){
    const { t } = useTranslation();
    const { isMobile } = useIsMobile();
    const [ showSteps, setShowSteps ] = useState(false);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="sm"
            fullScreen={isMobile}
        >
            <DialogTitle>
                <Typography variant="h4">
                    {t('dialogs.show_procedure_details.title')}
                </Typography>
                <Typography variant="subtitle1" sx={{mt: 2}}>
                    {t('dialogs.show_procedure_details.description')}
                </Typography>
            </DialogTitle>
            <DialogContent style={{paddingLeft: 0, paddingRight: 0}}>
                <Stack spacing={2}>
                    {actions?.map((action, index) => (
                        <>
                            <Typography variant="h6" px={2}>
                                {action?.kind === 'thought_process' ? t('intelligence.assistants.thought_process') : t('intelligence.assistants.action')}
                            </Typography>
                            <Divider />
                            <Typography variant="overline" sx={{fontWeight: '700'}} px={2}>
                                Request
                            </Typography>
                            <Typography variant="body1" px={2}>
                                {action?.request}
                            </Typography>
                            <Divider />
                            <Typography variant="overline" sx={{fontWeight: '700'}} px={2}>
                                Response
                            </Typography>
                            <Typography variant="body1" px={2}>
                                {action?.response || 'Kein Ergebnis'}
                            </Typography>
                            <Divider />
                            {showSteps && (
                                <Typography variant="overline" sx={{fontWeight: '700'}} px={2}>
                                    Schritte
                                </Typography>
                            )}
                            {showSteps && (
                                <List>
                                    {action?.steps?.map((step, index) => (
                                        <ListItem
                                            key={'step-' + step.id}
                                        >
                                            <ListItemText
                                                primary={step.title}
                                                primaryTypographyProps={{variant: 'subtitle1'}}
                                                secondary={step.content}
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            )}
                        </>
                    ))}
                </Stack>
            </DialogContent>
            <DialogActions>
                <Stack direction="row" justifyContent="space-between" sx={{width: '100%'}}>
                    <Button variant="outlined" onClick={onClose}>
                        {t('common.close')}
                    </Button>
                    <Button variant="contained" onClick={() => setShowSteps(prev => !prev)}>
                        {showSteps ? 'Schritte verstecken' : 'Schritte anzeigen'}
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    )
}

export default ActionDetailsDialog;