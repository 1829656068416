import {useEffect, useState} from 'react';
import {Button, ButtonGroup, MenuItem, Popover} from '@mui/material';
import {usePopover} from 'src/omnia/hooks/use-popover';
import {snakeToUpper} from "src/omnia/utils/snakeToUpper";
import {useTranslation} from "react-i18next";
import useCoreFormik from "../../../../hooks/formik/use-core-formik";

export const TaskStatus = ({formik, options = []}) => {
    const { t } = useTranslation();
    const { TASK_STATUS } = useCoreFormik();
    const popover = usePopover();

    return (
        <>
            <ButtonGroup
                ref={popover.anchorRef}
                variant="text"
                size="small"
            >
                <Button
                    size="small"
                    onClick={popover.handleToggle}
                >
                    {t('core.tasks.state_is', {state: t('core.tasks.states.' + formik.values.status)})}
                </Button>
            </ButtonGroup>
            <Popover
                anchorEl={popover.anchorRef.current}
                disableScrollLock
                onClose={popover.handleClose}
                open={popover.open}
                anchorOrigin={{
                    horizontal: 'right',
                    vertical: 'bottom'
                }}
                transformOrigin={{
                    horizontal: 'right',
                    vertical: 'top'
                }}
            >
                {TASK_STATUS.map((option) => (
                    <MenuItem
                        key={option.value}
                        selected={option === formik.values.status}
                        onClick={() => {
                            popover.handleClose();
                            formik.setFieldValue('status', option.value)
                        }}
                    >
                        {option.label}
                    </MenuItem>
                ))}
            </Popover>
        </>
    );
};
