import React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    ListItemButton,
    ListItemIcon, ListItemSecondaryAction, ListItemText, Stack,
    Typography
} from "@mui/material";
import PropTypes from "prop-types";
import OverviewList from "../../../elements/overview-list";
import {useTranslation} from "react-i18next";
import ShareIconButton from "../../../elements/sharing/share-button";
import RemoveSharableObject from "../../../elements/sharing/remove-sharable-object";
import truncateText from "../../../../utils/truncate-text";
import OnIcon from "../../../elements/icon";

function PromptLibraryDialog({open, onClose, onSelect}) {

    const { t } = useTranslation();

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>
                <Typography variant="h4">
                    {t('intelligence.assistants.prompt_library')}
                </Typography>
            </DialogTitle>
            <DialogContent style={{padding: 0}}>
                <OverviewList
                    height={500}
                    withSearch={true}
                    endpoint='core/assistant-saved-prompts'
                    renderItem={(prompt, index, setElements) => (
                        <ListItemButton
                            key={'prompt-' + index}
                            onClick={() => onSelect?.(prompt)}
                        >
                            <ListItemIcon>
                                <OnIcon iconName="File02" />
                            </ListItemIcon>
                            <ListItemText
                                primary={truncateText(prompt?.name || 'Unnamed Prompt', 50)}
                                // primary={prompt?.name || 'Unnamed Prompt'}
                                secondary={prompt?.owner?.name}
                                primaryTypographyProps={{noWrap: true}}
                            />
                            <ListItemSecondaryAction>
                                <Stack direction="row" spacing={1}>
                                    <ShareIconButton
                                        entity={prompt}
                                        endpoint='core/assistant-saved-prompts'
                                        variant='icon-button'
                                    />
                                    <RemoveSharableObject
                                        entity={prompt}
                                        noRedirect={true}
                                        endpoint='core/assistant-saved-prompts'
                                        variant='icon-button'
                                    />
                                </Stack>
                            </ListItemSecondaryAction>
                        </ListItemButton>
                    )}
                />
            </DialogContent>
            <DialogActions>
                <Stack direction="row" justifyContent="space-between" sx={{width: '100%'}}>
                    <Button onClick={onClose} variant="outlined" color="primary">
                        {t("common.cancel")}
                    </Button>
                    <Button onClick={onClose} variant="contained" color="primary">
                        {t("common.done")}
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
}

PromptLibraryDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSelect: PropTypes.func,
}

export default PromptLibraryDialog;