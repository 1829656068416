import React from "react";
import { useTheme } from "@mui/system";
import { useIsMobile } from "../../hooks/use-is-mobile";
import { Card, Fade, Popover, Popper } from "@mui/material";
import PropTypes from "prop-types";

function getPopoverOrigin(placement) {
    const [mainPlacement, alignment] = placement.split('-');

    let anchorOrigin = { vertical: 'bottom', horizontal: 'center' };
    let transformOrigin = { vertical: 'top', horizontal: 'center' };

    switch (mainPlacement) {
        case 'bottom':
            anchorOrigin.vertical = 'bottom';
            transformOrigin.vertical = 'top';
            break;
        case 'top':
            anchorOrigin.vertical = 'top';
            transformOrigin.vertical = 'bottom';
            break;
        case 'left':
            anchorOrigin.horizontal = 'left';
            transformOrigin.horizontal = 'right';
            break;
        case 'right':
            anchorOrigin.horizontal = 'right';
            transformOrigin.horizontal = 'left';
            break;
        default:
            break;
    }

    switch (alignment) {
        case 'start':
            if (mainPlacement === 'top' || mainPlacement === 'bottom') {
                anchorOrigin.horizontal = 'left';
                transformOrigin.horizontal = 'left';
            } else {
                anchorOrigin.vertical = 'top';
                transformOrigin.vertical = 'top';
            }
            break;
        case 'end':
            if (mainPlacement === 'top' || mainPlacement === 'bottom') {
                anchorOrigin.horizontal = 'right';
                transformOrigin.horizontal = 'right';
            } else {
                anchorOrigin.vertical = 'bottom';
                transformOrigin.vertical = 'bottom';
            }
            break;
        default:
            // anchorOrigin.vertical = 'center';
            // transformOrigin.vertical = 'center';
            break;
    }

    return { anchorOrigin, transformOrigin };
}

function SmartPopper({ popover, children, placement, minWidth, closeOnLeave, padding, width, disableHover, sx, ...other }) {
    const theme = useTheme();
    const { isMobile } = useIsMobile();
    const { isFlying } = useIsMobile();

    const modifiers = [
        {
            name: 'offset',
            options: {
                offset: isFlying ? [0, 13] : [0, 10],
            },
        },
    ];

    const { anchorOrigin, transformOrigin } = getPopoverOrigin(placement);

    // FIXME: this should rather be isTouchDevice
    if (isMobile || disableHover) {
        return (
            <Popover
                anchorEl={popover.anchorRef.current}
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
                disableScrollLock
                onClose={popover.handleClose}
                open={popover.open}
                slotProps={{
                    paper: {
                        sx: {
                            ...{
                                p: padding,
                                // pb: 0,
                            },
                            ...(width ? {width: width} : {minWidth: minWidth}),
                        },
                        style: isFlying
                            ? {
                                transform: 'translateX(-10px)',
                            }
                            : {
                                transform: 'translateY(10px)',
                            },
                    },
                }}
                {...other}
            >
                {children}
            </Popover>
        );
    }

    return (
        <Popper
            open={!!popover.open}
            anchorEl={popover.anchorRef.current}
            placement={placement}
            modifiers={modifiers}
            sx={{ zIndex: theme.zIndex.modal }}
            transition
            {...other}
        >
            {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={250}>
                    <Card
                        elevation={10}
                        sx={{
                            ...{
                                zIndex: theme.zIndex.modal,
                                p: padding,
                            },
                            ...(width ? {width: width} : {minWidth: minWidth}),
                            ...sx
                        }}
                        onMouseEnter={() => {
                            popover.handleOpen();
                            popover.queueOpen();
                        }}
                        onMouseLeave={() => {
                            if(closeOnLeave)
                                popover.queueClose();
                        }}
                    >
                        {children}
                    </Card>
                </Fade>
            )}
        </Popper>
    );
}

SmartPopper.propTypes = {
    popover: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
    closeOnLeave: PropTypes.bool,
    disableHover: PropTypes.bool,
    minWidth: PropTypes.number,
    width: PropTypes.number,
    padding: PropTypes.number,
    sx: PropTypes.object,
    placement: PropTypes.oneOf([
        'top-start',
        'top',
        'top-end',
        'right-start',
        'right',
        'right-end',
        'bottom-start',
        'bottom',
        'bottom-end',
        'left-start',
        'left',
        'left-end',
    ]),
};

SmartPopper.defaultProps = {
    minWidth: 200,
    closeOnLeave: true,
    width: null,
    disableHover: false,
    sx: {},
    padding: 0,
    placement: 'bottom-end',
};

export default SmartPopper;
