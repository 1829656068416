import React from "react";
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import {Tooltip} from "@mui/material";

const SeverityPillRoot = styled('span')(({ theme, ownerState }) => {
    const backgroundColor = ownerState.color !== "neutral" ? theme.palette[ownerState.color].main : theme.palette.neutral[theme.palette.mode === 'light' ? 200 : 600];
    const color = ownerState.color !== "neutral" ? theme.palette[ownerState.color].contrastText : theme.palette.text.primary;

    return {
        ...{
            alignItems: 'center',
            backgroundColor,
            color,
            cursor: 'default',
            display: 'inline-flex',
            flexGrow: 0,
            flexShrink: 0,
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        },
        ...(ownerState.dot ? {
            height: 12,
            width: 12,
            borderRadius: 6,
        } : {
            minWidth: 20,
            borderRadius: 12,
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.pxToRem(12),
            lineHeight: 2,
            fontWeight: 600,
            justifyContent: 'center',
            letterSpacing: 0.5,
            textTransform: 'uppercase',
            whiteSpace: 'nowrap',
        })
    };
});

export const SeverityPill = (props) => {
    const { color = 'primary', children, dot, ...other } = props;

    const ownerState = { color, dot };

    if(dot){
        return (
            <SeverityPillRoot
                ownerState={ownerState}
                {...other}
            />
        )
    } else {
        return (
            <SeverityPillRoot
                ownerState={ownerState}
                {...other}
            >
                {children}
            </SeverityPillRoot>
        );
    }

};

SeverityPill.propTypes = {
    children: PropTypes.node,
    dot: PropTypes.bool,
    color: PropTypes.oneOf([
        'primary',
        'secondary',
        'error',
        'info',
        'warning',
        'success',
        'neutral'
    ])
};

SeverityPill.defaultProps = {
    dot: false
}
