import React from "react";
import PropTypes from 'prop-types';
import {
    Box, Stack,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography
} from '@mui/material';
import {useIsMobile} from 'src/omnia/hooks/use-is-mobile';
import moment from "moment";
import NumberFormat from "react-number-format";
import {useTheme} from "@mui/system";
import {useTranslation} from "react-i18next";
import OrderStateChip from "./order-state-chip";

export const OrderListTable = ({onOpenDrawer, orders, minimal, ...other}) => {

    const theme = useTheme();
    const { t } = useTranslation();
    const { isMobile } = useIsMobile();

    return (
        <div {...other}>
            <Table>
                <TableBody>
                    {orders.map((order) => (
                        <TableRow
                            hover
                            key={order.id}
                            onClick={() => onOpenDrawer?.(order.id)}
                            sx={{cursor: 'pointer'}}
                        >
                            <TableCell sx={{alignItems: 'center', display: 'flex', p: 2}}>
                                {(!isMobile && !minimal) && (
                                    <Box
                                        sx={{
                                            backgroundColor: () => theme.palette.mode === 'dark'
                                                ? theme.palette.neutral[800]
                                                : theme.palette.neutral[200],
                                            borderRadius: 2,
                                            width: 200,
                                            // ml: 3,
                                            p: 1
                                        }}
                                    >
                                        <Typography align="center" variant="h6" noWrap>
                                            {t('resources.orders.order_with_number', {number: order.number})}
                                        </Typography>
                                        <Typography align="center" variant="subtitle2" noWrap>
                                            {t('common.created_from_now', {fromNow: moment(order.created_at).fromNow()})}
                                        </Typography>
                                    </Box>
                                )}
                                <Box sx={{ml: 1}}>
                                    <Stack>
                                        <Typography variant="h6">
                                            {order?.organization ? order?.organization : (order?.first_name ? (order?.first_name + ' ' + order?.last_name) : t('common.unknown'))}
                                        </Typography>
                                        <Typography variant="subtitle2">
                                            {minimal ? order.number : (order?.contact_person ? order?.contact_person : (order?.first_name + ' ' + order?.last_name))}
                                        </Typography>
                                    </Stack>
                                    <Typography
                                        color="textSecondary"
                                        variant="body2"
                                    >
                                        {t('resources.orders.total_value')}
                                        {' '}
                                        <NumberFormat
                                            fixedDecimalScale={false}
                                            decimalScale={2}
                                            value={parseFloat(order.total_gross) / 100}
                                            displayType={'text'}
                                            thousandSeparator={'.'}
                                            decimalSeparator={','}
                                            suffix={' €'}
                                        />
                                    </Typography>
                                </Box>
                            </TableCell>
                            <TableCell align="right" sx={{textAlign: 'right', p: 2}}>
                                <OrderStateChip order={order} />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
};

OrderListTable.propTypes = {
    onOpenDrawer: PropTypes.func,
    orders: PropTypes.array.isRequired,
    minimal: PropTypes.bool,
};

OrderListTable.defaultProps = {
    minimal: false,
}
